import React, { createContext, useCallback, useContext } from 'react';

import { useFetch } from 'services/hooks';

const OperationsGroupsContext = createContext({});

const OperationsGroupsProvider = ({ children }) => {
  const {
    data: operationsGroupsData,
    loading: loadingOperationsGroups,
    error: operationsGroupsError,
    get,
  } = useFetch();

  const getOperationsGroups = useCallback(() => {
    get({
      url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/operations-groups`,
      config: {},
    });
  }, [get]);

  return (
    <OperationsGroupsContext.Provider
      value={{
        operationsGroupsData,
        loadingOperationsGroups,
        getOperationsGroups,
        operationsGroupsError,
      }}
    >
      {children}
    </OperationsGroupsContext.Provider>
  );
};

function useOperationsGroups() {
  const context = useContext(OperationsGroupsContext);

  if (!context) {
    throw new Error('useOperationsGroups must be used within an OperationsGroupsContext');
  }

  return context;
}

export { OperationsGroupsProvider, useOperationsGroups };
