import React, { createContext, useCallback, useContext } from 'react';

import { useFetch } from 'services/hooks';

const DisapprovalReasonsContext = createContext({});

const DisapprovalReasonsProvider = ({ children }) => {
  const { data, clearData, loading, get } = useFetch();

  const getData = useCallback(() => {
    get({
      url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/disapproval-reasons`,
      config: {},
    });
  }, [get]);

  return (
    <DisapprovalReasonsContext.Provider
      value={{
        data,
        dataDocs: data?.docs,
        loading,
        getData,
        clearData,
      }}
    >
      {children}
    </DisapprovalReasonsContext.Provider>
  );
};

function useDisapprovalReasons() {
  const context = useContext(DisapprovalReasonsContext);

  if (!context) {
    throw new Error(
      'useDisapprovalReasons must be used within an DisapprovalReasonsContext'
    );
  }

  return context;
}

export { DisapprovalReasonsProvider, useDisapprovalReasons };
