import React, { useState } from 'react';

import Wrapper from '../wrapper';

import ReviewFilter from './ReviewFilter';
import ReviewList from './ReviewList';

import './styles.less';

const ManualReview = () => {
  // eslint-disable-next-line no-unused-vars
  const [isLoadingList, setIsLoadingList] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorFilter, setErrorFilter] = useState(false);

  return (
    <Wrapper id="review-component">
      <div className="gx-flex-row">
        <div id="filter" className="hide-scroll">
          <ReviewFilter isLoadingList={isLoadingList} setErrorFilter={setErrorFilter} />
        </div>
        <div id="list">
          <ReviewList
            isLoadingList={isLoadingList}
            setIsLoadingList={setIsLoadingList}
            errorFilter={errorFilter}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ManualReview;
