import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import PermissionWrapper from 'routes/PermissionWrapper';
import { useAuth } from 'hooks/auth';

import { Edit, MoreVertical, Trash } from '@combateafraude/icons/general';
import RemoveOperationsGroup from './RemoveOperationsGroup';

const OperationsGroupsActionsList = ({ operationsGroup, refreshListRef }) => {
  const { openModal, RemoveOperationsGroupModal } = RemoveOperationsGroup({
    operationsGroup,
    refreshListRef,
  });
  const { loggedUser } = useAuth();

  return (
    <>
      {RemoveOperationsGroupModal}
      <PermissionWrapper
        requiredPermissions={['wecheck_configs:update', 'wecheck_configs:delete']}
        or
        forbiddenContent={<span className="display-block" style={{ height: '1.5rem' }} />}
      >
        <Dropdown
          overlay={
            <Menu className="pdd-vertical-5">
              {hasRequiredPermissions(loggedUser, ['wecheck_configs:update'], []) && (
                <Menu.Item key="editPolicy" className="text-dark">
                  <Link to={`/operations-groups/${operationsGroup?.id}`}>
                    <Edit width={24} height={24} />
                    <span className="text-semibold">Editar grupo de operações</span>
                  </Link>
                </Menu.Item>
              )}

              {hasRequiredPermissions(
                loggedUser,
                ['wecheck_configs:update', 'wecheck_configs:delete'],
                []
              ) && <Menu.Divider className="mrg-horizon-15 mrg-vertical-5" />}

              {hasRequiredPermissions(loggedUser, ['wecheck_configs:delete'], []) && (
                <Menu.Item
                  key="deleteOperationsGroup"
                  onClick={openModal}
                  className="gx-text-danger"
                >
                  <Trash width={24} height={24} />
                  <span className="text-semibold ">Excluir grupo de operações</span>
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <div>
            <MoreVertical />
          </div>
        </Dropdown>
      </PermissionWrapper>
    </>
  );
};

OperationsGroupsActionsList.propTypes = {
  operationsGroup: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

OperationsGroupsActionsList.defaultProps = {
  operationsGroup: {},
};

export default OperationsGroupsActionsList;
