import React from 'react';

import PageTitle from 'components/PageTitle';

const Page2 = () => (
  <div id="info-page" className="info-page mrg-top-80 mrg-btm-95 pdd-horizon-170">
    <div className="content">
      <PageTitle title="Como funciona a prioridade da fila no WeCheck?" />
      <div className="mrg-top-40">
        <p>
          O operador que recebe um documento para avaliar fica com ele por 5 min, caso ele
          pule essa avaliação ela vai ser direcionada para outro operador. Dentro desse
          processo temos a nossa prioridade. Todos os registros vão ganhando prioridades,
          isso para garantir que todos os documentos sejam avaliados e o mais rapidamente
          possível, tudo isso ocorre de acordo com o cálculo de prioridade!
        </p>
        <p>
          <strong>
            Na prática é isso que acontece quando o analista recebe uma avaliação:
          </strong>{' '}
          <br />
          Prioridade é maior? Sim <br />
          É standard ou advanced? Standard <br />
          Então entregar avaliação para um operador Standard. <br />
          <p>
            Se esse operador não avaliar, pular avaliação, ela vai ser direcionada para
            outro operador. <br />
            <strong>Importante:</strong> Pode acontecer de o mesmo operador pegar essa
            avaliação novamente.
          </p>
        </p>
      </div>
    </div>
  </div>
);

export default Page2;
