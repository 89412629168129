import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import RateMeter from 'components/Rate';
import FieldPresenceCheck from '../FieldPresenceCheck';
import FieldDetailsList from '../FieldDetailsList';

import './styles.less';

const DocumentInformation = ({ documentData, countryCode }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      documentTitle: documentData?.documentTitle,
      countryCode,
      firstIssued: documentData?.firstIssued,
      maxValidity: documentData?.maxValidity,
      graphicField: documentData?.graphicField,
      docType: documentData?.docType,
      overallConstruction: documentData?.overallConstruction,
      // classifiedSecurityFeatures
      dimensions: documentData?.dimensions,
      issuingCountry: documentData?.issuingCountry,
      issuingState: documentData?.issuingState,
      mrz: documentData?.mrz,
      rfid: documentData?.rfid,
      textField: documentData?.textField,
      specimenId: documentData?.specimenId,
      barcodeField: documentData?.barcodeField,
      dataPersonalisationTechnique: documentData?.dataPersonalisationTechnique,
      generalNotes: documentData?.generalNotes,
      photoPersonalisationTechnique: documentData?.photoPersonalisationTechnique,
      printingTechnique: documentData?.printingTechnique,
      substrate: documentData?.substrate,
      referenceStrength: documentData?.referenceStrength,
      // documentId: documentData?.documentId,
      // linkDocument: documentData?.linkDocument,
    });
  }, [form, documentData, countryCode]);

  return (
    <div id="document-information-component" className="flex-column flex-1">
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        // className="uppercase-form"
      >
        {documentData?.referenceStrength && (
          <>
            <div className="input-item">
              <span className="input-item-label">Qualidade de referência</span>
            </div>
            <div>
              <RateMeter
                className="rate-meter"
                value={documentData?.referenceStrength}
                disabled
              />
            </div>
          </>
        )}
        <div className="custom-inputs flex">
          <Form.Item name="documentTitle" label="Título" style={{ width: '50%' }}>
            <Input className="custom-input" disabled />
          </Form.Item>
          <Form.Item name="docType" label="Tipo" style={{ width: '50%' }}>
            <Input className="custom-input" disabled />
          </Form.Item>
        </div>
        <div className="custom-inputs flex">
          <Form.Item name="issuingCountry" label="País emissor" style={{ width: '35%' }}>
            <Input className="custom-input" disabled />
          </Form.Item>
          <Form.Item name="countryCode" label="Código do país" style={{ width: '30%' }}>
            <Input className="custom-input" disabled />
          </Form.Item>
          <Form.Item name="issuingState" label="Estado emissor" style={{ width: '35%' }}>
            <Input className="custom-input" disabled />
          </Form.Item>
        </div>
        <div className="custom-inputs">
          <Form.Item name="specimenId" label="Seção de referência do documento">
            <Input className="custom-input" disabled />
          </Form.Item>
        </div>
        <div className="custom-inputs flex">
          <Form.Item
            name="firstIssued"
            label="Primeira publicação"
            style={{ width: '50%' }}
          >
            <Input className="custom-input" disabled />
          </Form.Item>
          <Form.Item
            name="maxValidity"
            label="Validade máxima(Adulto)"
            style={{ width: '50%' }}
          >
            <Input className="custom-input" disabled />
          </Form.Item>
        </div>
        <div className="custom-inputs flex">
          <span style={{ marginTop: '1rem' }}>Checagem automática</span>
        </div>
        <div className="custom-inputs flex">
          {documentData?.textField && (
            <div style={{ margin: '0.2rem' }} className="custom-inputs flex">
              <FieldPresenceCheck fieldName="Texto" />
            </div>
          )}

          {documentData?.graphicsField && (
            <div style={{ margin: '0.2rem' }} className="custom-inputs flex">
              <FieldPresenceCheck fieldName="Gráficos" />
            </div>
          )}
          {documentData?.barcodeField && (
            <div style={{ margin: '0.2rem' }} className="custom-inputs flex">
              <FieldPresenceCheck fieldName="Código de barras" />
            </div>
          )}
        </div>
        <div className="custom-inputs">
          <FieldDetailsList
            fieldName="Recursos de segurança"
            fieldDetails={documentData?.classifiedSecurityFeatures}
          />
        </div>
        <div className="custom-inputs">
          <FieldDetailsList
            fieldName="Recurso gráfico"
            fieldDetails={documentData?.graphicField}
          />
        </div>
        <div className="custom-inputs flex">
          <span style={{ marginTop: '1rem' }}>Dados técnicos</span>
        </div>
        <div className="custom-inputs flex">
          <Form.Item
            name="overallConstruction"
            label="Construção geral do documento"
            style={{ width: '50%' }}
          >
            <Input className="custom-input" disabled />
          </Form.Item>
          <Form.Item
            name="dimensions"
            label="Dimensões do documento"
            style={{ width: '50%' }}
          >
            <Input className="custom-input" disabled />
          </Form.Item>
        </div>
        <div className="custom-inputs flex">
          <Form.Item
            name="printingTechnique"
            label="Técnica de impressão"
            style={{ width: '40%' }}
          >
            <Input className="custom-input" disabled />
          </Form.Item>
          <Form.Item
            name="dataPersonalisationTechnique"
            label="Técnica de Personalização de Dados"
            style={{ width: '60%' }}
          >
            <Input className="custom-input" disabled />
          </Form.Item>
        </div>
        <div className="custom-inputs flex">
          <Form.Item
            name="photoPersonalisationTechnique"
            label="Técnica de Personalização de Foto"
            style={{ width: '80%' }}
          >
            <Input className="custom-input" disabled />
          </Form.Item>
          <Form.Item name="substrate" label="Substrato" style={{ width: '20%' }}>
            <Input className="custom-input" disabled />
          </Form.Item>
        </div>
        <div className="custom-inputs">
          <Form.Item name="generalNotes" label="Notas de referência">
            <Input className="custom-input" disabled />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

DocumentInformation.propTypes = {
  documentData: PropTypes.objectOf(PropTypes.objectOf),
  countryCode: PropTypes.string,
};

DocumentInformation.defaultProps = {
  documentData: undefined,
  countryCode: undefined,
};

export default DocumentInformation;
