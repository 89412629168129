import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import { useScroll } from 'hooks/scroll';

import Header from 'components/Header';

const { Content } = Layout;

const Wrapper = ({ subheader, children, ...rest }) => {
  const { handleScroll } = useScroll();

  return (
    <Layout className="gx-app-layout">
      <Layout>
        <Header subheader={subheader} />
        <Content
          id="main-content"
          className="gx-layout-content gx-container-wrap"
          onScroll={handleScroll}
        >
          <div className="gx-main-content-wrapper" {...rest}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

Wrapper.propTypes = {
  subheader: PropTypes.node,
};

Wrapper.defaultProps = {
  subheader: <></>,
};

export default Wrapper;
