import React from 'react';

import PageTitle from 'components/PageTitle';

const Page5 = () => (
  <div id="info-page" className="info-page mrg-top-100 mrg-btm-95 pdd-horizon-170">
    <div className="content">
      <PageTitle title="Quando ocorre Double Check?" />
      <div className="mrg-top-40">
        <p>
          A dupla checagem sempre vai acontecer quando uma avaliação é reprovada uma vez.
          Então para garantirmos uma segunda avaliação esse cenário vai existir. <br />
          Se na segunda avaliação for novamente reprovado, então encerra a avaliação.
        </p>
        <p>
          <strong>Exemplo:</strong>
          <br />
          <strong>Operador standard:</strong>
          <br />
          Se reprovar, cai na dupla checagem para um operador avançado.
          <br />
          Se aprovado, encerra avaliação.
        </p>
        <p>
          <strong>Operador Advanced: </strong>
          <br />
          Se reprovar, cai na dupla checagem para um operador avançado.
          <br />
          Se aprovado, encerra avaliação.
        </p>
      </div>
    </div>
  </div>
);

export default Page5;
