import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';

const ProgressLine = ({ steps, percent, ...rest }) => (
  <Progress {...rest} showInfo={false} type="line" steps={steps} percent={percent} />
);

ProgressLine.propTypes = {
  steps: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
};

export default ProgressLine;
