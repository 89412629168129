import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { CircleFalse, Checkmark } from '@combateafraude/icons/general';
import { CursorSelect } from '@combateafraude/icons/builder';

import './styles.less';

const { Option } = Select;

const SelectCustomFilter = ({ mode, value, listHeight, setFilter, options, ...rest }) => (
  <Select
    mode={mode}
    labelInValue
    value={value}
    placeholder={
      <div className="flex start-center">
        <CursorSelect width={24} height={24} />
        <span>Selecione...</span>
      </div>
    }
    optionLabelProp="label"
    dropdownClassName="custom-filter-select-dropdown"
    filterOption={false}
    listHeight={listHeight}
    style={{ width: 290 }}
    dropdownAlign={{ offset: [0, 2] }}
    removeIcon={<CircleFalse width={12} height={24} />}
    menuItemSelectedIcon={<Checkmark width={24} height={24} />}
    onChange={(values) => {
      setFilter(values);
    }}
    {...rest}
  >
    {options?.map((o) => (
      <Option key={o.key} label={o.label}>
        {o.label}
      </Option>
    ))}
  </Select>
);

SelectCustomFilter.propTypes = {
  mode: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.objectOf),
  setFilter: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.objectOf),
  listHeight: PropTypes.number,
};

SelectCustomFilter.defaultProps = {
  mode: null,
  value: [],
  setFilter: null,
  options: [],
  listHeight: 320,
};

export default SelectCustomFilter;
