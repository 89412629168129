/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState, useRef } from 'react';

import {
  RecordDetailsEvaluationLiveness,
  RecordDetailsEvaluationPendingOcr,
  RecordDetailsEvaluationDocumentscopy,
} from 'pages/private/Records/RecordDetails';
import { useFetch } from 'services/hooks';
import showToastMessage from 'components/ToastMessage';
import QueueEmpty from './components/QueueEmpty';

import './styles.less';

const Avaliation = () => {
  const { get, loading } = useFetch();
  const [data, setData] = useState({});

  const messageInfo = () => {
    showToastMessage({
      type: 'info',
      text: 'Nenhum documento para ser analisado!',
      className: 'message-top',
    });
  };

  const fetch = useCallback(async () => {
    try {
      const res = await get({
        url: '/queuing',
        config: { params: { getHighPriorityRecord: true } },
      });
      const { docs } = res;

      if (docs.length !== 0) setData(docs[0]);
      else messageInfo();
    } catch (e) {
      if (e.response?.status === 403) {
        showToastMessage({
          type: 'warning',
          text: e.response?.data?.message,
          className: 'message-top',
        });
      } else {
        messageInfo();
      }
    }
  }, [get]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;

    document.location.hash = '';
    window.history.pushState('', '', window.location.pathname);
  }, []);

  useEffect(() => {
    setData({});

    fetch();
  }, [fetch]);

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {data && Object.keys(data || {}).length > 0 ? (
        data.type === 'double-check-liveness' ? (
          <RecordDetailsEvaluationLiveness id={data.recordId} />
        ) : data.type === 'pending-ocr' ? (
          <RecordDetailsEvaluationPendingOcr id={data.recordId} />
        ) : (
          <RecordDetailsEvaluationDocumentscopy id={data.recordId} />
        )
      ) : (
        <QueueEmpty fetch={fetch} loading={loading} />
      )}
    </>
  );
};

export default Avaliation;
