import React from 'react';
import PropTypes from 'prop-types';
import { CheckD } from '@combateafraude/icons/general';

import './styles.less';

const FieldPresenceCheck = ({ fieldName }) => (
  <div className="field-presence-check-component flex center">
    <div className="field-presence-check-icon flex center">
      <CheckD width={24} height={24} />
    </div>
    <div className="field-presence-check-name flex center">
      <span>{fieldName}</span>
    </div>
  </div>
);
FieldPresenceCheck.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default FieldPresenceCheck;
