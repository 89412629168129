import React, { createContext, useCallback, useState, useContext } from 'react';
import { formatTimeDelta } from 'react-countdown';
import Modal from 'components/Modal';

import timeImg from 'assets/images/time-img.svg';

const CountdownContext = createContext({});

const CountdownProvider = ({ children }) => {
  const fiveMinutes = 5000 * 60;
  const [time, setTime] = useState('');
  const [timeLast, setTimeLast] = useState(fiveMinutes);
  const [timeout, setTimeout] = useState(false);
  const [visible, setVisible] = useState(false);

  const { openModal, Modal: ModalInfo } = Modal({
    img: timeImg,
    title: 'O seu tempo acabou!',
    content: 'Clique em ok para avaliar um novo documento',
    okText: 'Ok',
    okType: 'primary',
    keyboard: false,
    onOk: () => window.location.reload(),
  });

  const renderer = useCallback(
    ({ minutes, seconds, completed, total }) => {
      if (completed) {
        setTimeout(true);

        if (!visible) {
          setVisible(true);

          openModal();
        }
      }

      setTimeLast(total);
      setTime((fiveMinutes - total) / 1000);

      const timer = formatTimeDelta(
        { minutes, seconds },
        {
          minutes: '00',
          seconds: '00',
        }
      );

      return (
        <div className="flex center">
          <span>{timer.minutes}</span>:<span>{timer.seconds}</span>
        </div>
      );
    },
    [fiveMinutes, visible, openModal]
  );

  return (
    <div>
      {ModalInfo}
      <CountdownContext.Provider
        value={{
          renderer,
          time,
          fiveMinutes,
          timeLast,
          timeout,
          setTimeLast,
        }}
      >
        {children}
      </CountdownContext.Provider>
    </div>
  );
};

function useCountdown() {
  const context = useContext(CountdownContext);

  if (!context) {
    throw new Error('useCountdown must be used within an CountdownProvider');
  }

  return context;
}

export { CountdownProvider, useCountdown };
