/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Collapse, Row } from 'antd';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  CheckCircle2,
  ProcessingLine,
  WrongCircle,
  Info,
  ArrowDown,
  ArrowRight,
} from '@combateafraude/icons/general';
import Observation from './components/Observation';
import ReproveDocument from './components/ReproveDocument';
import AvaliationInfo from './components/AvaliationInfo';

import '../styles.less';

const { Panel } = Collapse;

const AvaliationError = ({
  recordData,
  isReview,
  reproveChanged,
  setReproveChanged,
  setAvaliationFinished,
  redirectUrl,
}) => {
  const {
    avaliationInfo = null,
    documentscopy = null,
    relatedRecordId = null,
    queue = null,
  } = recordData?.record;

  const defaultValuesCollapse = useMemo(() => {
    const values = [];
    if (
      (documentscopy?.obs && queue?.solutionLevel !== 'quality') ||
      (relatedRecordId && queue?.solutionLevel !== 'quality') ||
      queue?.solutionLevel === 'poc' ||
      queue?.solutionLevel === 'qrcode'
    ) {
      values.push('obs');
    }

    if (isReview) {
      values.push('avaliation-info');
    } else {
      values.push('reprove');
    }
    return values;
  }, [isReview, documentscopy, relatedRecordId, queue]);

  return (
    <div id="avaliation-without-page">
      <Row>
        {isReview ? (
          <p className="gx-w-100 text-bold text-dark font-size-16">
            Ops, não recebemos o documento completo!
          </p>
        ) : (
          <p className="gx-w-100 text-bold text-dark font-size-16">
            Ops, não recebemos o documento completo! Reprove-o para continuar.
          </p>
        )}
      </Row>

      <Collapse className="mrg-left-10" defaultActiveKey={defaultValuesCollapse} ghost>
        {isReview && (
          <Panel
            header="Dados referente a Avaliação"
            key="avaliation-info"
            className={`${classNames({
              'primary-border': avaliationInfo?.evaluated && documentscopy?.approved,
              'danger-border': avaliationInfo?.evaluated && !documentscopy?.approved,
              'success-border': avaliationInfo?.evaluated && documentscopy?.reprocess,
            })} mrg-btm-20`}
            extra={
              avaliationInfo?.evaluated && documentscopy?.approved !== null ? (
                documentscopy?.approved ? (
                  <CheckCircle2 className="gx-text-primary" width={24} height={24} />
                ) : (
                  <WrongCircle className="gx-text-danger" width={24} height={24} />
                )
              ) : (
                documentscopy?.reprocess && (
                  <ProcessingLine width={24} height={24} className="gx-text-success" />
                )
              )
            }
            expandIcon={({ isActive }) =>
              isActive ? (
                <ArrowDown width={24} height={24} />
              ) : (
                <ArrowRight width={24} height={24} />
              )
            }
          >
            <AvaliationInfo recordData={recordData} />
          </Panel>
        )}

        <Panel
          header="Observações"
          key="obs"
          className={`${classNames({
            'warning-border': defaultValuesCollapse.includes('obs'),
          })} mrg-btm-20`}
          extra={
            defaultValuesCollapse.includes('obs') && (
              <Info width={24} height={24} className="gx-text-warning" />
            )
          }
        >
          <Observation recordData={recordData} />
        </Panel>

        {!isReview && (
          <Panel
            id="reprove-component"
            header="Reprovar o documento"
            key="reprove"
            className="danger-border mrg-btm-20"
          >
            <ReproveDocument
              recordData={recordData}
              isReview={isReview}
              reproveChanged={reproveChanged}
              setReproveChanged={setReproveChanged}
              setAvaliationFinished={setAvaliationFinished}
              redirectUrl={redirectUrl}
            />
          </Panel>
        )}
      </Collapse>
    </div>
  );
};

AvaliationError.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  setReproveChanged: PropTypes.func,
  reproveChanged: PropTypes.bool,
  setAvaliationFinished: PropTypes.func,
  redirectUrl: PropTypes.string,
};

AvaliationError.defaultProps = {
  isReview: false,
  reproveChanged: false,
  setReproveChanged: () => {},
  setAvaliationFinished: () => {},
  redirectUrl: '/manual-review-wizard',
};

export default AvaliationError;
