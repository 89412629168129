import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from 'antd';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useAuth } from 'hooks/auth';

import ReportSuspectButton from './components/ReportSuspectButton';
import ViewSelfieButton from './components/ViewSelfieButton';
import IncorrectAnalysisButton from './components/IncorrectAnalysisButton';

import './styles.less';

const { Panel } = Collapse;

const DocumentsHeader = ({ recordData, isReview, modelId }) => {
  const { loggedUser } = useAuth();

  const {
    documentscopy = null,
    type = null,
    queue = null,
    avaliationInfo = null,
    executionInfo = null,
  } = recordData?.record;
  const [rules, setRules] = useState('');

  useEffect(() => {
    if (type === 'documentscopy' && queue?.solutionType === 'cnh') {
      if (documentscopy?.rulesValidation?.rule?.model === modelId?.charAt(0)) {
        setRules(documentscopy?.rulesValidation?.rule);
      } else {
        setRules(
          documentscopy?.rulesValidation?.otherRules?.find((r) =>
            Object.prototype.hasOwnProperty.call(r?.referenceImages || {}, `${modelId}`)
          )
        );
      }
    } else if (type === 'documentscopy' && queue?.solutionType !== 'international') {
      if (documentscopy?.rulesValidation?.rule?._id === modelId) {
        setRules(documentscopy?.rulesValidation.rule);
      } else {
        setRules(
          documentscopy?.rulesValidation?.otherRules?.find((r) => r._id === modelId)
        );
      }
    }
  }, [documentscopy, modelId, type, queue]);

  const rulesValidation = useMemo(
    () => (
      <>
        {rules?.tips?.map((t) => (
          <div key={t.tip} className="rule-tip">
            <div className="tip-content flex" title={t.tip}>
              <span className="white-dot" />
              <span className="tip-text">{t.tip}</span>
            </div>
          </div>
        ))}
      </>
    ),
    [rules]
  );

  const canShowButtonsList =
    type === 'documentscopy' && isReview && avaliationInfo?.evaluated;
  const showSelfieButton =
    hasRequiredPermissions(loggedUser, ['wecheck_viewSelfie:read'], []) &&
    executionInfo?.images?.selfie;
  const showIncorrectAnalysisButton = hasRequiredPermissions(
    loggedUser,
    ['wecheck_incorrectAnalysis:read'],
    []
  );
  const showReportSuspectButton = hasRequiredPermissions(
    loggedUser,
    ['wecheck_reportSuspect:read'],
    []
  );

  return (
    <div
      className={`documents-header-component flex ${classNames({
        'between-center': rules,
        'end-center': !rules,
      })}`}
    >
      {rules && (
        <Collapse
          className={`rules-collapse ${classNames({
            'show-documents-header-buttons':
              canShowButtonsList &&
              (showSelfieButton ||
                showIncorrectAnalysisButton ||
                showReportSuspectButton),
          })}`}
          ghost
          expandIconPosition="end"
        >
          <Panel header="Regras de avaliação do modelo" key="rules">
            {rulesValidation}
          </Panel>
        </Collapse>
      )}

      {canShowButtonsList && (
        <div className="flex center">
          {showSelfieButton && (
            <ViewSelfieButton selfieImg={executionInfo?.images?.selfie} />
          )}

          {showIncorrectAnalysisButton && (
            <IncorrectAnalysisButton
              loggedUser={loggedUser}
              record={recordData?.record}
              isApproved={documentscopy?.approved}
            />
          )}

          {showReportSuspectButton && (
            <ReportSuspectButton loggedUser={loggedUser} executionInfo={executionInfo} />
          )}
        </div>
      )}
    </div>
  );
};
DocumentsHeader.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  modelId: PropTypes.string || PropTypes.number,
};
DocumentsHeader.defaultProps = {
  isReview: false,
  modelId: null,
};

export default DocumentsHeader;
