import React from 'react';

import PageTitle from 'components/PageTitle';

const Page6 = () => (
  <div id="info-page" className="info-page mrg-top-60 mrg-btm-95 pdd-horizon-170">
    <div className="content">
      <PageTitle title="E se um cliente reclamar que sua avaliação está demorando?" />
      <div className="mrg-top-30">
        <p>
          Nesses casos, o time de suporte do BackOffice é quem vai atuar nesse momento. No
          BackOffice existe a possibilidade de o analista “Atualizar documentoscopia no
          Wecheck”, quando isso acontecer vai ser reprocessado e essa avaliação ganha uma
          prioridade para avançar na fila.
        </p>
        <p>
          <strong>Exemplo:</strong>
          <br />
          Suporte BackOffice → Atualizar documentoscopia no Wecheck → Incrementar 1000 na
          prioridade inicial.
        </p>
        <p>
          <strong>Em teoria é isso que está acontecendo:</strong>
          <br />
          Prioridade inicial + 1000 + o cálculo de prioridade de tempo que ele está na
          fila.
        </p>
        <p>
          Se o suporte executar mais vezes o reprocessamento, a prioridade inicial vai
          aumentando de 1000 em 1000, atualmente 1000 de prioridade é equivalente a um
          documento que está 16:40min aguardando na fila.
        </p>
      </div>
    </div>
  </div>
);

export default Page6;
