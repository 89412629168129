import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Select, Modal as ModalAntd } from 'antd';
import { useHistory } from 'react-router-dom';
import { Checkmark, CircleFalse } from '@combateafraude/icons/general';

import PropTypes from 'prop-types';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useCountdown } from 'hooks/countdown';

import Button from 'components/Button';
import Modal from 'components/Modal';
import showToastMessage from 'components/ToastMessage';

import alertImg from 'assets/images/alert-img.svg';

import './styles.less';

const { Option } = Select;

const ReproveDocument = ({
  recordData,
  isReview,
  setReproveChanged,
  setAvaliationFinished,
  redirectUrl,
}) => {
  const history = useHistory();

  const { loggedUser } = useAuth();
  const { time } = useCountdown();

  const [disapprovalReasonsList, setDisapprovalReasonsList] = useState([]);
  const [disapprovalReasonsFraudList, setDisapprovalReasonsFraudList] = useState([]);
  const [disapprovalReasonsUnreadableList, setDisapprovalReasonsUnreadableList] =
    useState([]);
  const [disapprovalReasonsBackofficeList, setDisapprovalReasonsBackofficeList] =
    useState([]);
  const { put } = useFetch();
  const [form] = Form.useForm();

  const { disapprovalReasons, record } = recordData;

  const { openModal: openModalError, Modal: ModalError } = Modal({
    img: alertImg,
    title: 'Selecione corretamente o(s) motivo(s) de reprovação.',
    content: 'Somente uma das opções deve estar selecionada.',
    okText: 'Ok',
    okType: 'primary',
  });

  const { openModal: openModalInfo, Modal: ModalInfo } = Modal({
    img: alertImg,
    title: 'Escolha os motivos da reprovação.',
    okText: 'Ok',
    okType: 'primary',
  });

  const onChange = useCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const { reproveReasons, reproveReasonsFraud, reproveReasonsUnreadable } = values;

    setReproveChanged(
      !!reproveReasons ||
        reproveReasonsFraud?.length > 0 ||
        reproveReasonsUnreadable?.length > 0
    );
  }, [form, setReproveChanged]);

  useEffect(() => {
    const newReasonsDisapproval = disapprovalReasons
      .filter((r) => !r.fraud && !r.unreadable && !r.backofficeReasons)
      .map((reason) => ({
        key: reason.disapprovalMessageId,
        label: reason.disapprovalMessage,
      }));

    const newReasonsDisapprovalFraud = disapprovalReasons
      .filter((r) => r.fraud)
      .map((reason) => ({
        key: reason.disapprovalMessageId,
        label: reason.disapprovalMessage,
      }));

    const newReasonsDisapprovalUnreadable = disapprovalReasons
      .filter((r) => r.unreadable)
      .map((reason) => ({
        key: reason.disapprovalMessageId,
        label: reason.disapprovalMessage,
      }));

    const newReasonsDisapprovalBackoffice = disapprovalReasons
      .filter((r) => r.backofficeReasons)
      .map((reason) => ({
        key: reason.disapprovalMessageId,
        label: reason.disapprovalMessage,
      }));

    setDisapprovalReasonsList(newReasonsDisapproval);
    setDisapprovalReasonsFraudList(newReasonsDisapprovalFraud);
    setDisapprovalReasonsUnreadableList(newReasonsDisapprovalUnreadable);
    setDisapprovalReasonsBackofficeList(newReasonsDisapprovalBackoffice);
  }, [disapprovalReasons]);

  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    let { reproveReasons } = values;
    const { reproveReasonsFraud, reproveReasonsUnreadable } = values;

    if (reproveReasons) {
      const result = [
        {
          disabled: reproveReasons.disabled,
          key: reproveReasons.key,
          label: reproveReasons.label,
          value: reproveReasons.value,
        },
      ];

      reproveReasons = result;
    }

    if (
      (reproveReasons?.length > 0 && reproveReasonsFraud?.length > 0) ||
      (reproveReasons?.length > 0 && reproveReasonsUnreadable?.length > 0) ||
      (reproveReasonsFraud?.length > 0 && reproveReasonsUnreadable?.length > 0)
    ) {
      openModalError();
    } else if (
      reproveReasons?.length > 0 ||
      reproveReasonsFraud?.length > 0 ||
      reproveReasonsUnreadable?.length > 0
    ) {
      ModalAntd.confirm({
        title: 'Você realmente deseja reprovar esse documento?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        width: 314,
        height: 171,
        onOk: async () => {
          let recordDisapprovalReasons = [];

          if (reproveReasons?.length > 0) {
            recordDisapprovalReasons = reproveReasons.map((reason) =>
              disapprovalReasons.find((r) => r.disapprovalMessageId === reason.value)
            );
          } else if (reproveReasonsFraud?.length > 0) {
            recordDisapprovalReasons = reproveReasonsFraud.map((reason) =>
              disapprovalReasons.find((r) => r.disapprovalMessageId === reason.value)
            );
          } else if (reproveReasonsUnreadable?.length > 0) {
            recordDisapprovalReasons = reproveReasonsUnreadable.map((reason) =>
              disapprovalReasons.find((r) => r.disapprovalMessageId === reason.value)
            );
          }

          let avaliationData;

          const isFraud = !!reproveReasonsFraud && reproveReasonsFraud.length > 0;
          const isUnreadable =
            !!reproveReasonsUnreadable && reproveReasonsUnreadable.length > 0;

          if (record?.type === 'documentscopy') {
            avaliationData = {
              documentscopy: {
                doubleCheck: record?.documentscopy?.doubleCheck,
                qualityCheck: record?.documentscopy?.qualityCheck,
                fraud: isFraud,
                unreadable: isUnreadable,
                approved: false,
                disapprovalReasons: recordDisapprovalReasons,
              },
            };
          } else if (record?.type === 'pending-ocr') {
            avaliationData = {
              pendingOcr: {
                doubleCheck: record?.documentscopy?.doubleCheck,
                fraud: isFraud,
                unreadable: isUnreadable,
                approved: false,
                disapprovalReasons: recordDisapprovalReasons,
              },
            };
          }

          const payload = {
            avaliationInfo: {
              evaluated: true,
              user: {
                _id: loggedUser._id,
                name: loggedUser.name,
                username: loggedUser.username,
              },
              time,
              reviewAt: new Date(),
            },
            ...avaliationData,
            type: record.type,
          };

          try {
            await put({
              url: `/records/${record._id}`,
              payload,
            });

            setAvaliationFinished(true);

            history.push(redirectUrl);
            window.location.reload();
          } catch (e) {
            showToastMessage({
              type: 'error',
              text: 'Falha ao reprovar documento.',
            });
            setAvaliationFinished(true);
            history.replace('/home');
          }
        },
      });
    } else {
      openModalInfo();
    }
  }, [
    disapprovalReasons,
    form,
    loggedUser,
    put,
    record,
    setAvaliationFinished,
    time,
    history,
    redirectUrl,
    openModalError,
    openModalInfo,
  ]);

  const reproveReasons = useMemo(() => {
    let disapprovalReasonsData;
    if (record.type === 'documentscopy') {
      disapprovalReasonsData = record?.documentscopy?.disapprovalReasons;
    } else if (record.type === 'pending-ocr') {
      disapprovalReasonsData = record?.pendingOcr?.disapprovalReasons;
    }
    if (
      disapprovalReasonsData?.disapprovalMessage ||
      (disapprovalReasonsData && disapprovalReasonsData?.length > 0)
    ) {
      return (
        <>
          {disapprovalReasonsData?.disapprovalMessage ? (
            <span className="mrg-btm-5">
              {disapprovalReasonsData?.disapprovalMessage}
              <br />
            </span>
          ) : (
            disapprovalReasonsData?.map((reason) => {
              if (reason.disapprovalMessageId) {
                return (
                  <span className="mrg-btm-5">
                    {reason.disapprovalMessage}
                    <br />
                  </span>
                );
              }
              return <></>;
            })
          )}
        </>
      );
    }

    return (
      <span className="mrg-btm-5">Este Documento não possui motivos de reprovação</span>
    );
  }, [record]);

  return (
    <div id="reprove-document-component">
      {ModalInfo}
      {ModalError}
      {isReview ? (
        <div>{reproveReasons}</div>
      ) : (
        <Form form={form} layout="vertical" autoComplete="off">
          {record?.queue.type === 'documentscopy' && (
            <>
              <Form.Item name="reproveReasons" label="Reprovação">
                <Select
                  labelInValue
                  showArrow={false}
                  showSearch
                  allowClear
                  placeholder="Selecione um motivo..."
                  optionLabelProp="label"
                  dropdownClassName="multiselect-filters"
                  listHeight={320}
                  dropdownAlign={{ offset: [0, 2] }}
                  removeIcon={<CircleFalse color="#333" />}
                  menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                  disabled={isReview}
                  dropdownMatchSelectWidth={false}
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <>
                    {disapprovalReasonsList.map((reason) => (
                      <Option
                        key={reason.key}
                        label={reason.label}
                        className="gx-text-danger"
                      >
                        {reason.label}
                      </Option>
                    ))}
                  </>
                </Select>
              </Form.Item>

              <Form.Item
                name="reproveReasonsUnreadable"
                label="Reprovação por Ilegibilidade"
              >
                <Select
                  mode="multiple"
                  labelInValue
                  placeholder="Selecione um ou mais motivos..."
                  optionLabelProp="label"
                  dropdownClassName="multiselect-filters"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                  listHeight={320}
                  dropdownAlign={{ offset: [0, 2] }}
                  removeIcon={<CircleFalse />}
                  menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                  disabled={isReview}
                  dropdownMatchSelectWidth={false}
                  onChange={onChange}
                >
                  <>
                    {disapprovalReasonsUnreadableList.map((reason) => (
                      <Option
                        key={reason.key}
                        label={reason.label}
                        className="gx-text-danger"
                      >
                        {reason.label}
                      </Option>
                    ))}
                  </>
                </Select>
              </Form.Item>

              <Form.Item name="reproveReasonsFraud" label="Reprovação por Fraude">
                <Select
                  mode="multiple"
                  labelInValue
                  placeholder="Selecione um ou mais motivos..."
                  optionLabelProp="label"
                  dropdownClassName="multiselect-filters"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                  listHeight={320}
                  dropdownAlign={{ offset: [0, 2] }}
                  removeIcon={<CircleFalse />}
                  menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                  disabled={isReview}
                  dropdownMatchSelectWidth={false}
                  onChange={onChange}
                >
                  <>
                    {disapprovalReasonsFraudList.map((reason) => (
                      <Option
                        key={reason.key}
                        label={reason.label}
                        className="gx-text-danger"
                      >
                        {reason.label}
                      </Option>
                    ))}
                  </>
                </Select>
              </Form.Item>
            </>
          )}

          {record?.queue.type === 'pending-ocr' && (
            <Form.Item name="reproveReasons" label="Reprovação">
              <Select
                labelInValue
                showArrow={false}
                showSearch
                allowClear
                placeholder="Selecione um motivo..."
                optionLabelProp="label"
                dropdownClassName="multiselect-filters"
                listHeight={320}
                dropdownAlign={{ offset: [0, 2] }}
                removeIcon={<CircleFalse color="#333" />}
                menuItemSelectedIcon={<Checkmark width={20} height={20} />}
                disabled={isReview}
                onChange={onChange}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <>
                  {disapprovalReasonsBackofficeList.map((reason) => (
                    <Option
                      key={reason.key}
                      label={reason.label}
                      className="gx-text-danger"
                    >
                      {reason.label}
                    </Option>
                  ))}
                </>
              </Select>
            </Form.Item>
          )}

          <div className="footer">
            {!isReview && (
              <Button
                onClick={handleSubmit}
                className="btn-reprove btn-custom-primary danger"
              >
                Reprovar
              </Button>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

ReproveDocument.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  setReproveChanged: PropTypes.func,
  setAvaliationFinished: PropTypes.func,
  redirectUrl: PropTypes.string,
};

ReproveDocument.defaultProps = {
  isReview: false,
  redirectUrl: '/manual-review-wizard',
  setReproveChanged: () => {},
  setAvaliationFinished: () => {},
};

export default ReproveDocument;
