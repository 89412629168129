import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Radio } from 'antd';
import PropTypes from 'prop-types';

import RadioButtonOptions from '../RadioButtonOptions';

import './styles.less';

const ConditionsPhotoAndSignature = ({
  recordData,
  formRef,
  modelId,
  setQualityPhotoSignatureData,
  isReview,
  setAllRulesCorrect,
  allRulesCorrect,
}) => {
  const [rules, setRules] = useState('');
  const { images } = recordData?.record?.executionInfo;
  const { documentscopy = null } = recordData?.record;
  const [photoValue, setPhotoValue] = useState(
    documentscopy?.automation?.photo?.prediction ? 'correct' : undefined
  );
  const [perforationValue, setPerforationValue] = useState(
    documentscopy?.automation?.perforation?.prediction ? 'correct' : undefined
  );
  const [approximateAgeValue, setApproximateAgeValue] = useState(
    documentscopy?.inputValues?.approximateAge ? 'correct' : undefined
  );

  useEffect(() => {
    if (allRulesCorrect.qualityPhotoSignature) {
      formRef.setFieldsValue({
        photoValue: 'correct',
        perforationValue: 'correct',
        approximateAgeValue: 'correct',
      });

      if (rules?.photo) {
        setPhotoValue('correct');
      }

      if (rules?.perforation) {
        setPerforationValue('correct');
      }

      if (documentscopy?.inputValues?.approximateAge) {
        setApproximateAgeValue('correct');
      }
    }
  }, [documentscopy, rules, allRulesCorrect, formRef, images]);

  useEffect(() => {
    if (documentscopy?.rulesValidation?.rule?._id === modelId) {
      setRules(documentscopy?.rulesValidation.rule);
    } else {
      setRules(
        documentscopy?.rulesValidation?.otherRules?.find((r) => r._id === modelId)
      );
    }
  }, [documentscopy, modelId]);

  useEffect(() => {
    if (isReview && documentscopy?.review?.additionalValidations?.qualityPhotoSignature) {
      const { qualityPhotoSignature } = documentscopy?.review?.additionalValidations;
      if (qualityPhotoSignature.photo?.selected) {
        formRef?.setFieldsValue({
          photoValue: qualityPhotoSignature.photo?.selected,
        });
      }
      if (qualityPhotoSignature.perforation?.selected) {
        formRef?.setFieldsValue({
          perforationValue: qualityPhotoSignature.perforation?.selected,
        });
      }
      if (qualityPhotoSignature.approximateAge?.selected) {
        formRef?.setFieldsValue({
          approximateAgeValue: qualityPhotoSignature.approximateAge?.selected,
        });
      }
    }
  }, [formRef, isReview, documentscopy]);

  function selectAll() {
    setAllRulesCorrect((prevState) => ({ ...prevState, qualityPhotoSignature: true }));
  }

  useEffect(() => {
    setQualityPhotoSignatureData({
      photo: {
        selected: photoValue?.target?.value || photoValue,
      },
      perforation: {
        selected: perforationValue?.target?.value || perforationValue,
      },
      approximateAge: {
        selected: approximateAgeValue?.target?.value || approximateAgeValue,
      },
    });
  }, [approximateAgeValue, perforationValue, photoValue, setQualityPhotoSignatureData]);

  return (
    <div id="condition-image-component">
      <Form
        form={formRef}
        initialValues={{
          photoValue,
          perforationValue,
          approximateAgeValue,
        }}
      >
        {rules?.model && (
          <Row>
            <span className="model">{`Modelo Escolhido: ${rules?.model || ''}`}</span>
          </Row>
        )}
        {rules?.photo && (
          <Row className="flex fd-row space-between">
            <Col className="label">
              <span>Foto: {rules?.photo}</span>
            </Col>
            <Col>
              <Form.Item name="photoValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setPhotoValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}

        {rules?.perforation && (
          <Row className="flex fd-row space-between">
            <Col className="label">
              <span>Perfuração: {rules?.perforation}</span>
            </Col>
            <Col>
              <Form.Item name="perforationValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setPerforationValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}

        {documentscopy?.inputValues?.approximateAge && (
          <Row className="flex fd-row space-between">
            <Col className="label">
              <span>{documentscopy?.inputValues?.approximateAge}</span>
            </Col>
            <Col>
              <Form.Item name="approximateAgeValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setApproximateAgeValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {!isReview &&
        recordData?.record?.executionInfo?.type?.toUpperCase() !== 'CNH' &&
        recordData?.record?.queue?.solutionType?.toUpperCase() !== 'OTHERS' && (
          <div className="footer">
            <Button type="link" primary onClick={() => selectAll()}>
              Todas as regras estão corretas
            </Button>
          </div>
        )}
    </div>
  );
};

ConditionsPhotoAndSignature.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  modelId: PropTypes.string,
  isReview: PropTypes.bool,
  setQualityPhotoSignatureData: PropTypes.func,
  setAllRulesCorrect: PropTypes.func,
  allRulesCorrect: PropTypes.objectOf(PropTypes.objectOf),
};

ConditionsPhotoAndSignature.defaultProps = {
  modelId: null,
  isReview: false,
  allRulesCorrect: {},
  setQualityPhotoSignatureData: () => {},
  setAllRulesCorrect: () => {},
};

export default ConditionsPhotoAndSignature;
