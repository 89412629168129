import React from 'react';
import { useCountdown } from 'hooks/countdown';
import ReactCountdown from 'react-countdown';

import './styles.less';

const Countdown = () => {
  const { timeLast, renderer } = useCountdown();

  return (
    <div id="countdown-component" className="countdown">
      <ReactCountdown date={Date.now() + timeLast} renderer={renderer} />
    </div>
  );
};

export default Countdown;
