import React from 'react';
import PropTypes from 'prop-types';
import { Button as StandardButton } from 'antd';

import './styles.less';

const Button = ({ outlined, rounded, children, ...rest }) => (
  <StandardButton
    id="standard-button-component"
    {...rest}
    ghost={outlined}
    style={{
      ...(rounded && {
        borderRadius: 100,
      }),
    }}
  >
    {children}
  </StandardButton>
);

Button.propTypes = {
  outlined: PropTypes.bool,
  rounded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.node,
    PropTypes.string,
  ]),
};

Button.defaultProps = {
  outlined: false,
  rounded: true,
  children: null,
};

export default Button;
