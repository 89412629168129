import React from 'react';
import { Form, Input } from 'antd';
import InputMask from 'components/Form/InputMask';
import PropTypes from 'prop-types';

const TypeOthers = ({ isReview }) => (
  <>
    <div>
      <Form.Item name="name" label="Nome completo (opcional)">
        <Input className="custom-input" disabled={isReview} />
      </Form.Item>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="motherName" label="Nome da mãe (opcional)">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="fatherName" label="Nome do pai (opcional)">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="cpf" label="CPF">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="birthDate" label="Data de nascimento">
          <InputMask
            className="custom-input input-sm"
            mask="99/99/9999"
            disabled={isReview}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="issueDate" label="Data de emissão">
          <InputMask
            className="custom-input input-sm"
            mask="99/99/9999"
            disabled={isReview}
          />
        </Form.Item>
      </div>
    </div>
  </>
);

TypeOthers.propTypes = {
  isReview: PropTypes.bool,
};

TypeOthers.defaultProps = {
  isReview: false,
};

export default TypeOthers;
