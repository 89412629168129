import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useQueryParams } from 'use-query-params';
import { Row, Col, Table } from 'antd';

import { useFetch } from 'services/hooks';

import ViewMore from 'components/ViewMore';
import showToastMessage from 'components/ToastMessage';

import { initialFilterData } from '../utils';

const useTable = ({
  rowKey,
  getParams,
  columns,
  queryParams,
  customLoading = false,
  rowClassName,
}) => {
  const { get, loading, setLoading } = useFetch();

  const [data, setData] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);

  const [params, setParams] = useState(getParams);
  const [query, setQuery] = useQueryParams({
    ...queryParams,
  });
  const [filterData, setFilterData] = useState(() =>
    initialFilterData({ query, queryParams })
  );

  const fetch = useCallback(
    async ({ loadMore }) => {
      try {
        if (!params) return;

        const { username, id } = lastEvaluatedKey || {};

        const requestParams =
          lastEvaluatedKey && loadMore
            ? { ...params.config.params, ...filterData, lastEvaluatedKey: username || id }
            : { ...params.config.params, ...filterData };

        const response = await get({
          url: params.url,
          config: {
            params: requestParams,
          },
          showMessage: false,
        });
        const { lastEvaluatedKey: respLastEvaluatedKey, docs } = response || {};

        setLastEvaluatedKey(respLastEvaluatedKey);

        if (loadMore) {
          setData((oldState) => [...oldState, ...(docs || [])]);
        } else {
          setData(docs);
        }
      } catch (err) {
        showToastMessage({
          type: 'error',
          text: 'Erro ao obter os dados da lista.',
        });
      }
    },
    [get, params, lastEvaluatedKey, filterData]
  );

  const refreshList = useCallback(() => {
    fetch({ loadMore: false });
  }, [fetch]);

  const updateParams = useCallback((newParams) => {
    setParams(newParams);
  }, []);

  useEffect(() => {
    if (!data?.length) return;
    fetch({ loadMore: false });
  }, [filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data?.length) refreshList();
  }, [params, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuery({ ...filterData });
  }, [filterData, setQuery]);

  const tableContent = useMemo(
    () =>
      columns && (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={data || []}
                loading={loading || customLoading}
                pagination={false}
                showSorterTooltip={false}
                rowKey={rowKey}
                className="custom-table-dark mrg-btm-30"
                rowClassName={rowClassName}
              />
            </Col>
          </Row>

          {lastEvaluatedKey && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <ViewMore
                  title={loading ? 'Carregando...' : 'Carregar mais'}
                  text={loading}
                  className="center"
                  loading={loading}
                  onClick={() => fetch({ loadMore: true })}
                />
              </Col>
            </Row>
          )}
        </>
      ),
    [columns, data, loading, customLoading, rowKey, lastEvaluatedKey, fetch, rowClassName]
  );

  return {
    data,
    tableContent,
    refreshList,
    updateParams,
    setFilterData,
    loading,
    setLoading,
    query,
  };
};

export default useTable;
