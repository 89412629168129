import React, { useMemo } from 'react';
import { Collapse, Select } from 'antd';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import countries from 'i18n-iso-countries';

import {
  Checkmark,
  CircleFalse,
  CheckCircle2,
  ProcessingLine,
  WrongCircle,
  ArrowDown,
  ArrowRight,
} from '@combateafraude/icons/general';

import documentsType from 'utils/data/documentsTypeData.json';

import SelectCountry from 'components/SelectCountry';

import ReproveDocument from './components/ReproveDocument';
import Observation from './components/Observation';
import AvaliationInfo from './components/AvaliationInfo';
import DisplayRules from './components/DisplayRules';

import '../styles.less';

const { Panel } = Collapse;
const { Option } = Select;

const AvaliationOthersType = ({
  recordData,
  isReview,
  reproveChanged,
  setReproveChanged,
  setAvaliationFinished,
  redirectUrl,
  documentType,
  setDocumentType,
  country,
  setCountry,
}) => {
  const { hash } = useLocation();

  const {
    avaliationInfo = null,
    documentscopy = null,
    relatedRecordId = null,
    queue = null,
    settings = null,
    executionInfo = null,
  } = recordData?.record;

  const paramCountryCode =
    executionInfo?.parameters?.countryCode ||
    countries.alpha3ToAlpha2(
      executionInfo?.sections?.globalDocVerification?.data?.nationalityCode
    );

  const countryCode = country || paramCountryCode || 'BR';

  const defaultValuesCollapse = useMemo(() => {
    const values = [];

    if (
      (documentscopy?.obs && queue?.solutionLevel !== 'quality') ||
      (relatedRecordId && queue?.solutionLevel !== 'quality') ||
      queue?.solutionLevel === 'poc' ||
      queue?.solutionLevel === 'qrcode'
    ) {
      values.push('obs');
    }

    if (isReview) {
      values.push('avaliation-info');
    } else {
      values.push('reprove');

      if (settings?.evaluationRulesEnabled) {
        values.push('evaluationRules');
      }
    }

    return values;
  }, [documentscopy, relatedRecordId, isReview, queue, settings]);

  return (
    <>
      <div id="type-document-selector">
        <span className="label"> Tipo de documento: </span>
        <Select
          showSearch
          showArrow={!isReview}
          allowClear
          value={isReview ? documentscopy?.review?.documentType : documentType}
          onChange={(e) => setDocumentType(e)}
          placeholder={!isReview && 'Digite o modelo...'}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            parseInt(optionA.key, 10) > parseInt(optionB.key, 10)
          }
          listHeight={320}
          dropdownAlign={{ offset: [0, 2] }}
          removeIcon={<CircleFalse />}
          menuItemSelectedIcon={<Checkmark color="#fff" />}
          disabled={isReview}
        >
          {documentsType?.map((d) => (
            <Option key={d.id} label={d.type} value={d.type}>
              {d.type}
            </Option>
          ))}
        </Select>
      </div>
      <div id="country-document-selector">
        <span className="label">País do documento: </span>
        <SelectCountry
          allowClear
          showArrow={!isReview}
          placeholder={!isReview && 'Digite o país...'}
          value={isReview ? documentscopy?.review?.documentOrigin : countryCode}
          onChange={(e) => setCountry(e)}
          disabled={isReview}
        />
      </div>

      <Collapse className="mrg-left-10" defaultActiveKey={defaultValuesCollapse} ghost>
        {isReview && (
          <Panel
            header="Dados referente a avaliação"
            key="avaliation-info"
            className={`${classNames({
              'primary-border': avaliationInfo?.evaluated && documentscopy.approved,
              'danger-border': avaliationInfo?.evaluated && !documentscopy.approved,
              'success-border': avaliationInfo?.evaluated && documentscopy.reprocess,
            })} mrg-btm-20`}
            extra={
              // eslint-disable-next-line no-nested-ternary
              avaliationInfo?.evaluated && documentscopy?.approved !== null ? (
                documentscopy?.approved ? (
                  <CheckCircle2 className="gx-text-primary" width={24} height={24} />
                ) : (
                  <WrongCircle className="gx-text-danger" width={24} height={24} />
                )
              ) : (
                documentscopy?.reprocess && (
                  <ProcessingLine width={24} height={24} className="gx-text-success" />
                )
              )
            }
            expandIcon={({ isActive }) =>
              isActive ? (
                <ArrowDown width={24} height={24} />
              ) : (
                <ArrowRight width={24} height={24} />
              )
            }
          >
            <AvaliationInfo recordData={recordData} />
          </Panel>
        )}

        {settings?.evaluationRulesEnabled && (
          <Panel
            header="Regras específicas de cliente"
            key="evaluationRules"
            className={`${classNames({
              'warning-border': defaultValuesCollapse.includes('evaluationRules'),
            })} mrg-btm-20`}
          >
            <DisplayRules
              approveRules={settings.approveEvaluationRules}
              reproveRules={settings.reproveEvaluationRules}
              fantasyName={executionInfo?.fantasyName}
            />
          </Panel>
        )}
        <Panel
          header="Observações sobre o documento"
          key="obs"
          className={`${classNames({
            'warning-border': defaultValuesCollapse.includes('obs'),
          })} mrg-btm-20`}
        >
          <Observation recordData={recordData} />
        </Panel>

        {!isReview && (
          <Panel
            id="reprove-component"
            header="Reprovar o documento"
            key="reprove"
            className={`${classNames({
              'danger-border': hash === '#reprove-component',
            })} mrg-btm-20`}
          >
            <ReproveDocument
              recordData={recordData}
              isReview={isReview}
              reproveChanged={reproveChanged}
              setReproveChanged={setReproveChanged}
              setAvaliationFinished={setAvaliationFinished}
              redirectUrl={redirectUrl}
            />
          </Panel>
        )}
      </Collapse>
    </>
  );
};

AvaliationOthersType.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  setReproveChanged: PropTypes.func,
  reproveChanged: PropTypes.bool,
  setAvaliationFinished: PropTypes.func,
  redirectUrl: PropTypes.string,
  documentType: PropTypes.string,
  setDocumentType: PropTypes.func,
  country: PropTypes.string,
  setCountry: PropTypes.func,
};

AvaliationOthersType.defaultProps = {
  isReview: false,
  reproveChanged: false,
  setReproveChanged: () => {},
  setAvaliationFinished: () => {},
  redirectUrl: '/manual-review-wizard',
  documentType: undefined,
  setDocumentType: () => {},
  country: undefined,
  setCountry: () => {},
};

export default AvaliationOthersType;
