import React, { useMemo, useState, useCallback } from 'react';
import { Modal, Row, Col, Carousel } from 'antd';
import { Close } from '@combateafraude/icons/general';

import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import Page5 from './pages/Page5';
import Page6 from './pages/Page6';

const InfoWeCheck = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const InfoWeCheckModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="info-modal"
        closable
        closeIcon={<Close width={24} height={24} />}
        onCancel={closeModal}
        footer={null}
        centered
      >
        <Row align="middle" justify="center" id="info">
          <Col span={22} className="info-carousel">
            <Carousel arrows>
              <Page1 />
              <Page2 />
              <Page3 />
              <Page4 />
              <Page5 />
              <Page6 />
            </Carousel>
          </Col>
        </Row>
      </Modal>
    ),
    [modalVisible, closeModal]
  );

  return {
    openModal,
    closeModal,
    InfoWeCheckModal,
  };
};

export default InfoWeCheck;
