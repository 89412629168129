import React from 'react';

import PageTitle from 'components/PageTitle';

const Page4 = () => (
  <div id="info-page" className="info-page mrg-top-125 mrg-btm-95 pdd-horizon-170">
    <div className="content">
      <PageTitle title="E quando tem Double Check?" />
      <div className="mrg-top-30">
        <p>
          Registros que foram gerados por conta de dupla checagem, o reprocessamento são
          inseridos na fila com prioridade inicial da primeira avaliação.
        </p>
        <p>
          <strong>Exemplo:</strong>
          <br />
          Vamos seguir com o mesmo exemplo anterior onde a prioridade é 181, se o registro
          de double check está 2min na fila, então sua prioridade é 181 + (2 * 60) = 301
        </p>
        <p>
          <strong>Importante:</strong> A dupla checagem sempre vai ser feita por um
          operador avançado.
        </p>
      </div>
    </div>
  </div>
);

export default Page4;
