import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { Info, CheckCircle2, Crash, AlertTriangle } from '@combateafraude/icons/general';

import './styles.less';

const ToastMessage = ({ type, text, className }) => {
  if (type === 'info') {
    return message.info({
      className: `toast-message ${className}`,
      content: text,
      icon: <Info className="gx-text-primary" width={20} height={20} />,
    });
  }

  if (type === 'success') {
    return message.success({
      className: `toast-message ${className}`,
      content: text,
      icon: <CheckCircle2 className="gx-text-primary" width={20} height={20} />,
    });
  }

  if (type === 'warning') {
    return message.warning({
      className: `toast-message ${className}`,
      content: text,
      icon: <AlertTriangle className="gx-text-warning" width={20} height={20} />,
    });
  }

  return message.error({
    className: `toast-message ${className}`,
    content: text,
    icon: <Crash className="gx-text-danger" width={20} height={20} />,
  });
};

ToastMessage.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ToastMessage;
