import React, { useMemo } from 'react';
import { Collapse } from 'antd';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  CheckCircle2,
  WrongCircle,
  Info,
  ArrowDown,
  ArrowRight,
} from '@combateafraude/icons/general';

import Observation from './components/Observation';
import ReproveDocument from './components/ReproveDocument';
import OCRDetails from './components/OCRDetails';
import AvaliationInfo from './components/AvaliationInfo';

const { Panel } = Collapse;

const AvaliationPendingOcr = ({
  recordData,
  isReview,
  reproveChanged,
  setReproveChanged,
  setAvaliationFinished,
  redirectUrl,
  formRef,
}) => {
  const { hash } = useLocation();

  const {
    avaliationInfo = null,
    pendingOcr = null,
    relatedRecordId = null,
    queue = null,
  } = recordData?.record;

  const defaultValuesCollapse = useMemo(() => {
    const values = ['ocr'];

    if (
      (pendingOcr?.obs && queue?.solutionLevel !== 'quality') ||
      (relatedRecordId && queue?.solutionLevel !== 'quality') ||
      queue?.solutionLevel === 'poc' ||
      queue?.solutionLevel === 'qrcode'
    ) {
      values.push('obs');
    }

    if (isReview) {
      values.push('avaliation-info');
    } else {
      values.push('reprove');
    }

    return values;
  }, [isReview, pendingOcr, relatedRecordId, queue]);
  return (
    <Collapse className="mrg-left-10" defaultActiveKey={defaultValuesCollapse} ghost>
      {isReview && (
        <Panel
          header="Dados referente a avaliação"
          key="avaliation-info"
          className={`${classNames({
            'primary-border': avaliationInfo?.evaluated && pendingOcr?.approved,
            'danger-border': avaliationInfo?.evaluated && !pendingOcr?.approved,
          })} mrg-btm-20`}
          extra={
            // eslint-disable-next-line no-nested-ternary
            avaliationInfo?.evaluated &&
            pendingOcr?.approved !== null &&
            (pendingOcr?.approved ? (
              <CheckCircle2 color="#004AF7" width={24} height={24} />
            ) : (
              <WrongCircle color="#E21B45" width={24} />
            ))
          }
          expandIcon={({ isActive }) =>
            isActive ? (
              <ArrowDown width={24} height={24} />
            ) : (
              <ArrowRight width={24} height={24} />
            )
          }
        >
          <AvaliationInfo recordData={recordData} />
        </Panel>
      )}
      <Panel
        header="Observações sobre documento"
        key="obs"
        className={`${classNames({
          'warning-border': defaultValuesCollapse.includes('obs'),
        })} mrg-btm-20`}
      >
        <Observation recordData={recordData} />
      </Panel>

      <Panel
        header="Detalhamento do OCR"
        key="ocr"
        className="mrg-btm-20"
        extra={<Info width={24} height={24} className="gx-text-warning" />}
      >
        <OCRDetails
          recordData={recordData}
          isReview={isReview}
          setAvaliationFinished={setAvaliationFinished}
          redirectUrl={redirectUrl}
          formRef={formRef}
        />
      </Panel>
      {!isReview && (
        <Panel
          id="reprove-component"
          header="Reprovar o documento"
          key="reprove"
          className={`${classNames({
            'danger-border': hash === '#reprove-component',
          })} mrg-btm-20`}
        >
          <ReproveDocument
            recordData={recordData}
            isReview={isReview}
            reproveChanged={reproveChanged}
            setReproveChanged={setReproveChanged}
            setAvaliationFinished={setAvaliationFinished}
            redirectUrl={redirectUrl}
          />
        </Panel>
      )}
    </Collapse>
  );
};

AvaliationPendingOcr.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  setReproveChanged: PropTypes.func,
  reproveChanged: PropTypes.bool,
  setAvaliationFinished: PropTypes.func,
  redirectUrl: PropTypes.string.isRequired,
};

AvaliationPendingOcr.defaultProps = {
  isReview: false,
  setReproveChanged: () => {},
  reproveChanged: () => {},
  setAvaliationFinished: () => {},
};

export default AvaliationPendingOcr;
