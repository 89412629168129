import React from 'react';
import { Card as CardComponent } from 'antd';

import './styles.less';

const Card = ({ children, ...rest }) => (
  <CardComponent {...rest}>{children}</CardComponent>
);

export default Card;
