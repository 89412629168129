/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Row, Col, Typography, Alert, Button } from 'antd';
import {
  useQueryParams,
  StringParam,
  NumberParam,
  ArrayParam,
  withDefault,
} from 'use-query-params';
import _ from 'lodash';

import { useComponentList } from 'components/List';
import { SortSelect } from 'components/List/components';

import CardRecord from './components/CardRecord';
import OpenAllDropdown from './components/OpenAllDropdown';
import { creationDateDates } from '../ReviewFilter/utils';

import './styles.less';

const { Text, Title } = Typography;

const ReviewList = ({ isLoadingList, setIsLoadingList, errorFilter }) => {
  const { componentList, data, updateParams, loading, error } = useComponentList({
    component: CardRecord,
    rowKey: '_id',
    allowEmptyListWorkaround: true,
    showQuickJumper: true,
  });

  useEffect(() => {
    setIsLoadingList(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const alertErrorReviewLoad = useMemo(() => {
    const errorMessage =
      error?.data?.message || errorFilter || 'Faça um novo filtro e tente novamente';
    return (
      <div id="alert-error-review-load">
        <Alert
          message="Erro"
          showIcon
          description={`Erro ao tentar consultar os dados: ${errorMessage}`}
          type="error"
          action={
            <>
              <Button
                size="small"
                danger
                onClick={() => {
                  window.location.reload();
                }}
              >
                Recarregar a página
              </Button>
              <Button
                size="small"
                onClick={() => {
                  window.history.back();
                }}
              >
                Voltar para a lista de execuções
              </Button>
            </>
          }
        />
      </div>
    );
  }, [error, errorFilter]);

  const formattedErrorStatusCode = error?.statusCode
    ? error?.statusCode.toString()
    : error && 'error';

  const hasReviewError = ['500', '503', '401', '400', 'error'].includes(
    formattedErrorStatusCode
  );

  const [query, setQuery] = useQueryParams({
    creationDate: StringParam,
    startDate: NumberParam,
    endDate: NumberParam,
    reviewedAt: StringParam,
    status: withDefault(ArrayParam, []),
    search: withDefault(ArrayParam, []),
    _order: StringParam,
    _sort: StringParam,
    recordTypes: withDefault(ArrayParam, []),
    evaluationStatus: StringParam,
    approvalStatus: StringParam,
    documentType: StringParam,
    issueState: StringParam,
    issueYear: StringParam,
    issueMonth: StringParam,
    disapprovalReason: StringParam,
    specificDisapprovalReasons: withDefault(ArrayParam, []),
    email: StringParam,
    doubleCheck: StringParam,
    incorrectAnalysis: StringParam,
    reportSuspect: StringParam,
    tenantId: withDefault(ArrayParam, []),
  });
  const querySnapshot = useRef({});
  const [showCards, setShowCards] = useState(false);

  const fetch = useCallback(() => {
    const params = {};

    if (query.reviewedAt) params.reviewedAt = query.reviewedAt;

    if (query.creationDate) {
      if (!query.startDate || !query.endDate) {
        const dateParams = creationDateDates(query.creationDate);
        setQuery(dateParams);
        return;
      }

      params._startCreateDate = query.startDate;
      params._endCreateDate = query.endDate;
    }

    if (query.status && !_.isEmpty(query.status)) {
      params.status = query.status.join(';');
    }

    if (query.search && !_.isEmpty(query.search)) {
      params._search = query.search.join(';');
    }

    if (query._sort && query._order) {
      params._sort = query._sort;
      params._order = query._order;
    }

    if (query.recordTypes && !_.isEmpty(query.recordTypes)) {
      params.recordTypes = query.recordTypes.join(';');
    }

    if (query.evaluationStatus) {
      params.evaluationStatus = query.evaluationStatus;
    }

    if (query.approvalStatus) {
      params.approvalStatus = query.approvalStatus;
    }

    if (query.documentType) {
      params.documentType = query.documentType;
    }

    if (query.issueState) {
      params.issueState = query.issueState;
    }

    if (query.issueYear) {
      params.issueYear = query.issueYear;
    }

    if (query.issueMonth) {
      params.issueMonth = query.issueMonth;
    }

    if (query.disapprovalReason) {
      params.disapprovalReason = query.disapprovalReason;
    }

    if (
      query.specificDisapprovalReasons &&
      !_.isEmpty(query.specificDisapprovalReasons)
    ) {
      params.specificDisapprovalReasons = query.specificDisapprovalReasons.join(';');
    }

    if (query.email) {
      params.email = query.email;
    }

    if (query.doubleCheck) {
      params.doubleCheck = query.doubleCheck;
    }

    if (query.incorrectAnalysis) {
      params.incorrectAnalysis = query.incorrectAnalysis;
    }

    if (query.reportSuspect) {
      params.reportSuspect = query.reportSuspect;
    }

    if (query.tenantId && !_.isEmpty(query.tenantId)) {
      params.tenantId = query.tenantId.join(';');
    }

    if (!_.isEmpty(params)) {
      setShowCards(true);
      updateParams({
        url: '/records',
        config: {
          params,
        },
      });
    }
  }, [query, updateParams, setQuery, setShowCards]);

  useEffect(() => {
    // Não recarregar quando filtro de 'Intervalo personalizado' for selecionado (creationDate === 'custom')
    const changeCreationDate =
      querySnapshot.current.creationDate !== query.creationDate &&
      query.creationDate === 'custom';

    if (_.isEqual(querySnapshot.current, query)) return;
    querySnapshot.current = query;

    if (!changeCreationDate) {
      fetch();
    }
  }, [query, fetch]);

  return (
    <div id="review-list-component">
      <Row align="middle" justify="center" className="no-mrg">
        <Col flex={1} className="page-title">
          <Title className="title">Consultas</Title>
          <Text className="subtitle">
            {showCards
              ? 'Exibindo resultado com base nos filtros aplicados'
              : 'É necessário atualizar os filtros e consultar para exibir os resultados'}
          </Text>
        </Col>
        {hasReviewError || errorFilter ? (
          alertErrorReviewLoad
        ) : (
          <>
            <Col className="flex center">
              <OpenAllDropdown data={data} loading={isLoadingList} />
              <SortSelect />
            </Col>
          </>
        )}
      </Row>
      {showCards && !hasReviewError && !errorFilter ? componentList : ''}
    </div>
  );
};

export default ReviewList;
