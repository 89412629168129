import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal as StandardModal, Typography, Radio, Input } from 'antd';
import alertImg from 'assets/images/alert-img.svg';

import { useFetch } from 'services/hooks';
import Button from 'components/Button';
import showToastMessage from 'components/ToastMessage';

import './styles.less';

const { Title } = Typography;

const ReportSuspectModal = ({ executionInfo }) => {
  const { post } = useFetch();

  const { tenantId = null, executionId = null, images = null } = executionInfo;

  const [selectedImage, setSelectedImage] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(async () => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setSelectedImage(undefined);
    setModalVisible(false);
  }, []);

  const confirmModal = useCallback(async () => {
    StandardModal.confirm({
      className: 'confirm-report-suspect-modal',
      img: alertImg,
      title: 'Você confirma a ação de reportar um suspeito?',
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      width: 314,
      height: 171,
      onOk: async () => {
        try {
          await post({
            url: `/post-evaluation-review/report-suspect`,
            payload: {
              tenantId,
              executionId,
              description,
              imageUrl: images?.[selectedImage],
              imageType: selectedImage,
            },
          });

          closeModal();

          window.location.reload();
        } catch (err) {
          const errMessage = err?.response?.data?.message;
          showToastMessage({
            type: 'error',
            text:
              errMessage === 'Already reported suspect on this execution'
                ? 'Suspeito já reportado nessa execução'
                : 'Falha ao reportar suspeito.',
          });

          if (errMessage === 'Already reported suspect on this execution') {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      },
    });
  }, [closeModal, post, tenantId, executionId, images, description, selectedImage]);

  const Modal = useMemo(
    () => (
      <StandardModal
        okText="Ok"
        okType="primary"
        visible={modalVisible}
        wrapClassName="report-suspect-modal-component"
        footer={null}
        centered
        closable
        maskClosable={false}
        onCancel={closeModal}
        keyboard
      >
        <div className="modal-header">
          <Title level={4} className="title">
            Informações para reportar como suspeito
          </Title>
        </div>

        <div className="modal-body">
          <Input.TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Descrição"
            className="resize-none mrg-btm-15"
          />
          <Radio.Group
            value={selectedImage}
            onChange={(e) => {
              setSelectedImage(e.target.value);
            }}
            className="flex-column center-start"
            style={{ gap: '12px' }}
          >
            <Radio value="front">Frente do documento</Radio>
            {images?.selfie && <Radio value="selfie">Selfie</Radio>}
          </Radio.Group>
        </div>

        <div className="modal-footer flex space-between">
          <Button onClick={closeModal}>Cancelar</Button>
          <Button
            type="danger"
            onClick={confirmModal}
            disabled={!selectedImage || !description?.trim()?.length}
          >
            Reportar
          </Button>
        </div>
      </StandardModal>
    ),
    [modalVisible, closeModal, description, selectedImage, confirmModal, images?.selfie]
  );

  return {
    openModal,
    closeModal,
    Modal,
  };
};

ReportSuspectModal.propTypes = {
  executionInfo: PropTypes.objectOf(PropTypes.objectOf).isRequired,
};

export default ReportSuspectModal;
