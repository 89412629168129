import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Login from 'pages/public/Login';

import Unauthorized from 'pages/private/Unauthorized';
import Review from 'pages/private/Review';
import {
  RecordDetailsReview,
  RecordDetailsEvaluationLiveness,
} from 'pages/private/Records/RecordDetails';
import Avaliation from 'pages/private/Avaliation';
import Home from 'pages/private/Home';
import Queue from 'pages/private/Queue';
import OperationsGroupsList from 'pages/private/OperationsGroups/OperationsGroupsList';
import CreateOrUpdateOperationsGroup from 'pages/private/OperationsGroups/CreateOrUpdateOperationsGroup';
import OperationsGroupsUsersList from 'pages/private/OperationsGroups/UsersList';
// import Cookies from 'js-cookie';

import Route from './Route';

const Routes = () => (
  // useEffect(() => {
  //   const element = document.body;
  //   if (Cookies.get('CAF-dark-theme')) {
  //     element.classList.toggle('dark-theme');
  //   } else {
  //     element.classList.remove('dark-theme');
  //   }
  // }, []);

  <Switch>
    <Route path="/login" exact component={Login} />

    <Route path="/unauthorized" exact component={Unauthorized} isPrivate />

    <Route
      path="/reviews"
      exact
      component={Review}
      isPrivate
      requiredPermissions={['wecheck_revision:read']}
    />

    <Route
      path="/documentscopy/records/details/record/:recordId"
      component={RecordDetailsReview}
      exact
      isPrivate
      or
      requiredPermissions={[
        'wecheck_revision:read',
        'wecheck_evaluation:read',
        'wecheck_evaluation:update',
      ]}
    />

    <Route
      path="/pending-ocr/records/details/record/:recordId"
      component={RecordDetailsReview}
      exact
      isPrivate
      or
      requiredPermissions={[
        'wecheck_revision:read',
        'wecheck_evaluation:read',
        'wecheck_evaluation:update',
      ]}
    />

    <Route
      path="/double-check-liveness/records/details/record/:recordId"
      component={RecordDetailsEvaluationLiveness}
      exact
      isPrivate
      or
      requiredPermissions={[
        'wecheck_revision:read',
        'wecheck_evaluation:read',
        'wecheck_evaluation:update',
      ]}
    />

    <Route
      path="/home"
      component={Home}
      exact
      isPrivate
      or
      requiredPermissions={[
        'wecheck_revision:read',
        'wecheck_evaluation:read',
        'wecheck_evaluation:update',
      ]}
    />

    <Route
      path="/manual-review-wizard"
      component={Avaliation}
      exact
      isPrivate
      or
      requiredPermissions={['wecheck_evaluation:update']}
    />

    <Route path="/queue" component={Queue} exact isPrivate />

    <Route
      path="/operations-groups"
      exact
      component={OperationsGroupsList}
      isPrivate
      requiredPermissions={['wecheck_configs:read']}
    />

    <Route
      path="/operations-groups/users"
      exact
      component={OperationsGroupsUsersList}
      isPrivate
      requiredPermissions={['wecheck_configs:read']}
    />

    <Route
      path="/operations-groups/create"
      exact
      component={CreateOrUpdateOperationsGroup}
      isPrivate
      requiredPermissions={['wecheck_configs:create']}
    />

    <Route
      path="/operations-groups/:id"
      component={CreateOrUpdateOperationsGroup}
      isPrivate
      requiredPermissions={['wecheck_configs:update']}
    />

    <Redirect to="/home" />
  </Switch>
);
export default Routes;
