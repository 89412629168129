import React, { createContext, useCallback, useContext, useState } from 'react';

import { useFetch } from 'services/hooks';

const RecordContext = createContext({});

const RecordProvider = ({ children }) => {
  const {
    data: recordData,
    loading: loadingRecord,
    get,
    clearData: clearRecord,
    error,
  } = useFetch();

  const [loadDataParams, setLoadDataParams] = useState({});

  const loadData = useCallback(
    ({ recordId, clearData }) => {
      setLoadDataParams({
        recordId,
      });

      get({
        url: `/records/${recordId}`,
        clearData,
      });
    },
    [get]
  );

  const reloadData = useCallback(
    (clearData = true) => {
      if (!loadDataParams) return;

      loadData({ ...loadDataParams, clearData });
    },
    [loadDataParams, loadData]
  );

  return (
    <RecordContext.Provider
      value={{
        recordData,
        loadingRecord,
        loadData,
        reloadData,
        clearRecord,
        error,
      }}
    >
      {children}
    </RecordContext.Provider>
  );
};

function useRecord() {
  const context = useContext(RecordContext);

  if (!context) {
    throw new Error('useRecord must be used within an RecordContext');
  }

  return context;
}

export { RecordProvider, useRecord };
