import React from 'react';
import { Input } from 'antd';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const InputMask = ({ disabled, ...rest }) => (
  <ReactInputMask {...rest} disabled={disabled}>
    {(inputProps) => <Input {...inputProps} disabled={disabled} />}
  </ReactInputMask>
);

InputMask.propTypes = {
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.instanceOf(RegExp)),
  ]),
  maskChar: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  beforemaskedstatechange: PropTypes.func,
  ...Input.props,
};

InputMask.defaultProps = {
  mask: null,
  maskChar: null,
  alwaysShowMask: false,
  beforemaskedstatechange: null,
};

export default InputMask;
