/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import PropTypes from 'prop-types';

import ZoomedImage from 'components/Image/ZoomedImage';
import imageError from 'assets/images/imageError.png';
import DocumentsHeader from './DocumentsHeader';

const { TabPane } = Tabs;

const CnhDocumentsConfirmation = ({ recordData, setModelId, modelId, isReview }) => {
  const { queue = null } = recordData?.record;

  const { documentscopy = null } = recordData?.record;
  const [keyTabAvaliation, setKeyTabAvaliation] = useState(
    queue?.solutionLevel === 'qrcode' || queue?.type === 'documentscopy' ? '3' : '1'
  );

  const [keyTabReference, setKeyTabReference] = useState(
    documentscopy?.rulesValidation?.rule?.model
      ? `${documentscopy?.rulesValidation?.rule?.model}.1`
      : undefined
  );

  useEffect(() => {
    setModelId(
      documentscopy?.rulesValidation?.rule?.model
        ? `${documentscopy?.rulesValidation?.rule?.model}.1`
        : undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const referenceTabs = useCallback(
    (rule, menuItems, height) => {
      const tabsList = [];
      for (const [key, value] of Object.entries(rule?.referenceImages)) {
        tabsList.push(
          <TabPane key={key} forceRender>
            <div className="mrg-btm-15">
              <ZoomedImage
                src={value?.front}
                alt="Documento frente"
                height={height}
                label="Referência"
                showScaleButton
                setKeyTab={setKeyTabReference}
                keyTab={keyTabReference}
                showMenu
                menuItems={menuItems}
                id={`${key}-model-reference-front-document-image`}
              />
            </div>
            <ZoomedImage
              src={value?.back}
              alt="Documento verso"
              height={height}
              label="Referência"
              showScaleButton
              id={`${key}-model-reference-back-document-image`}
            />
          </TabPane>
        );
      }
      return tabsList;
    },
    [keyTabReference]
  );

  const imageTabs = useCallback(
    (height = undefined) => {
      if (!recordData) return <></>;

      const MenuItemsAvaliation = [
        { key: '1', label: 'Imagem tratada' },
        { key: '2', label: 'Imagem cortada' },
        { key: '3', label: 'Imagem original' },
      ];

      const MenuItemsReference = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(
        documentscopy?.rulesValidation?.rule?.referenceImages || {}
      )) {
        MenuItemsReference.push({
          key,
          label: `Modelo ${key || ''}`,
        });
      }

      documentscopy?.rulesValidation?.otherRules?.forEach((otherRule) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key] of Object.entries(otherRule?.referenceImages || {})) {
          MenuItemsReference.push({
            key,
            label: `Modelo ${key || ''}`,
          });
        }
      });

      const {
        front = null,
        back = null,
        originalFront = null,
        originalBack = null,
        originalFrontCompressed = null,
        originalBackCompressed = null,
      } = recordData?.record?.executionInfo?.images;
      const containsImage =
        originalFrontCompressed ||
        originalBackCompressed ||
        front ||
        back ||
        originalFront ||
        originalBack;

      return containsImage ? (
        <Row>
          <Col span={12}>
            <Tabs className="tabs-zoomed-images" activeKey={keyTabAvaliation}>
              <TabPane key="1" forceRender>
                <div className="mrg-btm-15">
                  <ZoomedImage
                    src={originalFrontCompressed || originalFront}
                    alt="Documento frente"
                    height={height}
                    label="Análise"
                    className="mrg-btm-10"
                    showScaleButton
                    setKeyTab={setKeyTabAvaliation}
                    keyTab={keyTabAvaliation}
                    showMenu
                    menuItems={MenuItemsAvaliation}
                    id="review-compressed-front-document-image"
                  />
                </div>
                <ZoomedImage
                  src={originalBackCompressed || originalBack}
                  alt="Documento verso"
                  height={height}
                  label="Análise"
                  showScaleButton
                  id="review-compressed-back-document-image"
                />
              </TabPane>
              <TabPane key="2" forceRender>
                <div className="mrg-btm-15">
                  <ZoomedImage
                    src={front}
                    alt="Documento frente"
                    height={height}
                    label="Análise"
                    className="mrg-btm-10"
                    showScaleButton
                    setKeyTab={setKeyTabAvaliation}
                    keyTab={keyTabAvaliation}
                    showMenu
                    menuItems={MenuItemsAvaliation}
                    id="review-cropped-front-document-image"
                  />
                </div>
                <ZoomedImage
                  src={back}
                  alt="Documento verso"
                  height={height}
                  label="Análise"
                  showScaleButton
                  id="review-cropped-back-document-image"
                />
              </TabPane>
              <TabPane key="3" forceRender>
                <div className="mrg-btm-15">
                  <ZoomedImage
                    src={originalFront}
                    alt="Documento frente"
                    height={height}
                    label="Análise"
                    className="mrg-btm-10"
                    showScaleButton
                    setKeyTab={setKeyTabAvaliation}
                    keyTab={keyTabAvaliation}
                    showMenu
                    menuItems={MenuItemsAvaliation}
                    id="review-original-front-document-image"
                  />
                </div>
                <ZoomedImage
                  src={originalBack}
                  alt="Documento verso"
                  height={height}
                  label="Análise"
                  showScaleButton
                  id="review-original-back-document-image"
                />
              </TabPane>
            </Tabs>
          </Col>
          <Col span={12}>
            {documentscopy?.rulesValidation?.rule &&
              Object.keys(documentscopy?.rulesValidation?.rule || {})?.length > 0 &&
              (Object.keys(documentscopy?.rulesValidation?.rule?.referenceImages || {})
                ?.length > 0 ||
                documentscopy?.rulesValidation?.otherRules?.length > 0) && (
                <Tabs
                  className="tabs-zoomed-images"
                  onChange={() => setModelId(keyTabReference)}
                  activeKey={keyTabReference}
                >
                  {Object.keys(
                    documentscopy?.rulesValidation?.rule?.referenceImages || {}
                  )?.length > 0 &&
                    referenceTabs(
                      documentscopy?.rulesValidation?.rule,
                      MenuItemsReference,
                      height
                    )}
                  {documentscopy?.rulesValidation?.otherRules?.length > 0 &&
                    documentscopy?.rulesValidation?.otherRules?.map(
                      (otherRule) =>
                        Object.keys(
                          documentscopy?.rulesValidation?.rule?.referenceImages || {}
                        )?.length > 0 &&
                        referenceTabs(otherRule, MenuItemsReference, height)
                    )}
                </Tabs>
              )}
          </Col>
        </Row>
      ) : (
        <img
          src={imageError}
          alt="Homem confuso"
          className="grayscale-95  mrg-top-80 img-error"
        />
      );
    },
    [
      keyTabAvaliation,
      keyTabReference,
      documentscopy,
      recordData,
      setModelId,
      referenceTabs,
    ]
  );

  return (
    <div id="document-confirmation-component">
      <DocumentsHeader recordData={recordData} isReview={isReview} modelId={modelId} />
      <div className="image-tabs">{imageTabs(16)}</div>
    </div>
  );
};

CnhDocumentsConfirmation.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  setModelId: PropTypes.func,
  modelId: PropTypes.string || PropTypes.number,
  isReview: PropTypes.bool,
};

CnhDocumentsConfirmation.defaultProps = {
  setModelId: () => {},
  modelId: null,
  isReview: undefined,
};

export default CnhDocumentsConfirmation;
