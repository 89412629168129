import React from 'react';
import { NavLink } from 'react-router-dom';

import Wrapper from 'pages/private/wrapper';
import { Settings } from '@combateafraude/icons/general';
import { Users } from '@combateafraude/icons/users';

import './styles.less';

const OperationsGroupsWrapper = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    <div className="tabs">
      <NavLink exact to="/operations-groups" activeClassName="active">
        <div className="inline-flex center">
          <Settings width={24} height={24} />
          <span className="mrg-left-5">Grupos de operações</span>
        </div>
      </NavLink>
      <NavLink exact to="/operations-groups/users" activeClassName="active">
        <div className="inline-flex center">
          <Users width={24} height={24} />
          <span className="mrg-left-5">Usuários</span>
        </div>
      </NavLink>
    </div>
    {children}
  </Wrapper>
);

export default OperationsGroupsWrapper;
