import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';

import UserInfo from 'components/UserInfo';

import logo from 'assets/images/logo.svg';
import HorizontalNav from './HorizontalNav';

import './styles.less';

const { Header } = Layout;

const InsideHeader = ({ subheader }) => (
  <div
    id="header-component"
    className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal"
  >
    <Header className="gx-header-horizontal-main header-show">
      <div className="gx-container">
        <div className="gx-header-horizontal-main-flex">
          <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
            <img alt="" src={logo} height="42px" />
          </Link>

          <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
            <HorizontalNav />
          </div>
          <ul className="gx-header-notifications gx-ml-auto">
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </ul>
        </div>
      </div>
    </Header>

    {subheader}
  </div>
);

InsideHeader.propTypes = {
  subheader: PropTypes.node,
};

InsideHeader.defaultProps = {
  subheader: <></>,
};

export default InsideHeader;
