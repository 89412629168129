import React, { useCallback, useMemo } from 'react';
import { Button } from 'antd';
import { AlertTriangle } from '@combateafraude/icons/general';

import PropTypes from 'prop-types';

const Observation = ({ recordData }) => {
  const { type = null, queue = null } = recordData?.record;

  const openrelatedRecordId = useCallback(() => {
    const { relatedRecordId } = recordData.record || {};

    const url = `${process.env.REACT_APP_BASE_URL_WECHECK_WEB}/${type}/records/details/record/${relatedRecordId}`;
    window.open(url, '_blank');
  }, [recordData, type]);

  const observationData = useMemo(
    () => (
      <>
        <div className="flex">
          <p className="card-title text-bold">Motivos anteriores de reprovação</p>
        </div>

        {recordData.record?.documentscopy?.obs?.map((o) => (
          <div key={o} className="card-info">
            <span>· {o}</span>
          </div>
        ))}
      </>
    ),
    [recordData]
  );

  return (
    <>
      {queue?.solutionLevel === 'poc' && (
        <p id="poc-client-message" className="card-title max-lines-2 gx-w-100 text-bold">
          <AlertTriangle className="gx-text-danger" width={24} height={24} />
          Cliente em POC!
        </p>
      )}

      {queue?.solutionLevel === 'qrcode' && (
        <p className="card-title max-lines-2 gx-w-100 text-bold">
          Detectamos um QRCode em uma das imagens!
        </p>
      )}

      {recordData.record?.documentscopy?.obs && queue?.solutionLevel !== 'quality' && (
        <div>{observationData}</div>
      )}
      {!recordData.record?.documentscopy?.obs && queue?.solutionLevel !== 'qrcode' && (
        <p className="card-title max-lines-2 gx-w-100 text-bold">
          Nenhuma observação referente ao documento.
        </p>
      )}

      {recordData.record?.relatedRecordId && queue?.solutionLevel !== 'quality' && (
        <div className="mrg-top-10">
          <Button type="link" className="btn-link" onClick={openrelatedRecordId}>
            Abrir avaliação anterior
          </Button>
        </div>
      )}
    </>
  );
};

Observation.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
};

export default Observation;
