/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Dropdown, Divider, Avatar, Menu, Switch } from 'antd';
import { Button } from '@combateafraude/react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useAuth } from 'hooks/auth';

import PermissionWrapper from 'routes/PermissionWrapper';

import ProfileDefault from 'assets/images/profile-default.png';
import ProfileDefaultDark from 'assets/images/profile-default-dark.png';

import { ArrowDown, Info, Lua, Settings } from '@combateafraude/icons/general';
import useInfoWeCheckModal from './components/InfoModal';

import './styles.less';

const UserInfo = () => {
  const history = useHistory();
  const [isDarkTheme, setDarkTheme] = useState(false);
  const [menuDropdownIsVisible, setMenuDropdownIsVisible] = useState(false);

  const { logOut, loggedUser } = useAuth();

  const { openModal, InfoWeCheckModal } = useInfoWeCheckModal();

  useEffect(() => {
    setDarkTheme(Cookies.get('CAF-dark-theme'));
  }, []);

  useEffect(() => {
    const element = document.body;
    if (isDarkTheme) {
      element.classList.toggle('dark-theme');
      Cookies.set('CAF-dark-theme', 'true');
    } else {
      Cookies.remove('CAF-dark-theme');

      element.classList.remove('dark-theme');
    }
  }, [isDarkTheme]);

  const logout = useCallback(() => {
    logOut();
  }, [logOut]);

  const userProfile = useMemo(
    () => (
      <>
        <div className="user-profile">
          <div>
            <Avatar src={isDarkTheme ? ProfileDefaultDark : ProfileDefault} size={48} />
          </div>
          <section>
            <h3 className="max-lines-1">{loggedUser?.name || loggedUser?.docs?.name}</h3>
            <span className="max-lines-1">
              {loggedUser?.username || loggedUser?.docs?.username}
            </span>
          </section>
        </div>
        <Divider className="no-mrg-btm" />
      </>
    ),
    [loggedUser, isDarkTheme]
  );

  const menu = useMemo(
    () => (
      <div>
        {InfoWeCheckModal}
        {loggedUser && <>{userProfile}</>}

        <div className="gx-flex-column">
          <div className="user-menu">
            <Menu>
              <Menu.Item key="1">
                <div className="flex start-center">
                  <Lua width={24} height={24} className="dark-mode" />
                  <span className="mrg-left-5 dark-mode">Modo Noturno</span>
                </div>
                <Switch checked={isDarkTheme} onChange={setDarkTheme} />
              </Menu.Item>
            </Menu>

            <Menu onClick={() => setMenuDropdownIsVisible(false)}>
              <Menu.Item key="2" onClick={openModal}>
                <div className="flex start-center">
                  <Info width={24} height={24} />
                  <span className="mrg-left-5">Informações</span>
                </div>
              </Menu.Item>
              <PermissionWrapper requiredPermissions={['wecheck_configs:read']}>
                <Menu.Item key="3" onClick={() => history.push('/operations-groups')}>
                  <div className="flex start-center">
                    <Settings width={24} height={24} />
                    <span className="mrg-left-5">Configurações</span>
                  </div>
                </Menu.Item>
              </PermissionWrapper>
            </Menu>

            <Button className="btn-logout btn-custom-primary" onClick={logout}>
              Sair
            </Button>
          </div>
          <Divider className="no-mrg" />
          <section className="privacy-policies-terms-of-use">
            <div>
              <a
                href="https://www.caf.io/politicas/politicas-de-privacidade"
                target="_blank"
                rel="noreferrer"
              >
                Política de privacidade
              </a>
              <a
                href="https://www.caf.io/politicas/termos-e-condicoes-de-uso"
                target="_blank"
                rel="noreferrer"
              >
                Termos e condições de uso
              </a>
            </div>
          </section>
        </div>
      </div>
    ),
    [InfoWeCheckModal, loggedUser, userProfile, isDarkTheme, openModal, logout, history]
  );

  const handleVisibleChange = (flag) => {
    setMenuDropdownIsVisible(flag);
  };

  return (
    <div id="user-info-component">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        overlayClassName="user-dropdown"
        visible={menuDropdownIsVisible}
        onVisibleChange={handleVisibleChange}
      >
        <div className="user-dropdown-trigger">
          <Avatar src={isDarkTheme ? ProfileDefaultDark : ProfileDefault} size={48} />
          <span>{loggedUser.name}</span>
          <ArrowDown width={24} height={24} />
        </div>
      </Dropdown>
    </div>
  );
};

export default UserInfo;
