import React from 'react';
import { Form, Input } from 'antd';
import InputMask from 'components/Form/InputMask';
import PropTypes from 'prop-types';

const TypeRG = ({ isReview }) => (
  <>
    <div className="custom-inputs">
      <div className="field-mandatory">
        <Form.Item name="rg" label="RG" rules={[{ required: true }]}>
          <Input className="custom-input" disabled={isReview} style={{ width: 325 }} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item name="issueDate" label="Data de emissão" rules={[{ required: true }]}>
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item
          name="issuingAuthority"
          label="Órgão emissor"
          rules={[{ required: true }]}
        >
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item name="issueState" label="UF emissora" rules={[{ required: true }]}>
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div>
      <Form.Item name="name" label="Nome completo (opcional)">
        <Input className="custom-input" disabled={isReview} />
      </Form.Item>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="motherName" label="Nome da mãe (opcional)">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="fatherName" label="Nome do pai (opcional)">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div>
      <Form.Item name="referenceDocument" label="Origem do Documento">
        <Input className="custom-input" disabled={isReview} />
      </Form.Item>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="birthPlace" label="Local de nascimento">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="birthDate" label="Data de nascimento">
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="cpf" label="CPF">
          <Input className="custom-input" disabled={isReview} style={{ width: 313 }} />
        </Form.Item>
      </div>
    </div>
  </>
);

TypeRG.propTypes = {
  isReview: PropTypes.bool,
};

TypeRG.defaultProps = {
  isReview: false,
};

export default TypeRG;
