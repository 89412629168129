import React, { useRef, useEffect, useCallback } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';
import { Form, Select } from 'antd';
import { PageTitle } from '@combateafraude/react';

import useTable from 'components/List/hooks/useTable';
import SearchInput from 'components/Form/SearchInput';
import showToastMessage from 'components/ToastMessage';
import SortIcon from 'components/SortIcon';

import { useFetch } from 'services/hooks';

import PermissionWrapper from 'routes/PermissionWrapper';

import { ArrowDown, Trash } from '@combateafraude/icons/general';
import Wrapper from '../wrapper';

import './styles.less';

const UsersList = () => {
  const [query] = useQueryParams({
    search: StringParam,
    operationsGroupFilter: StringParam,
  });
  const refreshListRef = useRef(() => {});

  const { data: operationsGroups, get: getOperationsGroups, loading } = useFetch();
  const { patch: patchUser, loading: loadingUpdate } = useFetch();

  useEffect(() => {
    getOperationsGroups({
      url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/operations-groups`,
      config: { params: {} },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeUserOperationsGroup = useCallback(
    async (username, groupId) => {
      try {
        await patchUser({
          url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/users/${username}`,
          payload: { operationsGroupId: groupId },
        });
        showToastMessage({
          type: 'success',
          text: 'Grupo de operações do usuário alterado com sucesso!',
        });
      } catch (err) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        showToastMessage({
          type: 'error',
          text: 'Falha ao alterar grupo de operações do usuário!',
        });
      }
    },
    [patchUser]
  );

  const findOperationsGroupById = useCallback(
    (id) => {
      const foundGroup = operationsGroups?.docs?.find((group) => group.id === id);
      return foundGroup?.name || null;
    },
    [operationsGroups]
  );

  const columns = [
    {
      key: 'name',
      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(({ column }) => column.key === 'name');
        return (
          <div className="flex row">
            <span className="column-title">Nome</span>
            <SortIcon order={sortedColumn?.order} />
          </div>
        );
      },
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name?.localeCompare(b.name),
      render: (__, record) => (
        <div className="flex align-center text-dark">
          <div className="flex align-center text-dark">{record?.name || '-'}</div>
        </div>
      ),
    },
    {
      key: 'username',
      title: 'E-mail',
      dataIndex: 'username',
      render: (__, record) => (
        <div className="flex align-center text-dark">
          <div className="flex align-center text-dark">{record?.username || '-'}</div>
        </div>
      ),
    },
    {
      key: 'operationsGroupId',
      title: 'Grupo de operações',
      dataIndex: 'operationsGroupId',
      render: (__, record) => (
        <PermissionWrapper
          requiredPermissions={['wecheck_configs:update']}
          forbiddenContent={
            <span
              className="text-dark text-bold inline-flex center"
              style={{ minHeight: '36px' }}
            >
              {findOperationsGroupById(record?.operationsGroupId) || '-'}
            </span>
          }
        >
          <Select
            allowClear
            showSearch
            optionLabelProp="label"
            optionFilterProp="children"
            bordered={false}
            clearIcon={<Trash />}
            suffixIcon={<ArrowDown />}
            placeholder="Selecione um grupo de operações"
            style={{ width: '350px' }}
            className="text-dark text-bold no-pdd operations-group-select"
            defaultValue={
              record?.operationsGroupId
                ? findOperationsGroupById(record?.operationsGroupId) || 'Grupo excluído'
                : null
            }
            onSelect={(e) => changeUserOperationsGroup(record?.username, e)}
            onClear={() => changeUserOperationsGroup(record?.username, null)}
            loading={loading || loadingUpdate}
            disabled={loading || loadingUpdate}
          >
            {operationsGroups?.docs?.map((group) => (
              <Select.Option key={group?.id} value={group?.id} label={group?.name}>
                {group?.name}
              </Select.Option>
            ))}
          </Select>
        </PermissionWrapper>
      ),
    },
  ];

  const { tableContent, refreshList, setFilterData } = useTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/users`,
      config: {},
    },
    queryParams: { search: StringParam, operationsGroupFilter: StringParam },
    columns,
    rowKey: 'username',
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        search: values._search ? values._search.trim() : undefined,
        operationsGroupFilter: values.operationsGroupFilter
          ? values.operationsGroupFilter.trim()
          : undefined,
      });
    }, 500),
    []
  );

  return (
    <Wrapper id="operations-groups-users-list-component">
      <div className="flex">
        <PageTitle
          title="Usuários de Grupos de operações"
          subtitle="Gerencie os grupos de operações dos usuários."
        />
        <div className="flex row end-center flex-1">
          <div className="flex align-center">
            <Form
              layout="horizontal"
              className="flex align-center"
              onValuesChange={handleFormChange}
              initialValues={{
                _search: query?.search,
                operationsGroupFilter: query?.operationsGroupFilter,
              }}
            >
              <Form.Item name="operationsGroupFilter" className="no-mrg-btm">
                <Select
                  showSearch
                  optionLabelProp="label"
                  optionFilterProp="children"
                  bordered={false}
                  clearIcon={<Trash />}
                  suffixIcon={<ArrowDown />}
                  allowClear
                  style={{ width: 250 }}
                  placeholder="Selecione um Grupo de operações"
                  defaultActiveFirstOption={false}
                  className="text-dark mrg-right-35 operations-group-select"
                  loading={loading || loadingUpdate}
                  disabled={loading || loadingUpdate}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {operationsGroups?.docs?.map((group) => (
                    <Select.Option key={group?.id} value={group?.id} label={group?.name}>
                      {group?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <SearchInput
                name="_search"
                placeholder="Busque por e-mail..."
                iconSize="sm"
                className="flex center"
              />
            </Form>
          </div>
        </div>
      </div>
      <div id="operations-groups-users-list-table" className="gx-w-100 mrg-top-10">
        {tableContent}
      </div>
    </Wrapper>
  );
};

export default UsersList;
