/* eslint-disable no-restricted-syntax */
import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Radio, Form, Carousel } from 'antd';
import PropTypes from 'prop-types';

import ZoomedImage from 'components/Image/ZoomedImage';
import RadioButtonOptions from './RadioButtonOptions/index';

const DirectorSignature = ({
  recordData,
  formRef,
  modelId,
  setDirectorSignatureData,
  isReview,
  allRulesCorrect,
}) => {
  const [rule, setRule] = useState('');
  const { rulesValidation } = recordData?.record?.documentscopy;
  const { documentscopy } = recordData?.record;

  const [directorSignatureValue, setDirectorSignatureValue] = useState(undefined);

  useEffect(() => {
    if (allRulesCorrect.directorSignature) {
      formRef.setFieldsValue({
        delegateSignatureValue: 'correct',
      });

      setDirectorSignatureData({
        selected: 'correct',
      });
    }
  }, [allRulesCorrect, formRef, setDirectorSignatureData]);

  useEffect(() => {
    for (const [key, value] of Object.entries(
      rulesValidation?.rule?.signatureImages || {}
    )) {
      const signature = value?.['back-signature'] || value?.['front-signature'];
      if (key === modelId && signature) {
        setDirectorSignatureValue('correct');
        formRef.setFieldsValue({
          directorSignatureValue: 'correct',
        });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const directorSignatureImages = useMemo(() => {
    const signaturesList = [];
    for (const [key, value] of Object.entries(rule?.signatureImages || {})) {
      const signature = value?.['back-signature'] || value?.['front-signature'];
      if (key?.charAt(0) === modelId?.charAt(0) && signature) {
        signaturesList.push(
          <div className="custom-carousel" key={key}>
            <ZoomedImage
              key={key}
              src={signature}
              height={10}
              label="Referência"
              minHeight={25}
              zoomPixels={25}
              checkHover
            />
          </div>
        );
      }
    }

    if (signaturesList?.length > 0) {
      return signaturesList;
    }

    return (
      <div className="custom-carousel">
        <ZoomedImage
          src={undefined}
          height={10}
          label="Referência"
          minHeight={25}
          zoomPixels={25}
          checkHover
        />
      </div>
    );
  }, [rule?.signatureImages, modelId]);

  useEffect(() => {
    if (
      isReview &&
      documentscopy?.review &&
      !!Object.keys(documentscopy?.review?.additionalValidations?.directorSignature || {})
        .length
    ) {
      const { directorSignature } = documentscopy?.review?.additionalValidations;
      formRef?.setFieldsValue({
        directorSignatureValue: directorSignature.selected,
      });
    }
  }, [formRef, isReview, documentscopy]);

  useEffect(() => {
    if (rulesValidation?.rule?.model === modelId?.charAt(0)) {
      setRule(rulesValidation?.rule);
    } else {
      setRule(
        rulesValidation?.otherRules?.find((r) => {
          for (const [key, value] of Object.entries(r?.signatureImages || {})) {
            if (key?.charAt(0) === modelId?.charAt(0)) {
              return value;
            }
          }

          return undefined;
        })
      );
    }
  }, [modelId, rulesValidation]);

  useEffect(() => {
    setDirectorSignatureData({
      selected: directorSignatureValue?.target?.value || directorSignatureValue,
    });
  }, [directorSignatureValue, setDirectorSignatureData]);

  return (
    <div id="signature-component">
      {rule?.model && (
        <Row>
          <span className="model">{`Modelo Escolhido: ${rule?.model || ''}`}</span>
        </Row>
      )}
      <Row className="flex fd-row space-between">
        <Col span={24}>
          <Carousel>{directorSignatureImages}</Carousel>
        </Col>
      </Row>
      <Form
        form={formRef}
        initialValues={{
          directorSignatureValue,
        }}
      >
        <Row align="middle" justify="end">
          <Form.Item name="directorSignatureValue" rules={[{ required: true }]}>
            <Radio.Group
              optionType="button"
              onChange={setDirectorSignatureValue}
              disabled={isReview}
            >
              <RadioButtonOptions />
            </Radio.Group>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

DirectorSignature.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  modelId: PropTypes.string,
  setDirectorSignatureData: PropTypes.func,
  isReview: PropTypes.bool,
  allRulesCorrect: PropTypes.objectOf(),
};

DirectorSignature.defaultProps = {
  modelId: null,
  setDirectorSignatureData: () => {},
  isReview: false,
  allRulesCorrect: {},
};

export default DirectorSignature;
