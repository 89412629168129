import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { Checkmark, CircleFalse } from '@combateafraude/icons/general';

const { Option } = Select;

const SelectCustomFilter = ({
  mode,
  value,
  placeholder,
  setFilter,
  options,
  ...rest
}) => (
  <Select
    mode={mode}
    labelInValue
    value={value}
    placeholder={placeholder}
    optionLabelProp="label"
    dropdownClassName="multiselect-filters"
    filterOption={false}
    listHeight={320}
    style={{ width: 291 }}
    dropdownAlign={{ offset: [0, 2] }}
    removeIcon={<CircleFalse />}
    menuItemSelectedIcon={<Checkmark width={20} height={20} />}
    onChange={(values) => {
      setFilter(values);
    }}
    {...rest}
  >
    {options?.map((o) => (
      <Option key={o.key} label={o.label}>
        {o.label}
      </Option>
    ))}
  </Select>
);

SelectCustomFilter.propTypes = {
  mode: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.objectOf),
  placeholder: PropTypes.string,
  setFilter: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.objectOf),
};

SelectCustomFilter.defaultProps = {
  mode: null,
  value: [],
  placeholder: null,
  setFilter: null,
  options: [],
};

export default SelectCustomFilter;
