import React from 'react';
import { ArrowUp, ArrowDown } from '@combateafraude/icons/general';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.less';

const SortIcon = ({ order }) => (
  <div id="sort-icon-component" className="flex-column center">
    <ArrowUp
      className={`${classNames({
        'sort-enabled': order === 'ascend',
      })}`}
    />
    <ArrowDown
      className={`${classNames({
        'sort-enabled': order === 'descend',
      })}`}
    />
  </div>
);

SortIcon.propTypes = {
  order: PropTypes.string,
};

SortIcon.defaultProps = {
  order: undefined,
};

export default SortIcon;
