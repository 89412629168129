import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal as StandardModal } from 'antd';
import alertImg from 'assets/images/alert-img.svg';

import { useFetch } from 'services/hooks';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import Button from 'components/Button';
import showToastMessage from 'components/ToastMessage';

import './styles.less';

const IncorrectAnalysisButton = ({ loggedUser, record, isApproved }) => {
  const { post } = useFetch();
  const {
    _id: recordId = null,
    postEvaluationReview = null,
    executionInfo = null,
  } = record;
  const { tenantId = null, executionId = null } = executionInfo;

  const hasPermissionToIncorrectAnalysis = hasRequiredPermissions(
    loggedUser,
    ['wecheck_incorrectAnalysis:create'],
    []
  );
  const alreadyMarked = postEvaluationReview?.incorrectAnalysis?.isTrue;
  const isDisabled = !hasPermissionToIncorrectAnalysis || alreadyMarked;

  const confirmModal = useCallback(async () => {
    StandardModal.confirm({
      className: 'confirm-incorrect-analysis-modal',
      img: alertImg,
      title: 'Você confirma que deseja marcar essa análise como incorreta?',
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      width: 314,
      height: 171,
      onOk: async () => {
        try {
          await post({
            url: `/post-evaluation-review/incorrect-analysis`,
            payload: {
              tenantId,
              executionId,
              recordId,
              type: isApproved ? 'falsePositive' : 'falseNegative',
            },
          });

          window.location.reload();
        } catch (err) {
          const errMessage = err?.response?.data?.message;
          showToastMessage({
            type: 'error',
            text:
              errMessage === 'Already marked incorrect analysis on this record'
                ? 'Análise já marcada como incorreta nesse registro'
                : 'Falha ao marcar análise como incorreta.',
          });
        }
      },
    });
  }, [post, tenantId, executionId, recordId, isApproved]);

  return (
    <>
      <Button
        title={
          isDisabled &&
          (hasPermissionToIncorrectAnalysis
            ? 'Análise já marcada como incorreta nesse registro'
            : 'Sem permissão para marcar análise como incorreta')
        }
        disabled={isDisabled}
        type="primary"
        onClick={confirmModal}
      >
        Marcar como incorreta
      </Button>
    </>
  );
};

IncorrectAnalysisButton.propTypes = {
  loggedUser: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  record: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isApproved: PropTypes.bool.isRequired,
};

export default IncorrectAnalysisButton;
