/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useCallback } from 'react';
import { Collapse } from 'antd';
import {
  CheckCircle2,
  ProcessingLine,
  WrongCircle,
  Info,
  ArrowDown,
  ArrowRight,
} from '@combateafraude/icons/general';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Observation from './components/Observation';
import ValidationIssueYearUF from './components/ValidationIssueYearUF';
import DirectorSignature from './components/DirectorSignature';
import AdditionalInformation from './components/AdditionalInformation';
import ReproveDocument from './components/ReproveDocument';
import AvaliationInfo from './components/AvaliationInfo';

import '../styles.less';
import DisplayRules from './components/DisplayRules';

const { Panel } = Collapse;

const AvaliationCNHType = ({
  recordData,
  isReview,
  modelId,
  formRef,
  ufYearChanged,
  setUfYearChanged,
  setDirectorSignatureData,
  reproveChanged,
  setReproveChanged,
  setLayoutBackData,
  setAvaliationFinished,
  redirectUrl,
  allRulesCorrect,
  setAllRulesCorrect,
}) => {
  const { hash } = useLocation();
  const {
    avaliationInfo = null,
    documentscopy = null,
    relatedRecordId = null,
    queue = null,
    settings = null,
    executionInfo = null,
  } = recordData?.record;

  const { graphic = null, renach = null } =
    documentscopy?.rulesValidation?.textValidations || {};

  const hasSignatureOnRule = useCallback(() => {
    for (const [key, value] of Object.entries(
      documentscopy?.rulesValidation?.rule?.signatureImages || {}
    )) {
      const signature = value?.['back-signature'] || value?.['front-signature'];
      if (key === modelId && signature) {
        return true;
      }
    }

    return false;
  }, [documentscopy, modelId]);

  const defaultValuesCollapse = useMemo(() => {
    const values = [];

    if (
      (documentscopy?.obs && queue?.solutionLevel !== 'quality') ||
      (relatedRecordId && queue?.solutionLevel !== 'quality') ||
      queue?.solutionLevel === 'poc' ||
      queue?.solutionLevel === 'qrcode'
    ) {
      values.push('obs');
    }

    if (isReview) {
      values.push('avaliation-info');

      if (documentscopy?.review?.additionalValidations?.layoutBack) {
        Object.values(documentscopy?.review?.additionalValidations?.layoutBack).map(
          (r) => {
            if (r.selected === 'unreadable' || r.selected === 'incorrect') {
              return values.push('layoutBack');
            }
            return '';
          }
        );
      }

      if (
        documentscopy?.review?.additionalValidations?.directorSignature?.selected ===
          'unreadable' ||
        documentscopy?.review?.additionalValidations?.directorSignature?.selected ===
          'incorrect'
      ) {
        values.push('directorSignature');
      }
    } else {
      values.push('reprove');

      if (settings?.evaluationRulesEnabled) {
        values.push('evaluationRules');
      }

      if (modelId && !hasSignatureOnRule()) {
        values.push('directorSignature');
      } else {
        setAllRulesCorrect((prevState) => ({
          ...prevState,
          directorSignature: true,
        }));
      }

      if (!graphic || !renach) {
        values.push('layoutBack');
      } else {
        setAllRulesCorrect((prevState) => ({
          ...prevState,
          layoutBack: true,
        }));
      }
    }
    return values;
  }, [
    isReview,
    documentscopy,
    relatedRecordId,
    queue,
    graphic,
    renach,
    hasSignatureOnRule,
    modelId,
    setAllRulesCorrect,
    settings,
  ]);

  return (
    <Collapse
      className="mrg-left-10"
      defaultActiveKey={defaultValuesCollapse}
      ghost
      expandIcon={({ isActive }) =>
        isActive ? (
          <ArrowDown width={24} height={24} />
        ) : (
          <ArrowRight width={24} height={24} />
        )
      }
    >
      {isReview && (
        <Panel
          header="Dados referente a avaliação"
          key="avaliation-info"
          className={`${classNames({
            'primary-border': avaliationInfo?.evaluated && documentscopy?.approved,
            'danger-border': avaliationInfo?.evaluated && !documentscopy?.approved,
            'success-border': avaliationInfo?.evaluated && documentscopy?.reprocess,
          })} mrg-btm-20`}
          extra={
            avaliationInfo?.evaluated && documentscopy?.approved !== null ? (
              documentscopy?.approved ? (
                <CheckCircle2 className="gx-text-primary" width={24} height={24} />
              ) : (
                <WrongCircle className="gx-text-danger" width={24} height={24} />
              )
            ) : (
              documentscopy?.reprocess && (
                <ProcessingLine width={24} height={24} className="gx-text-success" />
              )
            )
          }
        >
          <AvaliationInfo recordData={recordData} />
        </Panel>
      )}

      {settings?.evaluationRulesEnabled && (
        <Panel
          header="Regras específicas de cliente"
          key="evaluationRules"
          className={`${classNames({
            'warning-border': defaultValuesCollapse.includes('evaluationRules'),
          })} mrg-btm-20`}
        >
          <DisplayRules
            approveRules={settings.approveEvaluationRules}
            reproveRules={settings.reproveEvaluationRules}
            fantasyName={executionInfo?.fantasyName}
          />
        </Panel>
      )}
      <Panel
        header="Observações sobre o documento"
        key="obs"
        className={`${classNames({
          'warning-border': defaultValuesCollapse.includes('obs'),
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('obs') && (
            <Info width={24} height={24} className="gx-text-warning" />
          )
        }
      >
        <Observation recordData={recordData} />
      </Panel>
      <Panel
        header="Validação de Ano e UF de emissão"
        key="validation-year-uf"
        className="mrg-btm-20 primary-border"
        extra={
          documentscopy?.reprocess ? (
            <ProcessingLine width={24} height={24} className="gx-text-success" />
          ) : (
            <CheckCircle2 className="gx-text-primary" width={24} height={24} />
          )
        }
      >
        <ValidationIssueYearUF
          recordData={recordData}
          isReview={isReview}
          ufYearChanged={ufYearChanged}
          setUfYearChanged={setUfYearChanged}
          setAvaliationFinished={setAvaliationFinished}
        />
      </Panel>

      {Object.keys(documentscopy?.rulesValidation?.rule || {}).length > 0 && (
        <Panel
          header="Assinatura"
          key="directorSignature"
          className={`${classNames({
            'danger-border':
              isReview && defaultValuesCollapse.includes('directorSignature'),
            'primary-border':
              !defaultValuesCollapse.includes('directorSignature') ||
              allRulesCorrect?.directorSignature,
            'warning-border':
              !isReview &&
              defaultValuesCollapse.includes('directorSignature') &&
              !allRulesCorrect?.directorSignature,
          })} mrg-btm-20`}
          extra={
            defaultValuesCollapse.includes('directorSignature') &&
            !allRulesCorrect?.directorSignature ? (
              isReview ? (
                <WrongCircle className="gx-text-danger" width={24} height={24} />
              ) : (
                <Info width={24} height={24} className="gx-text-warning" />
              )
            ) : (
              <CheckCircle2 className="gx-text-primary" width={24} height={24} />
            )
          }
        >
          <DirectorSignature
            recordData={recordData}
            formRef={formRef}
            modelId={modelId}
            setDirectorSignatureData={setDirectorSignatureData}
            isReview={isReview}
          />
        </Panel>
      )}

      {Object.keys(documentscopy?.rulesValidation?.rule || {}).length > 0 && (
        <Panel
          header="Dados Adicionais"
          key="layoutBack"
          className={`${classNames({
            'danger-border': isReview && defaultValuesCollapse.includes('layoutBack'),
            'primary-border':
              !defaultValuesCollapse.includes('layoutBack') || allRulesCorrect.layoutBack,
            'warning-border':
              !isReview &&
              defaultValuesCollapse.includes('layoutBack') &&
              !allRulesCorrect.layoutBack,
          })} mrg-btm-20`}
          extra={
            defaultValuesCollapse.includes('layoutBack') &&
            !allRulesCorrect.layoutBack ? (
              isReview ? (
                <WrongCircle className="gx-text-danger" width={24} height={24} />
              ) : (
                <Info width={24} height={24} className="gx-text-warning" />
              )
            ) : (
              <CheckCircle2 className="gx-text-primary" width={24} height={24} />
            )
          }
        >
          <AdditionalInformation
            recordData={recordData}
            formRef={formRef}
            modelId={modelId}
            setLayoutBackData={setLayoutBackData}
            isReview={isReview}
            allRulesCorrect={allRulesCorrect}
            setAllRulesCorrect={setAllRulesCorrect}
          />
        </Panel>
      )}

      {!isReview && (
        <Panel
          id="reprove-component"
          header="Reprovar o documento"
          key="reprove"
          className={`${classNames({
            'danger-border': hash === '#reprove-component',
          })} mrg-btm-20`}
        >
          <ReproveDocument
            recordData={recordData}
            isReview={isReview}
            reproveChanged={reproveChanged}
            setReproveChanged={setReproveChanged}
            setAvaliationFinished={setAvaliationFinished}
            redirectUrl={redirectUrl}
          />
        </Panel>
      )}
    </Collapse>
  );
};

AvaliationCNHType.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  modelId: PropTypes.string,
  ufYearChanged: PropTypes.bool,
  setUfYearChanged: PropTypes.func,
  setDirectorSignatureData: PropTypes.func,
  setReproveChanged: PropTypes.func,
  reproveChanged: PropTypes.bool,
  setLayoutBackData: PropTypes.func,
  setAvaliationFinished: PropTypes.func,
  redirectUrl: PropTypes.string,
  allRulesCorrect: PropTypes.objectOf(),
  setAllRulesCorrect: PropTypes.func,
};

AvaliationCNHType.defaultProps = {
  isReview: false,
  modelId: null,
  ufYearChanged: false,
  setUfYearChanged: () => {},
  reproveChanged: false,
  setDirectorSignatureData: () => {},
  setReproveChanged: () => {},
  setLayoutBackData: () => {},
  setAvaliationFinished: () => {},
  redirectUrl: '/manual-review-wizard',
  allRulesCorrect: {},
  setAllRulesCorrect: () => {},
};

export default AvaliationCNHType;
