import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form, Input, Modal as ModalAntd } from 'antd';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useCountdown } from 'hooks/countdown';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import alertImg from 'assets/images/alert-img.svg';

import Button from 'components/Button';
import InputMask from 'components/Form/InputMask';
import showToastMessage from 'components/ToastMessage';

import './styles.less';

const ValidationIssueYearUF = ({
  recordData,
  isReview,
  setUfYearChanged,
  ufYearChanged,
  setAvaliationFinished,
  redirectUrl,
}) => {
  const history = useHistory();

  const { put } = useFetch();
  const { loggedUser } = useAuth();
  const { time } = useCountdown();

  const { record } = recordData;
  const { documentscopy } = recordData?.record;
  const { ocr } = record?.executionInfo?.sections;
  const [form] = Form.useForm();

  const [issueState, setIssueState] = useState(ocr?.issueState || ocr?.cnhIssueState);
  const [issueDate, setIssueDate] = useState(ocr?.issueDate);

  const isRg = ocr?.issueState;

  const { openModal: openModalInfo, Modal: ModalInfo } = Modal({
    img: alertImg,
    title: 'Modifique os dados antes de clicar em confimar.',
    okText: 'Ok',
    okType: 'primary',
  });

  useEffect(() => {
    if (
      (isRg && ocr?.issueState !== issueState) ||
      (!isRg && ocr?.cnhIssueState !== issueState) ||
      ocr?.issueDate !== issueDate
    ) {
      setUfYearChanged(true);
    } else {
      setUfYearChanged(false);
    }
  }, [isRg, issueDate, issueState, ocr, record, setUfYearChanged]);

  useEffect(() => {
    if (isReview && documentscopy?.review?.issueState) {
      form.setFieldsValue({
        issueState: documentscopy?.review?.issueState,
      });
    }

    if (isReview && documentscopy?.review?.issueDate) {
      form.setFieldsValue({
        issueDate: documentscopy?.review?.issueDate,
      });
    }
  }, [form, isReview, documentscopy]);

  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    if (ufYearChanged) {
      ModalAntd.confirm({
        title: 'Você realmente deseja enviar os dados de Ano e UF de emissão ',
        okText: 'Sim, Enviar',
        okType: 'pimary',
        cancelText: 'Não',
        onOk: async () => {
          const payload = {
            avaliationInfo: {
              evaluated: true,
              user: {
                _id: loggedUser._id,
                name: loggedUser.name,
                username: loggedUser.username,
              },
              time,
              reviewAt: new Date(),
            },
            documentscopy: {
              doubleCheck: record?.documentscopy?.doubleCheck,
              reprocess: true,
              fraud: false,
              unreadable: false,
              review: {
                issueDate,
                issueState: issueState.toUpperCase(),
              },
            },
            type: record.type,
          };
          try {
            await put({
              url: `/records/${record._id}`,
              payload,
            });

            setAvaliationFinished(true);

            history.push(redirectUrl);
            window.location.reload();
          } catch (e) {
            showToastMessage({
              type: 'error',
              text: 'Erro ao registrar informações.',
            });
            setAvaliationFinished(true);
            history.replace('/home');
          }
        },
      });
    } else {
      openModalInfo();
    }
  }, [
    form,
    ufYearChanged,
    loggedUser,
    time,
    record,
    issueDate,
    issueState,
    put,
    setAvaliationFinished,
    history,
    redirectUrl,
    openModalInfo,
  ]);

  return (
    <div id="validation-issue-year-uf-component">
      {ModalInfo}
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          issueDate,
          issueState,
        }}
      >
        <Row align="middle" justify="center">
          <Col>
            <Form.Item
              name="issueDate"
              label="Data de emissão"
              rules={[{ required: true }]}
            >
              <InputMask
                mask="99/99/9999"
                className="text-dark issue-date ant-input-text"
                disabled={isReview}
                onChange={(e) => setIssueDate(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col className="mrg-left-15">
            <Form.Item name="issueState" label="UF" rules={[{ required: true }]}>
              <Input
                className="text-dark uppercase-input issue-state ant-input-text"
                disabled={isReview}
                onChange={(e) => setIssueState(e.target.value)}
              />
            </Form.Item>
          </Col>
          {!isReview && (
            <Col className="mrg-left-15">
              <Button className="btn-custom-secondary" onClick={handleSubmit}>
                Alterar
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

ValidationIssueYearUF.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  setUfYearChanged: PropTypes.func,
  ufYearChanged: PropTypes.bool,
  setAvaliationFinished: PropTypes.func,
  redirectUrl: PropTypes.string,
};

ValidationIssueYearUF.defaultProps = {
  isReview: false,
  redirectUrl: '/manual-review-wizard',
  setUfYearChanged: () => {},
  ufYearChanged: () => {},
  setAvaliationFinished: () => {},
};

export default ValidationIssueYearUF;
