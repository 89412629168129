/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState } from 'react';
import { Col, Row, Form } from 'antd';
import { useParams, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { ArrowLeft, ArrowRight } from '@combateafraude/icons/general';

import Loader from 'components/Loader';
import showToastMessage from 'components/ToastMessage';
import Button from 'components/Button';

import { useRecord } from 'hooks/record';
import Wrapper from '../wrapper';

import DocumentsConfirmation from '../pages/components/DocumentsConfirmation';
import CnhDocumentsConfirmation from '../pages/components/CnhDocumentsConfirmation';
import UFYearIsEmpty from '../pages/UFYearIsEmpty';
import AvaliationRGType from '../pages/AvaliationRGType';
import AvaliationInternationalType from '../pages/AvaliationInternationalType';
import AvaliationCNHType from '../pages/AvaliationCNHType';
import AvaliationOthersType from '../pages/AvaliationOthersType';
import AvaliationWithoutImage from '../pages/AvaliationError';
import AvaliationPendingOcr from '../pages/AvaliationPendingOCR';
import InternationalDocumentsConfirmation from '../pages/components/InternationalDocumentsConfirmation';

import './styles.less';

const RecordDetailsReview = () => {
  const [form] = Form.useForm();

  const { recordId } = useParams();

  const [modelId, setModelId] = useState();
  const [selectedGlobalDocKey, setSelectedGlobalDocKey] = useState('front');
  const [keyTabAvaliation, setKeyTabAvaliation] = useState('front');
  const [selectedSpecimenDocIndex, setSelectedSpecimenDocIndex] = useState(0);
  const [keyTabSpecimen, setKeyTabSpecimen] = useState('0');

  const { recordData, loadingRecord, loadData, error, clearRecord } = useRecord();

  const changeSelectedDoc = (newKey) => {
    setSelectedGlobalDocKey(newKey);
    setKeyTabAvaliation(newKey);
    setSelectedSpecimenDocIndex(0);
    setKeyTabSpecimen('0');
  };

  const avaliationPage = useMemo(() => {
    if (recordData) {
      const { type = null, queue = null } = recordData?.record;
      const {
        front = null,
        back = null,
        originalFront = null,
        originalBack = null,
        originalFrontCompressed = null,
        originalBackCompressed = null,
      } = recordData?.record?.executionInfo?.images;
      const containsImage =
        originalFrontCompressed ||
        originalBackCompressed ||
        front ||
        back ||
        originalFront ||
        originalBack;

      const containsGlobalImage = front || back;
      const globalImagesKeys =
        containsGlobalImage &&
        Object.keys(recordData?.record?.executionInfo?.images).filter(
          (key) =>
            (key === 'front' || key === 'back') &&
            recordData?.record?.executionInfo.images[key]
        );

      return (
        <Wrapper id="record-details-component">
          <Row>
            <Col
              className={`${classNames({
                // eslint-disable-next-line prettier/prettier
                documentscopy: recordData?.record?.type === 'documentscopy',
                'pending-ocr': recordData?.record?.type === 'pending-ocr',
              })} record-details-cards `}
            >
              <div className="body-record-details body-record-details-review">
                {!containsImage && (
                  <AvaliationWithoutImage recordData={recordData} isReview />
                )}
                {type === 'documentscopy' &&
                  queue?.solutionType === 'uf_year_is_empty' &&
                  containsImage && <UFYearIsEmpty recordData={recordData} isReview />}

                {type === 'documentscopy' &&
                  queue?.solutionType === 'rg' &&
                  containsImage && (
                    <AvaliationRGType
                      recordData={recordData}
                      modelId={modelId}
                      isReview
                      formRef={form}
                    />
                  )}
                {type === 'documentscopy' &&
                  queue?.solutionType === 'cnh' &&
                  containsImage && (
                    <AvaliationCNHType
                      recordData={recordData}
                      modelId={modelId}
                      formRef={form}
                      isReview
                    />
                  )}
                {type === 'documentscopy' &&
                  queue?.solutionType === 'international' &&
                  containsGlobalImage && (
                    <AvaliationInternationalType
                      recordData={recordData}
                      isReview
                      formRef={form}
                      selectedDocKey={selectedGlobalDocKey}
                      selectedSpecimenDocIndex={selectedSpecimenDocIndex}
                    />
                  )}
                {type === 'documentscopy' &&
                  queue?.solutionType === 'others' &&
                  containsImage && (
                    <AvaliationOthersType recordData={recordData} isReview />
                  )}
                {type === 'pending-ocr' && (
                  <AvaliationPendingOcr recordData={recordData} isReview formRef={form} />
                )}
              </div>

              {type === 'documentscopy' &&
                queue?.solutionLevel === 'international' &&
                globalImagesKeys?.length > 1 &&
                containsGlobalImage && (
                  <div className="footer-record-details">
                    <div>
                      <div className="footer-buttons">
                        <Button
                          onClick={() => {
                            changeSelectedDoc('front');
                          }}
                          className="btn-icon"
                          disabled={selectedGlobalDocKey === 'front'}
                        >
                          <ArrowLeft />
                        </Button>
                        <Button
                          onClick={() => {
                            changeSelectedDoc('back');
                          }}
                          className="btn-icon"
                          disabled={selectedGlobalDocKey === 'back'}
                        >
                          <ArrowRight />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
            </Col>
            <Col>
              {queue?.solutionLevel === 'international' ? (
                <InternationalDocumentsConfirmation
                  recordData={recordData}
                  selectedDocKey={selectedGlobalDocKey}
                  setSelectedDocKey={setSelectedGlobalDocKey}
                  keyTabAvaliation={keyTabAvaliation}
                  setKeyTabAvaliation={setKeyTabAvaliation}
                  selectedSpecimenDocIndex={selectedSpecimenDocIndex}
                  setSelectedSpecimenDocIndex={setSelectedSpecimenDocIndex}
                  keyTabSpecimen={keyTabSpecimen}
                  setKeyTabSpecimen={setKeyTabSpecimen}
                  isReview
                />
              ) : type === 'documentscopy' && queue?.solutionType === 'cnh' ? (
                <CnhDocumentsConfirmation
                  recordData={recordData}
                  setModelId={setModelId}
                  modelId={modelId}
                  isReview
                />
              ) : (
                <DocumentsConfirmation
                  recordData={recordData}
                  setModelId={setModelId}
                  modelId={modelId}
                  isReview
                />
              )}
            </Col>
          </Row>
        </Wrapper>
      );
    }

    return <span>Ops, ocorreu algum erro neste documento!</span>;
  }, [
    form,
    modelId,
    recordData,
    selectedGlobalDocKey,
    selectedSpecimenDocIndex,
    keyTabAvaliation,
    keyTabSpecimen,
  ]);

  useEffect(() => {
    const idData = recordId;
    loadData({ recordId: idData, clearData: true });
  }, [loadData, recordId]);

  const hasRecordError = useMemo(() => {
    const formattedErrorStatusCode = error?.statusCode;

    return ['500', '400'].includes(formattedErrorStatusCode);
  }, [error]);

  useEffect(() => {
    if (hasRecordError) {
      showToastMessage({
        type: 'error',
        text: `${error.data.message}`,
      });
    }
  }, [clearRecord, error, hasRecordError]);

  return (
    <div id="record-details-component">
      {loadingRecord && !recordData ? (
        <div id="page-loading">
          <Loader size="large" />
        </div>
      ) : (
        <>
          {hasRecordError ? (
            <>
              <Redirect to="/home" />
              {clearRecord({ shouldClearError: true })}
            </>
          ) : (
            <>
              {recordData && (
                <div className="gx-flex-row">
                  <Form form={form} autoComplete="off">
                    {avaliationPage}
                  </Form>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RecordDetailsReview;
