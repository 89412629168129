import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { PageTitle } from '@combateafraude/react';
import { Typography } from 'antd';

import Button from 'components/Button';
import { toFormatDate } from 'utils/formatters';

import './styles.less';

const { Text } = Typography;

const QueueSubheader = ({ refreshList, loadingTable, setLoadingTable }) => {
  const refreshListRef = useRef(() => {});
  const [listUpdatedAt, setListUpdatedAt] = useState(null);

  useEffect(() => {
    refreshListRef.current = refreshList;
    setListUpdatedAt(toFormatDate(new Date()));
  }, [refreshList]);

  const handleUpdateList = useCallback(async () => {
    try {
      setLoadingTable(true);

      refreshList();
      setListUpdatedAt(toFormatDate(new Date()));
    } catch (err) {
      setLoadingTable(false);
    }
  }, [refreshList, setListUpdatedAt, setLoadingTable]);

  return (
    <div id="queue-subheader-component" className="flex gx-w-100 space-between">
      <PageTitle
        title="Visualização da fila"
        subtitle="Visualize os registros da fila no WeCheck."
      />
      <div className="flex row align-center between-center queue-query">
        <Text className="last-update">
          {listUpdatedAt && !loadingTable
            ? `Última atualização em: ${listUpdatedAt}`
            : 'Atualizando...'}
        </Text>
        <Button
          onClick={handleUpdateList}
          loading={loadingTable}
          disabled={loadingTable}
          className="btn-custom-secondary btn-refresh"
        >
          Atualizar
        </Button>
      </div>
    </div>
  );
};

QueueSubheader.propTypes = {
  refreshList: PropTypes.func.isRequired,
  loadingTable: PropTypes.bool.isRequired,
  setLoadingTable: PropTypes.func.isRequired,
};

export default QueueSubheader;
