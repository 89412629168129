import React from 'react';
import { Radio, Tooltip } from 'antd';
import { CheckD, Close, Hidden } from '@combateafraude/icons/general';

const RadioButtonOptions = () => (
  <>
    <Tooltip title="Correto">
      <Radio.Button value="correct" className="correct">
        <CheckD width={24} height={24} />
      </Radio.Button>
    </Tooltip>

    <Tooltip title="Ilegível">
      <Radio.Button value="unreadable" className="unreadable">
        <Hidden width={24} height={24} />
      </Radio.Button>
    </Tooltip>
    <Tooltip title="Incorreto">
      <Radio.Button value="incorrect" className="incorrect">
        <Close width={24} height={24} />
      </Radio.Button>
    </Tooltip>
  </>
);

export default RadioButtonOptions;
