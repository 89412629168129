import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './styles.less';

const FloatingActionButton = ({ className, children, ...rest }) => (
  <Button className={`floating-action-button-component ${className}`} {...rest}>
    {children}
  </Button>
);

FloatingActionButton.propTypes = {
  className: PropTypes.string,
};

FloatingActionButton.defaultProps = {
  className: '',
};

export default FloatingActionButton;
