import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ArrowDown } from '@combateafraude/icons/general';

import { Form, Select, Typography } from 'antd';

import TypeRG from './TypeRG';
import TypeCNH from './TypeCNH';
import TypeRNE from './TypeRNE';
import TypeCRLV from './TypeCRLV';
import TypeOthers from './TypeOthers';
import TypeRNM from './TypeRNM';

import './styles.less';

const { Option } = Select;
const { Paragraph } = Typography;

const OCRDetails = ({ recordData, isReview, formRef }) => {
  const { record } = recordData;

  const [customFormType, setCustomFormType] = useState(
    record?.executionInfo?.type?.toUpperCase()
  );

  useEffect(() => {
    if (!recordData) return;

    let ocr;

    if (recordData.record?.pendingOcr?.data) {
      const { data } = recordData.record?.pendingOcr || {};

      ocr = data || {};
    } else {
      const { sections } = recordData.record?.executionInfo || {};
      ocr = sections.ocr || {};
    }

    formRef?.setFieldsValue({ ...ocr });
  }, [formRef, recordData]);

  return (
    <div id="ocr-details-component" className="flex-column flex-1">
      <p className="text-bold text-dark font-size-16">
        Avalie as imagens e confirme os dados lidos pelo OCR.
      </p>
      <div>
        <Form
          form={formRef}
          layout="vertical"
          autoComplete="off"
          className="uppercase-form"
        >
          {recordData && (
            <>
              <div className="flex start-center no-mrg-horizon mrg-btm-25 flex fd-row space-between">
                <Paragraph className="no-mrg-btm mrg-right-10 text-dark text-semibold">
                  Tipo de formulário:
                </Paragraph>
                <Select
                  placeholder="Selecione"
                  onChange={setCustomFormType}
                  value={customFormType}
                  style={{ width: 90 }}
                  suffixIcon={<ArrowDown width={24} height={24} />}
                >
                  <Option value="RG">RG</Option>
                  <Option value="CNH">CNH</Option>
                  <Option value="RNE">RNE</Option>
                  <Option value="CRLV">CRLV</Option>
                  <Option value="RNM">RNM</Option>
                  <Option value="OUTROS">Outros</Option>
                </Select>
              </div>

              {customFormType === 'RG' && <TypeRG isReview={isReview} />}
              {customFormType === 'CNH' && <TypeCNH isReview={isReview} />}
              {customFormType === 'RNE' && <TypeRNE isReview={isReview} />}
              {customFormType === 'CRLV' && <TypeCRLV isReview={isReview} />}
              {customFormType === 'RNM' && <TypeRNM isReview={isReview} />}
              {(customFormType === 'OUTROS' ||
                (customFormType !== 'RG' &&
                  customFormType !== 'CNH' &&
                  customFormType !== 'RNE' &&
                  customFormType !== 'CRLV' &&
                  customFormType !== 'RNM')) && <TypeOthers isReview={isReview} />}

              {!customFormType && (
                <div className="custom-form-message flex center gx-w-100">
                  <Paragraph>Por favor, informe o tipo de formulário.</Paragraph>
                </div>
              )}
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

OCRDetails.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
};

OCRDetails.defaultProps = {
  isReview: false,
};

export default OCRDetails;
