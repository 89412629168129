import { useState, useCallback } from 'react';

import api from 'services/api';

const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  const clearData = useCallback((props) => {
    const { shouldClearError } = props || {};

    setData(undefined);
    if (shouldClearError) {
      setError(undefined);
    }
  }, []);

  const get = useCallback(
    async ({ url, config, clearData: clearDataParam }) => {
      try {
        if (clearDataParam) clearData();
        setError(undefined);
        setLoading(true);

        const response = await api.get(url, config);
        setData(response?.data);

        return Promise.resolve(response?.data);
      } catch (err) {
        setError({
          statusCode: `${err?.response?.status || 500}`,
          data: err?.response?.data || { message: 'Erro interno do servidor' },
        });

        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [clearData]
  );

  const post = useCallback(async ({ url, payload, config }) => {
    try {
      setError(undefined);
      setLoading(true);

      const response = await api.post(url, payload, config);
      setData(response?.data);

      return Promise.resolve(response?.data);
    } catch (err) {
      setError({
        statusCode: `${err?.response?.status || 500}`,
        data: err?.response?.data || { message: 'Erro interno do servidor' },
      });

      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const patch = useCallback(async ({ url, payload, config }) => {
    try {
      setError(undefined);
      setLoading(true);

      const response = await api.patch(url, payload, config);
      setData(response?.data);

      return Promise.resolve(response?.data);
    } catch (err) {
      setError({
        statusCode: `${err?.response?.status || 500}`,
        data: err?.response?.data || { message: 'Erro interno do servidor' },
      });

      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const put = useCallback(async ({ url, payload, config }) => {
    try {
      setError(undefined);
      setLoading(true);

      const response = await api.put(url, payload, config);
      setData(response?.data);

      return Promise.resolve(response?.data);
    } catch (err) {
      setError({
        statusCode: `${err?.response?.status || 500}`,
        data: err?.response?.data || { message: 'Erro interno do servidor' },
      });

      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const _delete = useCallback(async ({ url, config }) => {
    try {
      setError(undefined);
      setLoading(true);

      const response = await api.delete(url, config);
      setData(response?.data);

      return Promise.resolve(response?.data);
    } catch (err) {
      setError({
        statusCode: `${err?.response?.status || 500}`,
        data: err?.response?.data || { message: 'Erro interno do servidor' },
      });

      throw new Error(err?.response?.data);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    error,
    loading,
    setLoading,
    clearData,
    get,
    post,
    put,
    patch,
    delete: _delete,
  };
};

export default useFetch;
