import React, { useMemo, useState, useCallback } from 'react';
import { Modal as StandardModal, Typography } from 'antd';
import { Button } from '@combateafraude/react';

import './styles.less';

const { Title, Text } = Typography;

const CustomModal = ({
  img,
  title,
  content = null,
  onOk = null,
  keyboard = true,
  ...rest
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const Modal = useMemo(
    () => (
      <StandardModal
        visible={modalVisible}
        wrapClassName="custom-modal-component"
        footer={null}
        centered
        closable={false}
        maskClosable={false}
        onCancel={closeModal}
        keyboard={keyboard}
        {...rest}
      >
        <div className="img-modal">
          <img src={img} alt="Alerta" />
        </div>
        <div className="body-modal">
          <Title>{title}</Title>
          {content && <Text>{content}</Text>}
        </div>
        <Button className="btn-ok btn-custom-primary" onClick={onOk || closeModal}>
          OK
        </Button>
      </StandardModal>
    ),
    [modalVisible, closeModal, keyboard, rest, img, title, content, onOk]
  );

  return {
    openModal,
    closeModal,
    Modal,
  };
};

export default CustomModal;
