import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

import './styles.less';

const CountryFlagAvatar = ({ src, size, ...rest }) => (
  <Avatar
    className="country-flag-avatar"
    src={src}
    alt="bandeira do país"
    size={size}
    {...rest}
  />
);
CountryFlagAvatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number.isRequired,
};

CountryFlagAvatar.defaultProps = {
  src: undefined,
};

export default CountryFlagAvatar;
