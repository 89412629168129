import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { PageTitle, Button } from '@combateafraude/react';

import showToastMessage from 'components/ToastMessage';

import api from 'services/api';

const RemoveOperationsGroup = ({ operationsGroup, refreshListRef }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onRemoveOperationsGroup = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(
        `${process.env.REACT_APP_BASE_URL_WECHECK_API}/operations-groups/${operationsGroup?.id}`
      );
      refreshListRef.current();
      closeModal();
      showToastMessage({
        type: 'success',
        text: 'Grupo de operações removido com sucesso!',
      });
    } catch (err) {
      if (err.response.status === 400) {
        showToastMessage({
          type: 'error',
          text: err.response.data.message,
        });
      } else {
        showToastMessage({
          type: 'error',
          text: 'Houve um problema ao excluir este grupo de operações.',
        });
      }
      closeModal();
    } finally {
      setLoading(false);
    }
  }, [operationsGroup, refreshListRef, closeModal]);

  const RemoveOperationsGroupModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        closable={false}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle title="Excluir grupo de operações" align="center" />

          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              Você realmente deseja excluir esse grupo de operações?
              <strong className="display-block mrg-top-10 text-dark font-size-16">
                {operationsGroup?.name}
              </strong>
            </span>
          </div>

          <div className="flex center-end">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              type="danger"
              onClick={onRemoveOperationsGroup}
              disabled={loading}
              loading={loading}
            >
              {loading ? 'Aguarde...' : 'Sim, excluir'}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, operationsGroup, loading, onRemoveOperationsGroup]
  );

  return { openModal, closeModal, RemoveOperationsGroupModal };
};

export default RemoveOperationsGroup;
