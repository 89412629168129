import React, { useEffect, useState } from 'react';
import { Row, Col, Radio, Form, Carousel } from 'antd';
import PropTypes from 'prop-types';

import ZoomedImage from 'components/Image/ZoomedImage';
import RadioButtonOptions from '../RadioButtonOptions';

import './styles.less';

const DelegateSignature = ({
  recordData,
  formRef,
  modelId,
  setDelegateSignatureData,
  isReview,
  allRulesCorrect,
}) => {
  const [rules, setRules] = useState('');
  const { rulesValidation, automation } = recordData?.record?.documentscopy;
  const { documentscopy } = recordData?.record;

  const [delegateSignatureValue, setDelegateSignatureValue] = useState(
    automation?.delegateSignature?.prediction ? 'correct' : undefined
  );

  useEffect(() => {
    if (allRulesCorrect.delegateSignature) {
      formRef.setFieldsValue({
        delegateSignatureValue: 'correct',
      });

      setDelegateSignatureData({
        selected: 'correct',
      });
    }
  }, [allRulesCorrect, formRef, setDelegateSignatureData]);

  useEffect(() => {
    if (
      isReview &&
      documentscopy?.review &&
      !!Object.keys(documentscopy?.review?.additionalValidations?.delegateSignature || {})
        .length
    ) {
      const { delegateSignature } = documentscopy?.review?.additionalValidations;
      formRef?.setFieldsValue({
        delegateSignatureValue: delegateSignature.selected,
      });
    }
  }, [formRef, isReview, documentscopy]);

  useEffect(() => {
    if (rulesValidation?.rule?._id === modelId) {
      setRules(rulesValidation.rule);
    } else {
      setRules(rulesValidation?.otherRules?.find((r) => r._id === modelId));
    }
  }, [modelId, rulesValidation]);

  useEffect(() => {
    setDelegateSignatureData({
      selected: delegateSignatureValue?.target?.value || delegateSignatureValue,
    });
  }, [delegateSignatureValue, setDelegateSignatureData]);

  return (
    <div id="signature-component">
      {rules?.model && (
        <Row>
          <span className="model">{`Modelo Escolhido: ${rules?.model || ''}`}</span>
        </Row>
      )}
      <Row className="flex fd-row space-between">
        <Col span={12}>
          <ZoomedImage
            src={documentscopy?.processing?.back?.detectedData?.signature[0]}
            height={10}
            label="Analisar"
            minHeight={25}
            zoomPixels={25}
            checkHover
            useImageDefaultHeight
            reduceInitialHeight={45}
          />
        </Col>

        <Col span={12}>
          <Carousel>
            {rules?.delegateSignature?.images?.map((image) => (
              <div className="custom-carousel" key={image}>
                <ZoomedImage
                  src={image}
                  height={10}
                  label="Referência"
                  minHeight={25}
                  zoomPixels={25}
                  checkHover
                  useImageDefaultHeight
                  reduceInitialHeight={45}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Form
        form={formRef}
        initialValues={{
          delegateSignatureValue,
        }}
      >
        <Row align="middle" justify="end">
          <Form.Item name="delegateSignatureValue">
            <Radio.Group
              optionType="button"
              onChange={setDelegateSignatureValue}
              disabled={isReview}
            >
              <RadioButtonOptions />
            </Radio.Group>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

DelegateSignature.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  modelId: PropTypes.string,
  setDelegateSignatureData: PropTypes.func,
  isReview: PropTypes.bool,
  allRulesCorrect: PropTypes.objectOf(PropTypes.objectOf),
};

DelegateSignature.defaultProps = {
  modelId: null,
  setDelegateSignatureData: () => {},
  isReview: false,
  allRulesCorrect: {},
};

export default DelegateSignature;
