/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Collapse } from 'antd';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import {
  CheckCircle2,
  WrongCircle,
  ProcessingLine,
  Info,
  ArrowDown,
  ArrowRight,
} from '@combateafraude/icons/general';

import PropTypes from 'prop-types';

import DisplayRules from './components/DisplayRules';
import ValidationIssueYearUF from './components/ValidationIssueYearUF';
import Observation from './components/Observation';
import ReproveDocument from './components/ReproveDocument';
import ConditionsPhotoAndSignature from './components/ConditionsPhotoAndSignature';
import HeaderData from './components/HeaderData';
import Blazon from './components/Blazon';
import DelegateSignature from './components/DelegateSignature';
import AdditionalInformation from './components/AdditionalInformation';
import AvaliationInfo from './components/AvaliationInfo';
import ExtraInformation from './components/ExtraInformation';

const { Panel } = Collapse;

const AvaliationRGType = ({
  recordData,
  isReview,
  modelId,
  formRef,
  setUfYearChanged,
  ufYearChanged,
  reproveChanged,
  setReproveChanged,
  setHeaderData,
  setLayoutBackData,
  setQualityPhotoSignatureData,
  setBlazonData,
  setDelegateSignatureData,
  setAvaliationFinished,
  redirectUrl,
  allRulesCorrect,
  setAllRulesCorrect,
}) => {
  const { hash } = useLocation();

  const {
    avaliationInfo = null,
    documentscopy = null,
    relatedRecordId = null,
    queue = null,
    settings = null,
    executionInfo = null,
  } = recordData?.record;

  const {
    department = null,
    federativeUnit = null,
    institute = null,
    issuingAuthority = null,
  } = documentscopy?.rulesValidation?.headerValidations;

  const {
    rgMask,
    birthDateMask,
    parentsSeparator,
    cpfMask,
    birthPlace,
    referencePattern,
    issueDateMask,
    civilEvolution,
    via,
  } = documentscopy?.rulesValidation?.textValidations;

  const { perforation = null, photo = null } = documentscopy?.automation;

  const defaultValuesCollapse = useMemo(() => {
    let values = [];

    if (
      (documentscopy?.obs && queue?.solutionLevel !== 'quality') ||
      (relatedRecordId && queue?.solutionLevel !== 'quality') ||
      queue?.solutionLevel === 'poc' ||
      queue?.solutionLevel === 'qrcode'
    ) {
      values.push('obs');
    }

    if (!isReview) {
      values.push('reprove');

      if (settings?.evaluationRulesEnabled) {
        values.push('evaluationRules');
      }

      if (!perforation?.prediction || !photo?.prediction) {
        values.push('qualityPhotoSignature');
      } else {
        setAllRulesCorrect((prevState) => ({
          ...prevState,
          qualityPhotoSignature: true,
        }));
      }

      if (!department || !federativeUnit || !institute || !issuingAuthority) {
        values.push('header');
      } else {
        setAllRulesCorrect((prevState) => ({
          ...prevState,
          header: true,
        }));
      }

      if (!documentscopy?.automation.blazon.prediction) {
        values.push('blazon');
      } else {
        setAllRulesCorrect((prevState) => ({
          ...prevState,
          blazon: true,
        }));
      }

      if (
        !rgMask ||
        !birthDateMask ||
        !parentsSeparator ||
        !cpfMask ||
        !birthPlace ||
        !referencePattern ||
        !issueDateMask ||
        (!via && documentscopy?.rulesValidation?.rule?.via) ||
        (!civilEvolution && documentscopy?.rulesValidation?.rule?.civilEvolution)
      ) {
        values.push('layoutBack');
      } else {
        setAllRulesCorrect((prevState) => ({
          ...prevState,
          layoutBack: true,
        }));
      }
      if (!documentscopy?.automation.delegateSignature.prediction) {
        values.push('delegateSignature');
      } else {
        setAllRulesCorrect((prevState) => ({
          ...prevState,
          delegateSignature: true,
        }));
      }
    } else {
      values = ['avaliation-info'];

      if (documentscopy?.review) {
        if (!documentscopy?.review.approved) {
          values.push('reprove');
        }

        if (documentscopy?.reprocess) {
          values.push('validation-year-uf');
        }

        if (documentscopy?.review.additionalValidations?.qualityPhotoSignature) {
          Object.values(
            documentscopy?.review.additionalValidations?.qualityPhotoSignature
          ).map((r) => {
            if (r.selected === 'unreadable' || r.selected === 'incorrect') {
              return values.push('qualityPhotoSignature');
            }
            return '';
          });
        }

        if (documentscopy?.review.additionalValidations?.header) {
          Object.values(documentscopy?.review.additionalValidations?.header).map((r) => {
            if (r.selected === 'unreadable' || r.selected === 'incorrect') {
              return values.push('header');
            }
            return '';
          });
        }

        if (documentscopy?.review.additionalValidations?.layoutBack) {
          Object.values(documentscopy?.review.additionalValidations?.layoutBack).map(
            (r) => {
              if (r.selected === 'unreadable' || r.selected === 'incorrect') {
                return values.push('layoutBack');
              }
              return '';
            }
          );
        }

        if (
          documentscopy?.review.additionalValidations?.blazon?.selected ===
            'unreadable' ||
          documentscopy?.review.additionalValidations?.blazon?.selected === 'incorrect'
        ) {
          values.push('blazon');
        }

        if (
          documentscopy?.review.additionalValidations?.delegateSignature?.selected ===
            'unreadable' ||
          documentscopy?.review.additionalValidations?.delegateSignature?.selected ===
            'incorrect'
        ) {
          values.push('delegateSignature');
        }
      }
    }
    return values;
  }, [
    documentscopy?.obs,
    documentscopy?.automation.blazon.prediction,
    documentscopy?.automation.delegateSignature.prediction,
    documentscopy?.review,
    documentscopy?.reprocess,
    documentscopy?.rulesValidation?.rule?.via,
    documentscopy?.rulesValidation?.rule?.civilEvolution,
    relatedRecordId,
    queue?.solutionLevel,
    isReview,
    perforation,
    photo,
    department,
    federativeUnit,
    institute,
    issuingAuthority,
    rgMask,
    birthDateMask,
    parentsSeparator,
    cpfMask,
    birthPlace,
    referencePattern,
    issueDateMask,
    via,
    civilEvolution,
    setAllRulesCorrect,
    settings,
  ]);

  return (
    <Collapse className="mrg-left-10" defaultActiveKey={defaultValuesCollapse} ghost>
      {isReview && (
        <Panel
          header="Dados referente a avaliação"
          key="avaliation-info"
          className={`${classNames({
            'primary-border': avaliationInfo?.evaluated && documentscopy?.approved,
            'danger-border': avaliationInfo?.evaluated && !documentscopy?.approved,
            'success-border': avaliationInfo?.evaluated && documentscopy?.reprocess,
          })} mrg-btm-20`}
          extra={
            avaliationInfo?.evaluated && documentscopy?.approved !== null ? (
              documentscopy?.approved ? (
                <CheckCircle2 className="gx-text-primary" width={24} height={24} />
              ) : (
                <WrongCircle className="gx-text-danger" width={24} height={24} />
              )
            ) : (
              documentscopy?.reprocess && (
                <ProcessingLine width={24} height={24} className="gx-text-success" />
              )
            )
          }
          expandIcon={({ isActive }) =>
            isActive ? (
              <ArrowDown width={24} height={24} />
            ) : (
              <ArrowRight width={24} height={24} />
            )
          }
        >
          <AvaliationInfo recordData={recordData} />
        </Panel>
      )}

      {settings?.evaluationRulesEnabled && (
        <Panel
          header="Regras específicas de cliente"
          key="evaluationRules"
          className={`${classNames({
            'warning-border': defaultValuesCollapse.includes('evaluationRules'),
          })} mrg-btm-20`}
        >
          <DisplayRules
            approveRules={settings.approveEvaluationRules}
            reproveRules={settings.reproveEvaluationRules}
            fantasyName={executionInfo?.fantasyName}
          />
        </Panel>
      )}
      <Panel
        header="Observações sobre o documento"
        key="obs"
        className={`${classNames({
          'warning-border': defaultValuesCollapse.includes('obs'),
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('obs') && (
            <Info width={24} height={24} className="gx-text-warning" />
          )
        }
      >
        <Observation recordData={recordData} isReview={isReview} />
      </Panel>
      <Panel
        header="Validação de Ano e UF de emissão"
        key="validation-year-uf"
        className="mrg-btm-20 primary-border"
        extra={
          documentscopy?.reprocess ? (
            <ProcessingLine width={24} height={24} className="gx-text-success" />
          ) : (
            <CheckCircle2 className="gx-text-primary" width={24} height={24} />
          )
        }
      >
        <ValidationIssueYearUF
          recordData={recordData}
          isReview={isReview}
          ufYearChanged={ufYearChanged}
          setUfYearChanged={setUfYearChanged}
          setAvaliationFinished={setAvaliationFinished}
        />
      </Panel>

      <Panel
        header="Condições fotos e assinatura"
        key="qualityPhotoSignature"
        className={`${classNames({
          'danger-border':
            isReview && defaultValuesCollapse.includes('qualityPhotoSignature'),
          'primary-border':
            !defaultValuesCollapse.includes('qualityPhotoSignature') ||
            allRulesCorrect?.qualityPhotoSignature,
          'warning-border':
            !isReview &&
            defaultValuesCollapse.includes('qualityPhotoSignature') &&
            !allRulesCorrect?.qualityPhotoSignature,
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('qualityPhotoSignature') &&
          !allRulesCorrect?.qualityPhotoSignature ? (
            isReview ? (
              <WrongCircle className="gx-text-danger" width={24} height={24} />
            ) : (
              <Info width={24} height={24} className="gx-text-warning" />
            )
          ) : (
            <CheckCircle2 className="gx-text-primary" width={24} height={24} />
          )
        }
      >
        <ConditionsPhotoAndSignature
          recordData={recordData}
          formRef={formRef}
          modelId={modelId}
          setQualityPhotoSignatureData={setQualityPhotoSignatureData}
          isReview={isReview}
          allRulesCorrect={allRulesCorrect}
          setAllRulesCorrect={setAllRulesCorrect}
        />
      </Panel>
      <Panel
        header="Dados do cabeçalho"
        key="header"
        className={`${classNames({
          'danger-border': isReview && defaultValuesCollapse.includes('header'),
          'primary-border':
            !defaultValuesCollapse.includes('header') || allRulesCorrect?.header,
          'warning-border':
            !isReview &&
            defaultValuesCollapse.includes('header') &&
            !allRulesCorrect?.header,
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('header') && !allRulesCorrect.header ? (
            isReview ? (
              <WrongCircle className="gx-text-danger" width={24} height={24} />
            ) : (
              <Info width={24} height={24} className="gx-text-warning" />
            )
          ) : (
            <CheckCircle2 className="gx-text-primary" width={24} height={24} />
          )
        }
      >
        <HeaderData
          formRef={formRef}
          modelId={modelId}
          recordData={recordData}
          setHeaderData={setHeaderData}
          isReview={isReview}
          allRulesCorrect={allRulesCorrect}
          setAllRulesCorrect={setAllRulesCorrect}
        />
      </Panel>
      <Panel
        header="Brasão"
        key="blazon"
        className={`${classNames({
          'danger-border': isReview && defaultValuesCollapse.includes('blazon'),
          'primary-border':
            !defaultValuesCollapse.includes('blazon') || allRulesCorrect?.blazon,
          'warning-border':
            !isReview &&
            defaultValuesCollapse.includes('blazon') &&
            !allRulesCorrect?.blazon,
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('blazon') && !allRulesCorrect.blazon ? (
            isReview ? (
              <WrongCircle className="gx-text-danger" width={24} height={24} />
            ) : (
              <Info width={24} height={24} className="gx-text-warning" />
            )
          ) : (
            <CheckCircle2 className="gx-text-primary" width={24} height={24} />
          )
        }
      >
        <Blazon
          recordData={recordData}
          formRef={formRef}
          modelId={modelId}
          setBlazonData={setBlazonData}
          isReview={isReview}
          allRulesCorrect={allRulesCorrect}
        />
      </Panel>
      <Panel
        header="Assinatura"
        key="delegateSignature"
        className={`${classNames({
          'danger-border':
            isReview && defaultValuesCollapse.includes('delegateSignature'),
          'primary-border':
            !defaultValuesCollapse.includes('delegateSignature') ||
            allRulesCorrect?.delegateSignature,
          'warning-border':
            !isReview &&
            defaultValuesCollapse.includes('delegateSignature') &&
            !allRulesCorrect?.delegateSignature,
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('delegateSignature') &&
          !allRulesCorrect?.delegateSignature ? (
            isReview ? (
              <WrongCircle className="gx-text-danger" width={24} height={24} />
            ) : (
              <Info width={24} height={24} className="gx-text-warning" />
            )
          ) : (
            <CheckCircle2 className="gx-text-primary" width={24} height={24} />
          )
        }
      >
        <DelegateSignature
          recordData={recordData}
          formRef={formRef}
          modelId={modelId}
          setDelegateSignatureData={setDelegateSignatureData}
          isReview={isReview}
          allRulesCorrect={allRulesCorrect}
        />
      </Panel>
      <Panel
        header="Dados Adicionais"
        key="layoutBack"
        className={`${classNames({
          'danger-border': isReview && defaultValuesCollapse.includes('layoutBack'),
          'primary-border':
            !defaultValuesCollapse.includes('layoutBack') || allRulesCorrect.layoutBack,
          'warning-border':
            !isReview &&
            defaultValuesCollapse.includes('layoutBack') &&
            !allRulesCorrect.layoutBack,
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('layoutBack') && !allRulesCorrect.layoutBack ? (
            isReview ? (
              <WrongCircle className="gx-text-danger" width={24} height={24} />
            ) : (
              <Info width={24} height={24} className="gx-text-warning" />
            )
          ) : (
            <CheckCircle2 className="gx-text-primary" width={24} height={24} />
          )
        }
      >
        <AdditionalInformation
          recordData={recordData}
          formRef={formRef}
          modelId={modelId}
          setLayoutBackData={setLayoutBackData}
          isReview={isReview}
          allRulesCorrect={allRulesCorrect}
          setAllRulesCorrect={setAllRulesCorrect}
        />
      </Panel>
      <Panel header="Informações extras" key="extraInfo" className="mrg-btm-20">
        <ExtraInformation recordData={recordData} formRef={formRef} modelId={modelId} />
      </Panel>
      {!isReview && (
        <Panel
          id="reprove-component"
          header="Reprovar o documento"
          key="reprove"
          className={`${classNames({
            'danger-border': hash === '#reprove-component',
          })} mrg-btm-20`}
        >
          <ReproveDocument
            recordData={recordData}
            isReview={isReview}
            reproveChanged={reproveChanged}
            setReproveChanged={setReproveChanged}
            setAvaliationFinished={setAvaliationFinished}
            redirectUrl={redirectUrl}
          />
        </Panel>
      )}
    </Collapse>
  );
};

AvaliationRGType.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  modelId: PropTypes.string,
  setUfYearChanged: PropTypes.func,
  setHeaderData: PropTypes.func,
  ufYearChanged: PropTypes.bool,
  setReproveChanged: PropTypes.func,
  setLayoutBackData: PropTypes.func,
  reproveChanged: PropTypes.bool,
  setQualityPhotoSignatureData: PropTypes.func,
  setBlazonData: PropTypes.func,
  setDelegateSignatureData: PropTypes.func,
  setAvaliationFinished: PropTypes.func,
  setAllRulesCorrect: PropTypes.func,
  redirectUrl: PropTypes.string,
  allRulesCorrect: PropTypes.objectOf(PropTypes.objectOf),
};

AvaliationRGType.defaultProps = {
  modelId: null,
  isReview: false,
  ufYearChanged: false,
  reproveChanged: false,
  redirectUrl: '/manual-review-wizard',
  allRulesCorrect: {},
  setUfYearChanged: () => {},
  setHeaderData: () => {},
  setReproveChanged: () => {},
  setLayoutBackData: () => {},
  setQualityPhotoSignatureData: () => {},
  setBlazonData: () => {},
  setDelegateSignatureData: () => {},
  setAvaliationFinished: () => {},
  setAllRulesCorrect: () => {},
};

export default AvaliationRGType;
