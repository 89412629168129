import React, { useState, useEffect } from 'react';
import { Row, Form, Input, Radio, Button } from 'antd';
import PropTypes from 'prop-types';
import RadioButtonOptions from '../RadioButtonOptions';

import './styles.less';

const HeaderData = ({
  formRef,
  recordData,
  modelId,
  setHeaderData,
  isReview,
  setAllRulesCorrect,
  allRulesCorrect,
}) => {
  const [rules, setRules] = useState('');
  const { documentscopy } = recordData?.record;
  const [federativeUnit, setFederativeUnit] = useState(
    rules?.federativeUnit?.toUpperCase()
  );
  const [federativeUnitValue, setFederativeUnitValue] = useState(
    documentscopy?.rulesValidation?.headerValidations?.federativeUnit
      ? 'correct'
      : undefined
  );
  const [issuingAuthority, setIssuingAuthority] = useState(
    rules?.issuingAuthority?.toUpperCase()
  );
  const [issuingAuthorityValue, setIssuingAuthorityValue] = useState(
    documentscopy?.rulesValidation?.headerValidations?.issuingAuthority
      ? 'correct'
      : undefined
  );
  const [department, setDepartment] = useState(rules?.department?.toUpperCase());
  const [departmentValue, setDepartmentValue] = useState(
    documentscopy?.rulesValidation?.headerValidations?.department ? 'correct' : undefined
  );
  const [institute, setInstitute] = useState(rules?.institute?.toUpperCase());
  const [instituteValue, setInstituteValue] = useState(
    documentscopy?.rulesValidation?.headerValidations?.institute ? 'correct' : undefined
  );

  useEffect(() => {
    if (documentscopy?.rulesValidation?.rule?._id === modelId) {
      setRules(documentscopy?.rulesValidation?.rule);
    } else {
      setRules(
        documentscopy?.rulesValidation?.otherRules?.find((r) => r._id === modelId)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  useEffect(() => {
    if (allRulesCorrect.header) {
      formRef.setFieldsValue({
        federativeUnitValue: 'correct',
        issuingAuthorityValue: 'correct',
        instituteValue: 'correct',
        departmentValue: 'correct',
      });

      setFederativeUnitValue('correct');
      setIssuingAuthorityValue('correct');
      setInstituteValue('correct');
      setDepartmentValue('correct');
    }
  }, [rules, allRulesCorrect, formRef]);

  useEffect(() => {
    if (!isReview) {
      setFederativeUnit(rules?.federativeUnit?.toUpperCase());
      setIssuingAuthority(rules?.issuingAuthority?.toUpperCase());
      setDepartment(rules?.department?.toUpperCase());
      setInstitute(rules?.institute?.toUpperCase());
    }
  }, [isReview, rules]);

  useEffect(() => {
    if (
      isReview &&
      documentscopy?.review &&
      !!Object.keys(documentscopy?.review?.additionalValidations?.header || {}).length
    ) {
      const { header } = documentscopy?.review?.additionalValidations;
      if (header.federativeUnit) {
        formRef?.setFieldsValue({
          federativeUnitValue: header.federativeUnit.selected,
          federativeUnit: header.federativeUnit.value,
        });
      }

      if (header.issuingAuthority) {
        formRef?.setFieldsValue({
          issuingAuthorityValue: header.issuingAuthority.selected,
          issuingAuthority: header.issuingAuthority.value,
        });
      }

      if (header.department) {
        formRef?.setFieldsValue({
          departmentValue: header.department.selected,
          department: header.department.value,
        });
      }

      if (header.institute) {
        formRef?.setFieldsValue({
          instituteValue: header.institute.selected,
          institute: header.institute.value,
        });
      }
    } else {
      formRef?.setFieldsValue({
        federativeUnit: rules?.federativeUnit?.toUpperCase(),
        issuingAuthority: rules?.issuingAuthority?.toUpperCase(),
        department: rules?.department?.toUpperCase(),
        institute: rules?.institute?.toUpperCase(),
      });
    }
  }, [formRef, isReview, documentscopy, rules]);

  useEffect(() => {
    setHeaderData({
      federativeUnit: {
        value: federativeUnit,
        selected: federativeUnitValue?.target?.value || federativeUnitValue,
      },
      issuingAuthority: {
        value: issuingAuthority,
        selected: issuingAuthorityValue?.target?.value || issuingAuthorityValue,
      },
      department: {
        value: department,
        selected: departmentValue?.target?.value || departmentValue,
      },
      institute: {
        value: institute,
        selected: instituteValue?.target?.value || instituteValue,
      },
    });
  }, [
    department,
    departmentValue,
    federativeUnit,
    federativeUnitValue,
    institute,
    instituteValue,
    issuingAuthority,
    issuingAuthorityValue,
    setHeaderData,
  ]);

  function selectAll() {
    setAllRulesCorrect((prevState) => ({ ...prevState, header: true }));
  }

  return (
    <div id="header-data-component">
      <Form
        form={formRef}
        initialValues={{
          federativeUnitValue,
          issuingAuthorityValue,
          departmentValue,
          instituteValue,
          institute,
          department,
          issuingAuthority,
          federativeUnit,
        }}
      >
        {rules?.model && (
          <Row>
            <span className="model">{`Modelo Escolhido: ${rules?.model || ''}`}</span>
          </Row>
        )}
        {rules?.federativeUnit && (
          <div>
            <div>
              <span className="label">Valide a Primeira Linha</span>
              <Form.Item name="federativeUnit" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setFederativeUnit(e.target.value)}
                  value={federativeUnit?.toUpperCase()}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item
                name="federativeUnitValue"
                valuePropName="value"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  optionType="button"
                  onChange={setFederativeUnitValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.issuingAuthority && (
          <div>
            <div>
              <span className="label">Valide a Segunda Linha</span>
              <Form.Item
                name="issuingAuthority"
                valuePropName="value"
                className="mrg-btm-25"
              >
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  value={issuingAuthority?.toUpperCase()}
                  onChange={(e) => setIssuingAuthority(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="issuingAuthorityValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setIssuingAuthorityValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.department && (
          <div>
            <div>
              <span className="label">Valide a Terceira Linha</span>
              <Form.Item name="department" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  value={department?.toUpperCase()}
                  onChange={(e) => setDepartment(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="departmentValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setDepartmentValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
            <div />
          </div>
        )}

        {rules?.institute && (
          <div>
            <div>
              <span className="label">Valide a Quarta Linha</span>
              <Form.Item name="institute" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  value={institute?.toUpperCase()}
                  onChange={(e) => setInstitute(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="instituteValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setInstituteValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
      {!isReview && recordData?.record?.executionInfo?.type?.toUpperCase() !== 'CNH' && (
        <div className="footer">
          <Button type="link" primary onClick={() => selectAll()}>
            Todas as regras estão corretas
          </Button>
        </div>
      )}
    </div>
  );
};

HeaderData.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  setHeaderData: PropTypes.func,
  modelId: PropTypes.string,
  isReview: PropTypes.bool,
  setAllRulesCorrect: PropTypes.func,
  allRulesCorrect: PropTypes.objectOf(PropTypes.objectOf),
};

HeaderData.defaultProps = {
  modelId: null,
  isReview: PropTypes.bool,
  allRulesCorrect: {},
  setHeaderData: () => {},
  setAllRulesCorrect: () => {},
};

export default HeaderData;
