import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import {
  Col,
  Modal as ModalAntd,
  Dropdown,
  Divider,
  Collapse,
  Typography,
  Tabs,
  Tag,
} from 'antd';
import {
  ArrowDown,
  ArrowRight,
  Clock,
  Consultas,
  Duplicate,
  Home,
} from '@combateafraude/icons/general';

import { StrictEvaluation } from '@combateafraude/icons/face';
import { useBeforeunload } from 'react-beforeunload';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import ZoomedImage from 'components/Image/ZoomedImage';
import showToastMessage from 'components/ToastMessage';

import alertImg from 'assets/images/alert-img.svg';

import Loader from 'components/Loader';
import Button from 'components/Button';

import Modal from 'components/Modal';

import { useAuth } from 'hooks/auth';
import { useRecord } from 'hooks/record';
import { useCountdown } from 'hooks/countdown';
import { fetchApi } from 'services/api';
import { useFetch } from 'services/hooks';

import Countdown from '../pages/components/Countdown';
import Observation from '../pages/components/Observation';
import AvaliationInfo from '../pages/components/AvaliationInfo';

import Wrapper from '../wrapper';

import './styles.less';

const { Panel } = Collapse;
const { Text } = Typography;
const { TabPane } = Tabs;

const RecordDetailsEvaluationLiveness = ({ id }) => {
  const { recordId } = useParams();
  const { loggedUser } = useAuth();
  const { recordData, loadingRecord, loadData, error, clearRecord } = useRecord();
  const history = useHistory();

  const { put, loading } = useFetch();

  const { time, timeout, setTimeLast, fiveMinutes } = useCountdown();
  const [redirectUrl, setRedirectUrl] = useState('/manual-review-wizard');
  const [avaliationFinished, setAvaliationFinished] = useState(false);
  const [keyTabAvaliation, setKeyTabAvaliation] = useState('1');

  const { openModal: initialPage, Modal: ModalInfoInitialPage } = Modal({
    img: alertImg,
    title: 'Termine de avaliar o documento para voltar para a página inicial!',
    okText: 'Ok',
    okType: 'primary',
  });

  const skipEvaluation = useCallback(() => {
    const body = {
      user: {
        _id: loggedUser._id,
        name: loggedUser.name,
        username: loggedUser.username,
      },
      timeout,
      time,
      skippedAt: new Date(),
    };

    if (!avaliationFinished) {
      fetchApi(`/queuing/${recordData?.record?._id}`, 'PUT', body);
    }
  }, [avaliationFinished, loggedUser, recordData, time, timeout]);

  useBeforeunload(async () => {
    skipEvaluation();

    return undefined;
  });

  const backHome = useCallback(async () => {
    initialPage();

    setRedirectUrl('/home');
  }, [initialPage]);

  const handleSubmitApprove = useCallback(async () => {
    const payload = {
      avaliationInfo: {
        evaluated: true,
        user: {
          _id: loggedUser._id,
          name: loggedUser.name,
          username: loggedUser.username,
        },
        time,
        reviewAt: new Date(),
      },
      doubleCheckLiveness: {
        approved: true,
        fraud: false,
      },
      type: recordData?.record?.type,
    };

    try {
      await put({
        url: `/records/${recordData?.record._id}`,
        payload,
      });

      setAvaliationFinished(true);

      history.push(redirectUrl);
      window.location.reload();
    } catch (e) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao registrar informações.',
      });
      setAvaliationFinished(true);
      history.replace('/home');
    }
  }, [history, loggedUser, put, recordData, redirectUrl, time]);

  const handleSubmitReprove = useCallback(async () => {
    ModalAntd.confirm({
      title: 'Você realmente deseja reprovar esse documento?',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      width: 314,
      height: 171,
      onOk: async () => {
        const payload = {
          avaliationInfo: {
            evaluated: true,
            user: {
              _id: loggedUser._id,
              name: loggedUser.name,
              username: loggedUser.username,
            },
            time,
            reviewAt: new Date(),
          },
          doubleCheckLiveness: {
            approved: false,
            fraud: false,
          },
          type: recordData?.record?.type,
        };

        try {
          await put({
            url: `/records/${id}`,
            payload,
          });

          setAvaliationFinished(true);

          history.push(redirectUrl);
          window.location.reload();
        } catch (e) {
          showToastMessage({
            type: 'error',
            text: 'Falha ao reprovar documento.',
          });
          setAvaliationFinished(true);
          history.replace('/home');
        }
      },
    });
  }, [loggedUser, time, recordData, put, id, history, redirectUrl]);

  const info = useMemo(() => {
    const defaultActiveKey = ['obs', recordId && 'review'];
    return (
      <div className="liveness-info">
        <Collapse
          expandIconPosition="right"
          className="mrg-left-10"
          defaultActiveKey={defaultActiveKey}
          ghost
          expandIcon={({ isActive }) =>
            isActive ? (
              <ArrowDown width={24} height={24} />
            ) : (
              <ArrowRight width={24} height={24} />
            )
          }
        >
          <Panel header="Observações" key="obs">
            <Observation recordData={recordData} />
            <Divider />
          </Panel>

          {recordId && (
            <Panel
              header="Dados referentes a avaliação"
              key="review"
              className="custom-panel"
            >
              <AvaliationInfo recordData={recordData} />
            </Panel>
          )}
        </Collapse>
      </div>
    );
  }, [recordData, recordId]);

  const evaluationPage = useMemo(() => {
    if (recordData) {
      const { record } = recordData;
      const { images } = record.executionInfo;
      const MenuItemsAvaliation = [
        { key: '1', label: 'Imagem tratada' },
        { key: '2', label: 'Imagem original' },
      ];

      return (
        <Wrapper>
          {ModalInfoInitialPage}
          <div className="header-record-details full-width flex">
            <div className="flex center fd-row gx-w-100 space-between details">
              <div className="flex center">
                {!recordId && (
                  <Button
                    id="back-home-button"
                    type="link"
                    onClick={backHome}
                    className={`${classNames({
                      'back-home-enabled': redirectUrl === '/home',
                    })} btn-icon`}
                  >
                    <Home />
                  </Button>
                )}
                <span>Id: {record._id}</span>
                <CopyToClipboardButton
                  text={`${process.env.REACT_APP_BASE_URL_WECHECK_WEB}/${record.type}/records/details/record/${record._id}`}
                  onCopyText="Link de detalhes da execução copiado!"
                  icon={<Duplicate />}
                  size="small"
                  className="copy-record-details-link-button mrg-left-5 flex center"
                />
                {record?.settings?.strictEvaluation && (
                  <Tag className="strict-eval-tag">
                    <div className="icon">
                      <StrictEvaluation width="20px" height="20px" />
                    </div>
                    Avaliação rígida
                  </Tag>
                )}
              </div>

              <div className="flex center">
                {!recordId && (
                  <>
                    <div className="flex center">
                      <Clock width={24} height={24} />
                    </div>
                    <Countdown />
                  </>
                )}

                <Dropdown
                  overlay={info}
                  trigger={['click']}
                  overlayClassName="liveness-dropdown"
                  dropdownAlign={{ offset: [-40, -40] }}
                >
                  <Button type="link" className="mrg-left-15 btn-icon">
                    <Consultas />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="body-record-details full-width">
            <Col className="img-liveness">
              <Tabs className="tabs-zoomed-images" activeKey={keyTabAvaliation}>
                <TabPane key="1">
                  <div className="mrg-btm-15">
                    <ZoomedImage
                      src={images?.selfieCompressed || images?.selfie}
                      alt="Foto tratada"
                      height={28}
                      label="Foto"
                      className="mrg-btm-10"
                      showScaleButton
                      setKeyTab={setKeyTabAvaliation}
                      keyTab={keyTabAvaliation}
                      showMenu
                      menuItems={MenuItemsAvaliation}
                      isLiveness
                      id="doublecheck-liveness-treated-image"
                    />
                  </div>
                </TabPane>
                <TabPane key="2">
                  <div className="mrg-btm-15">
                    <ZoomedImage
                      src={images?.selfie}
                      alt="Foto original"
                      height={28}
                      label="Foto"
                      className="mrg-btm-10"
                      showScaleButton
                      setKeyTab={setKeyTabAvaliation}
                      keyTab={keyTabAvaliation}
                      showMenu
                      menuItems={MenuItemsAvaliation}
                      isLiveness
                      id="doublecheck-liveness-original-image"
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Col>
            <Col className="video-liveness">
              {images?.video ? (
                <ReactPlayer url={images.video} playing loop controls muted />
              ) : (
                <Text> Vídeo não disponível</Text>
              )}
            </Col>
          </div>

          {!recordId && (
            <div className="footer-record-details full-width flex">
              <div className="flex center">
                <Button
                  onClick={handleSubmitApprove}
                  loading={loading}
                  className="btn-finished btn-custom-primary flex center"
                >
                  Aprovar
                </Button>

                <Button
                  onClick={handleSubmitReprove}
                  className="btn-custom-primary btn-reprove flex center"
                >
                  Reprovar
                </Button>
              </div>
            </div>
          )}
        </Wrapper>
      );
    }
    return <></>;
  }, [
    ModalInfoInitialPage,
    backHome,
    handleSubmitApprove,
    handleSubmitReprove,
    info,
    loading,
    recordData,
    recordId,
    redirectUrl,
    keyTabAvaliation,
  ]);

  useEffect(() => {
    const idData = id || recordId;

    loadData({ recordId: idData, clearData: true });
  }, [loadData, id, recordId, clearRecord]);

  useEffect(() => {
    setTimeLast(fiveMinutes);
  }, [fiveMinutes, setTimeLast]);

  const hasRecordError = useMemo(() => {
    const formattedErrorStatusCode = error?.statusCode;

    return ['500', '400'].includes(formattedErrorStatusCode);
  }, [error]);

  return (
    <div id="record-details-liveness-component">
      {(loadingRecord && !recordData) || loading || avaliationFinished ? (
        <div id="page-loading">
          <Loader size="large" />
        </div>
      ) : (
        <>
          {hasRecordError ? (
            <>
              <Redirect to="/home" />
              {clearRecord({ shouldClearError: true })}
            </>
          ) : (
            <>{recordData && <div className="gx-flex-row">{evaluationPage}</div>}</>
          )}
        </>
      )}
    </div>
  );
};

RecordDetailsEvaluationLiveness.propTypes = {
  id: PropTypes.string,
};

RecordDetailsEvaluationLiveness.defaultProps = {
  id: null,
};

export default RecordDetailsEvaluationLiveness;
