import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ViewSelfieModal from './ViewSelfieModal';

const ViewSelfieButton = ({ selfieImg }) => {
  const { openModal: openModalViewSelfie, Modal: ModalViewSelfie } = ViewSelfieModal({
    selfieImg,
  });

  const handleOpenViewSelfieModal = useCallback(async () => {
    openModalViewSelfie();
  }, [openModalViewSelfie]);

  return (
    <>
      {ModalViewSelfie}

      <Button onClick={handleOpenViewSelfieModal}>Ver selfie</Button>
    </>
  );
};

ViewSelfieButton.propTypes = {
  selfieImg: PropTypes.string.isRequired,
};

export default ViewSelfieButton;
