import React from 'react';
import PropTypes from 'prop-types';
import { Radio, DatePicker, Checkbox, Divider } from 'antd';
import moment from 'moment';

import { convertForTimestamp } from '../utils';

const { RangePicker } = DatePicker;

const SelectCreationDate = ({
  lifetime,
  setLifetime,
  createdAtFilter,
  reviewedAt,
  setReviewedAtFilter,
  setCreatedAtFilter,
  customCreatedAtFilter,
  setCustomCreatedAtFilter,
}) => {
  const isCustomCreationDate = () => createdAtFilter?.substr(0, 6) === 'custom';
  const isReviewedAt = () => reviewedAt === 'true';

  const getDatePickerValue = () => {
    if (!isCustomCreationDate() || createdAtFilter === 'custom') return;
    let startDateFinal;
    let endDateFinal;

    if (Array.isArray(customCreatedAtFilter)) {
      const { startDate, endDate } = customCreatedAtFilter[0];

      startDateFinal = startDate;
      endDateFinal = endDate;
    } else {
      const [startDate, endDate] = customCreatedAtFilter.substr(7).split('-');
      startDateFinal = startDate;
      endDateFinal = endDate;
    }
    // eslint-disable-next-line consistent-return
    return [
      moment(new Date(parseInt(startDateFinal, 10))),
      moment(new Date(parseInt(endDateFinal, 10))),
    ];
  };

  return (
    <>
      <>
        <Radio.Group
          defaultValue=""
          value={isReviewedAt() ? reviewedAt : 'false'}
          onChange={(e) => {
            setReviewedAtFilter(e.target.value);
            setLifetime([]);
          }}
        >
          <Radio value="false">Criado em</Radio>
          <Radio value="true">Avaliado em</Radio>
          <Divider />
        </Radio.Group>
      </>
      {!isCustomCreationDate() && !isReviewedAt() && (
        <>
          <Checkbox.Group
            defaultValue={[]}
            value={!isReviewedAt() && !isCustomCreationDate() ? lifetime : []}
            onChange={(e) => setLifetime(e)}
          >
            <Checkbox value="fiveMin">Criado &gt; 5 minutos</Checkbox>
            <Divider />
          </Checkbox.Group>
        </>
      )}
      <Radio.Group
        defaultValue=""
        value={isCustomCreationDate() ? 'custom' : createdAtFilter || ''}
        onChange={(e) => setCreatedAtFilter(e.target.value)}
      >
        <Radio value="last24Hours">Últimas 24 horas</Radio>
        <Radio value="last7Days">Últimos 7 dias</Radio>
        <Radio value="last14Days">Últimos 14 dias</Radio>
        <Radio value="last30Days">Últimos 30 dias</Radio>
        <Radio value="custom">Intervalo personalizado</Radio>
      </Radio.Group>
      {isCustomCreationDate() && (
        <RangePicker
          className="mrg-top-5 gx-bg-transparent range-picker-custom"
          id="openOneCalendar"
          format="DD/MM/YYYY HH:mm"
          showTime
          value={getDatePickerValue()}
          onChange={(value) => {
            if (value) {
              const { startDate, endDate } = convertForTimestamp(value);
              setCustomCreatedAtFilter([{ startDate, endDate }]);
            } else {
              setCustomCreatedAtFilter('custom');
            }
          }}
        />
      )}
    </>
  );
};

SelectCreationDate.propTypes = {
  lifetime: PropTypes.arrayOf(PropTypes.string),
  setLifetime: PropTypes.func,
  reviewedAt: PropTypes.string,
  setReviewedAtFilter: PropTypes.func,
  createdAtFilter: PropTypes.string,
  customCreatedAtFilter: PropTypes.string,
  setCreatedAtFilter: PropTypes.func,
  setCustomCreatedAtFilter: PropTypes.func,
};

SelectCreationDate.defaultProps = {
  lifetime: null,
  setLifetime: null,
  reviewedAt: null,
  setReviewedAtFilter: null,
  createdAtFilter: null,
  customCreatedAtFilter: null,
  setCreatedAtFilter: null,
  setCustomCreatedAtFilter: null,
};

export default SelectCreationDate;
