import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import PropTypes from 'prop-types';

import ZoomedImage from 'components/Image/ZoomedImage';

import imageError from 'assets/images/imageError.png';
import DocumentsHeader from '../DocumentsHeader';

import './styles.less';

const { TabPane } = Tabs;

const DocumentsConfirmation = ({ recordData, setModelId, modelId, isReview }) => {
  const { queue = null, executionInfo = null } = recordData?.record;

  const { documentscopy = null } = recordData?.record;
  const [keyTabAvaliation, setKeyTabAvaliation] = useState(
    queue?.solutionLevel === 'qrcode' || queue?.type === 'documentscopy' ? '3' : '1'
  );

  const [keyTabReference, setKeyTabReference] = useState(
    documentscopy?.rulesValidation?.rule?._id
  );

  useEffect(() => {
    setModelId(documentscopy?.rulesValidation?.rule._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageTabs = useCallback(
    (height = undefined) => {
      if (!recordData) return <></>;

      const MenuItemsAvaliation = [
        { key: '1', label: 'Imagem tratada' },
        { key: '2', label: 'Imagem cortada' },
        { key: '3', label: 'Imagem original' },
      ];

      const MenuItemsReference = [
        {
          key: documentscopy?.rulesValidation?.rule?._id,
          label: `Modelo ${documentscopy?.rulesValidation?.rule?.model || ''}`,
        },
      ];

      if (documentscopy?.rulesValidation?.otherRules?.length > 0) {
        documentscopy?.rulesValidation?.otherRules?.map((otherRule) =>
          MenuItemsReference.push({
            key: otherRule?._id,
            label: `Modelo ${otherRule?.model || ''}`,
          })
        );
      }

      const {
        front = null,
        back = null,
        originalFront = null,
        originalBack = null,
        originalFrontCompressed = null,
        originalBackCompressed = null,
      } = executionInfo?.images;
      const containsImage =
        originalFrontCompressed ||
        originalBackCompressed ||
        front ||
        back ||
        originalFront ||
        originalBack;

      return containsImage ? (
        <Row>
          <Col span={12}>
            <Tabs className="tabs-zoomed-images" activeKey={keyTabAvaliation}>
              <TabPane key="1" forceRender>
                <div className="mrg-btm-15">
                  <ZoomedImage
                    src={originalFrontCompressed || originalFront}
                    alt="Documento frente"
                    height={height}
                    label="Análise"
                    className="mrg-btm-10"
                    showScaleButton
                    setKeyTab={setKeyTabAvaliation}
                    keyTab={keyTabAvaliation}
                    showMenu
                    menuItems={MenuItemsAvaliation}
                    id="review-compressed-front-document-image"
                  />
                </div>
                <ZoomedImage
                  src={originalBackCompressed || originalBack}
                  alt="Documento verso"
                  height={height}
                  label="Análise"
                  showScaleButton
                  id="review-compressed-back-document-image"
                />
              </TabPane>
              <TabPane key="2" forceRender>
                <div className="mrg-btm-15">
                  <ZoomedImage
                    src={front}
                    alt="Documento frente"
                    height={height}
                    label="Análise"
                    className="mrg-btm-10"
                    showScaleButton
                    setKeyTab={setKeyTabAvaliation}
                    keyTab={keyTabAvaliation}
                    showMenu
                    menuItems={MenuItemsAvaliation}
                    id="review-cropped-front-document-image"
                  />
                </div>
                <ZoomedImage
                  src={back}
                  alt="Documento verso"
                  height={height}
                  label="Análise"
                  showScaleButton
                  id="review-cropped-back-document-image"
                />
              </TabPane>
              <TabPane key="3" forceRender>
                <div className="mrg-btm-15">
                  <ZoomedImage
                    src={originalFront}
                    alt="Documento frente"
                    height={height}
                    label="Análise"
                    className="mrg-btm-10"
                    showScaleButton
                    setKeyTab={setKeyTabAvaliation}
                    keyTab={keyTabAvaliation}
                    showMenu
                    menuItems={MenuItemsAvaliation}
                    id="review-original-front-document-image"
                  />
                </div>
                <ZoomedImage
                  src={originalBack}
                  alt="Documento verso"
                  height={height}
                  label="Análise"
                  showScaleButton
                  id="review-original-back-document-image"
                />
              </TabPane>
            </Tabs>
          </Col>
          <Col span={12}>
            {documentscopy?.rulesValidation?.rule &&
              Object.keys(documentscopy?.rulesValidation?.rule)?.length > 0 &&
              (documentscopy?.rulesValidation?.rule?.back?.length > 0 ||
                documentscopy?.rulesValidation?.rule?.front?.length > 0 ||
                documentscopy?.rulesValidation?.otherRules?.length > 0) && (
                <Tabs
                  className="tabs-zoomed-images"
                  onChange={() => setModelId(keyTabReference)}
                  activeKey={keyTabReference}
                >
                  <TabPane key={documentscopy?.rulesValidation?.rule._id} forceRender>
                    <div className="mrg-btm-15">
                      <ZoomedImage
                        src={documentscopy?.rulesValidation?.rule?.front}
                        alt="Documento frente"
                        height={height}
                        label="Referência"
                        showScaleButton
                        setKeyTab={setKeyTabReference}
                        keyTab={keyTabReference}
                        showMenu
                        menuItems={MenuItemsReference}
                        id="main-model-reference-front-document-image"
                      />
                    </div>
                    <ZoomedImage
                      src={documentscopy?.rulesValidation?.rule?.back}
                      alt="Documento verso"
                      height={height}
                      label="Referência"
                      showScaleButton
                      id="main-model-reference-back-document-image"
                    />
                  </TabPane>
                  {documentscopy?.rulesValidation?.otherRules?.length > 0 &&
                    documentscopy?.rulesValidation?.otherRules?.map((otherRule) => (
                      <TabPane key={otherRule._id} forceRender>
                        <div className="mrg-btm-15">
                          <ZoomedImage
                            src={otherRule?.front}
                            alt="Documento frente"
                            height={height}
                            label="Referência"
                            showScaleButton
                            setKeyTab={setKeyTabReference}
                            keyTab={keyTabReference}
                            showMenu
                            menuItems={MenuItemsReference}
                            id={`${
                              otherRule?.model || otherRule._id
                            }-model-reference-front-document-image`}
                          />
                        </div>
                        <ZoomedImage
                          src={otherRule?.back}
                          alt="Documento Verso"
                          height={height}
                          label="Referência"
                          showScaleButton
                          id={`${
                            otherRule?.model || otherRule._id
                          }-model-reference-back-document-image`}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              )}
          </Col>
        </Row>
      ) : (
        <img
          src={imageError}
          alt="Homem confuso"
          className="grayscale-95  mrg-top-80 img-error"
        />
      );
    },
    [
      keyTabAvaliation,
      keyTabReference,
      documentscopy,
      recordData,
      setModelId,
      executionInfo,
    ]
  );

  return (
    <div id="documents-confirmation-component">
      <DocumentsHeader recordData={recordData} isReview={isReview} modelId={modelId} />
      <div className="image-tabs">{imageTabs(16)}</div>
    </div>
  );
};

DocumentsConfirmation.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  setModelId: PropTypes.func,
  modelId: PropTypes.string || PropTypes.number,
  isReview: PropTypes.bool,
};

DocumentsConfirmation.defaultProps = {
  setModelId: () => {},
  modelId: null,
  isReview: undefined,
};

export default DocumentsConfirmation;
