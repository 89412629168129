import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import FieldDetailsList from '../FieldDetailsList';
import './styles.less';

const SpecimenInformation = ({ specimenData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      securityFeature: specimenData?.securityFeature,
      advisoryNotes: specimenData?.advisoryNotes,
      // docRefUrl: specimenData?.docRefUrl,
      // stateFlag: specimenData?.stateFlag,
      // thumbnail: specimenData?.thumbnail,
    });
  }, [form, specimenData]);

  return (
    <div id="specimen-information-component" className="flex-column flex-1">
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        // className="uppercase-form"
      >
        <div className="custom-inputs">
          <span style={{ fontSize: '1rem' }}>Dados Forenses</span>
          <div style={{ marginTop: '2rem', color: 'rgba(130, 130, 130, 1)' }}>
            <FieldDetailsList
              fieldName="Recursos de Segurança"
              fieldDetails={specimenData.securityFeature}
            />
          </div>
          <Form.Item
            name="advisoryNotes"
            label="Notas das características"
            className="form-item"
          >
            <Input.TextArea disabled />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

SpecimenInformation.propTypes = {
  specimenData: PropTypes.objectOf(PropTypes.objectOf),
};

SpecimenInformation.defaultProps = {
  specimenData: undefined,
};

export default SpecimenInformation;
