import React from 'react';

import PageTitle from 'components/PageTitle';

import identity from 'assets/images/identity.png';

const Page1 = () => (
  <div
    id="info-page"
    className="flex fd-row space-between info-page-img mrg-top-100 mrg-btm-70 pdd-horizon-100"
  >
    <div>
      <img src={identity} alt="ilustração de uma identidade" />
    </div>
    <div className="content content-img mrg-top-40">
      <PageTitle title="O que é o WeCheck?" />
      <div className="mrg-top-40">
        <p>
          É um sistema de avaliação com a proposta de Análises mais eficiente e com alto
          nível de assertividade. <br />
          Nesse primeiro momento temos o fluxo de documentoscopia e Pendente OCR
          acontecendo nele. <br />
          Onde os analistas de forma simples e fácil fazem uma avaliação de um documento e
          apuram sua autenticidade e ou falsidade, assim como podem corrigir algum dado
          que a OCR não leu.
        </p>
      </div>
    </div>
  </div>
);

export default Page1;
