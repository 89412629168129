/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row, Tabs, Carousel, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ArrowRight, ArrowLeft } from '@combateafraude/icons/general';

import ZoomedImage from 'components/Image/ZoomedImage';
import imageError from 'assets/images/imageError.png';
import SpecimenInformation from '../SpecimenInformation';
import DocumentsHeader from '../DocumentsHeader';

import './styles.less';

const { TabPane } = Tabs;

const InternationalDocumentsConfirmation = ({
  recordData,
  selectedDocKey,
  setSelectedDocKey,
  selectedSpecimenDocIndex,
  setSelectedSpecimenDocIndex,
  keyTabAvaliation,
  setKeyTabAvaliation,
  keyTabSpecimen,
  setKeyTabSpecimen,
  isReview,
}) => {
  const carouselRef = useRef();
  const { documentscopy = null, executionInfo = null } = recordData?.record || {};
  const { globalDataDocs = null } = documentscopy || {};
  const currentDocument = globalDataDocs?.[selectedDocKey];
  const { front = null, back = null } = executionInfo?.images || {};
  const containsGlobalImage = front || back;
  const globalImagesKeys =
    containsGlobalImage &&
    Object.keys(executionInfo?.images).filter(
      (key) => (key === 'front' || key === 'back') && executionInfo?.images?.[key]
    );

  const [isSpecimenLoupeActive, setIsSpecimenLoupeActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      carouselRef?.current?.goTo(0);
    }, 200);

    if (keyTabAvaliation === selectedDocKey) {
      return;
    }

    setSelectedDocKey(keyTabAvaliation);

    setSelectedSpecimenDocIndex(0);
    setKeyTabSpecimen('0');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyTabAvaliation]);

  useEffect(() => {
    const numberKeyTab = parseInt(keyTabSpecimen, 10);
    if (keyTabSpecimen === selectedSpecimenDocIndex) {
      return;
    }

    setSelectedSpecimenDocIndex(numberKeyTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyTabSpecimen]);

  const imageTabs = useCallback(
    (height = undefined) => {
      if (!recordData) return <></>;

      const MenuItemsSpecimen = currentDocument?.specimen?.map((_, index) => ({
        key: `${index}`,
        label: `Amostra ${index + 1}`,
      }));

      return containsGlobalImage ? (
        <Row>
          <Col span={12}>
            <Tabs className="tabs-zoomed-images" activeKey={`${keyTabAvaliation}`}>
              {globalImagesKeys.map((imageKey, index) => (
                <TabPane key={imageKey === 'front' ? 'front' : 'back'} forceRender>
                  <ZoomedImage
                    src={executionInfo?.images?.[imageKey]}
                    alt={`Imagem de Análise${
                      globalImagesKeys.length > 1 ? ` ${index + 1}` : ''
                    }`}
                    height={height / 3}
                    initialMaxHeight={300}
                    useImageDefaultHeight
                    label={
                      globalImagesKeys.length > 1
                        ? `Parte ${index + 1} da Avaliação`
                        : 'Avaliação'
                    }
                    showScaleButton
                    setKeyTab={setKeyTabAvaliation}
                    keyTab={keyTabAvaliation}
                    buttonsPosition="right-bottom"
                    className="review-document-zoomed-image-component"
                  />
                  {currentDocument?.specimen && (
                    <div className="specimen-backpanel">
                      <SpecimenInformation
                        specimenData={
                          currentDocument?.specimen?.[selectedSpecimenDocIndex]
                        }
                      />
                    </div>
                  )}
                </TabPane>
              ))}
            </Tabs>
          </Col>
          <Col className="specimen-column" span={12}>
            <Tabs className="tabs-zoomed-images" activeKey={`${keyTabSpecimen}`}>
              {currentDocument?.specimen?.length > 0
                ? currentDocument?.specimen?.map((spec, specIndex) => (
                    <TabPane key={`${specIndex}`} forceRender>
                      <ZoomedImage
                        src={spec.specimen?.[0]?.url}
                        alt={`Imagem de Amostra ${specIndex + 1}`}
                        height={height}
                        initialMaxHeight={300}
                        label="Amostra"
                        showScaleButton
                        setKeyTab={setKeyTabSpecimen}
                        keyTab={keyTabSpecimen}
                        menuItems={MenuItemsSpecimen}
                        buttonsPosition="right-top"
                        className="specimen-document-zoomed-image-component"
                        showLoupeButton
                        isLoupeActive={isSpecimenLoupeActive}
                        setIsLoupeActive={setIsSpecimenLoupeActive}
                        imageRendering="pixelated"
                      />
                    </TabPane>
                  ))
                : currentDocument && (
                    // Specimen images not found
                    <TabPane key="0" forceRender>
                      <ZoomedImage
                        height={height}
                        label="Amostra"
                        showScaleButton
                        setKeyTab={setKeyTabSpecimen}
                        keyTab={keyTabSpecimen}
                        menuItems={MenuItemsSpecimen}
                        buttonsPosition="right-top"
                        className="specimen-document-zoomed-image-component"
                        emptyMessageDescription="O documento não possui imagens de amostra"
                      />
                    </TabPane>
                  )}
            </Tabs>
            {!isSpecimenLoupeActive && currentDocument?.specimen?.length > 0 && (
              <Carousel
                ref={carouselRef}
                className="specimen-documents-image-carousel flex center"
                arrows
                nextArrow={<ArrowRight />}
                prevArrow={<ArrowLeft />}
                slidesToScroll={
                  currentDocument?.specimen?.length >= 4
                    ? 4
                    : currentDocument?.specimen?.length
                }
                slidesToShow={
                  currentDocument?.specimen?.length >= 4
                    ? 4
                    : currentDocument?.specimen?.length
                }
                infinite={false}
              >
                {currentDocument?.specimen?.map((spec, specIndex) => (
                  <div className="specimen-carousel-item flex center">
                    <Tooltip
                      title={spec?.specimenId || 'Amostra'}
                      overlayClassName="specimen-carousel-item-tooltip"
                    >
                      <button
                        className={`${classNames({
                          'specimen-is-selected': keyTabSpecimen === `${specIndex}`,
                        })}`}
                        type="button"
                        onClick={() => setKeyTabSpecimen(`${specIndex}`)}
                      >
                        <img src={spec.specimen?.[0]?.url} alt="Doc name" />
                        <div className="specimen-carousel-item-label">
                          <span>{spec?.specimenId || 'Amostra'}</span>
                        </div>
                      </button>
                    </Tooltip>
                  </div>
                ))}
              </Carousel>
            )}
          </Col>
        </Row>
      ) : (
        <img
          src={imageError}
          alt="Homem confuso"
          className="grayscale-95  mrg-top-80 img-error"
        />
      );
    },
    [
      keyTabAvaliation,
      recordData,
      keyTabSpecimen,
      setKeyTabAvaliation,
      setKeyTabSpecimen,
      executionInfo,
      containsGlobalImage,
      globalImagesKeys,
      currentDocument,
      carouselRef,
      isSpecimenLoupeActive,
      selectedSpecimenDocIndex,
    ]
  );

  return (
    <div id="international-documents-confirmation-component" className="flex-column">
      <DocumentsHeader recordData={recordData} isReview={isReview} />
      <div className="image-tabs">{imageTabs(64)}</div>
    </div>
  );
};

InternationalDocumentsConfirmation.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  selectedDocKey: PropTypes.string.isRequired,
  setSelectedDocKey: PropTypes.func.isRequired,
  selectedSpecimenDocIndex: PropTypes.number.isRequired,
  setSelectedSpecimenDocIndex: PropTypes.func.isRequired,
  keyTabAvaliation: PropTypes.string.isRequired,
  setKeyTabAvaliation: PropTypes.func.isRequired,
  keyTabSpecimen: PropTypes.string.isRequired,
  setKeyTabSpecimen: PropTypes.func.isRequired,
  isReview: PropTypes.bool,
};

InternationalDocumentsConfirmation.defaultProps = {
  isReview: undefined,
};

export default InternationalDocumentsConfirmation;
