import React from 'react';
import { Form, Input } from 'antd';
import InputMask from 'components/Form/InputMask';
import PropTypes from 'prop-types';

const TypeCRLV = ({ isReview }) => (
  <>
    <div>
      <Form.Item name="name" label="Nome completo">
        <Input className="custom-input" disabled={isReview} />
      </Form.Item>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="issueState" label="UF emissora">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="via" label="Via">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="type" label="Espécie/Tipo">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="crlvNumber" label="CRLV">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="renavamCode" label="Renavam">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="rntrc" label="R.N.T.R.C.">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="cpf" label="CPF">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="cnpj" label="CNPJ">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="assignmentYear" label="Exercício">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="issueDate" label="Data de emissão">
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="previousPlateAndState" label="Placa anterior / UF">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="fuel" label="Combustível">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="brandAndModel" label="Marca/Modelo">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="chassis" label="Nº chassi">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="capacityPowerCilynders" label="Cap/Pot/Cil">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="plate" label="Placa">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="manufactureYear" label="Ano de fabricação">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="category" label="Categoria">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="predominantColor" label="Cor">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="modelYear" label="Ano de modelo">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>
  </>
);

TypeCRLV.propTypes = {
  isReview: PropTypes.bool,
};

TypeCRLV.defaultProps = {
  isReview: false,
};

export default TypeCRLV;
