import React, { useState, useEffect } from 'react';
import { Button } from '@combateafraude/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Switch } from 'antd';
import womanWork from 'assets/images/womanWork.png';

import './styles.less';

const QueueEmpty = ({ fetch, loading }) => {
  const [autoRefresh, setAutoRefresh] = useState(false);

  useEffect(() => {
    setAutoRefresh(localStorage.getItem('autoRefresh') === 'true');
  }, []);

  useEffect(() => {
    let timer;
    if (autoRefresh) {
      localStorage.setItem('autoRefresh', 'true');
      timer = setInterval(() => {
        fetch();
      }, 5000);
    } else {
      localStorage.setItem('autoRefresh', 'false');
    }
    return () => {
      clearInterval(timer);
    };
  }, [autoRefresh, fetch]);

  return (
    <div id="queue-empty-component">
      <img
        src={womanWork}
        alt="Mulher trabalhando em um computador"
        className="mrg-btm-30"
      />
      <div className="flex fd-row space-between btn-queue-empty">
        <Button className="btn-custom-primary" onClick={() => fetch()} loading={loading}>
          {loading ? 'Buscando' : 'Buscar'}
        </Button>
        <Link to="/" type="button" loading={loading}>
          Voltar para a página inicial
        </Link>
      </div>
      <div className="mrg-top-20 flex center">
        <Switch
          className="mrg-right-10"
          checked={autoRefresh}
          onChange={setAutoRefresh}
        />
        <p className="no-mrg-btm">Buscar automaticamente</p>
      </div>
    </div>
  );
};

QueueEmpty.propTypes = {
  fetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default QueueEmpty;
