import React, { useState, useEffect } from 'react';
import { Row, Form, Input, Button, Radio } from 'antd';
import PropTypes from 'prop-types';

import RadioButtonOptions from '../RadioButtonOptions';

import './styles.less';

const AdditionalInformation = ({
  recordData,
  formRef,
  modelId,
  setLayoutBackData,
  isReview,
  allRulesCorrect,
  setAllRulesCorrect,
}) => {
  const [rules, setRules] = useState('');
  const { documentscopy } = recordData?.record;
  const [rgMask, setRgMask] = useState(rules?.rgMask);
  const [rgMaskValue, setRgMaskValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.rgMask ? 'correct' : undefined
  );
  const [issueDateMaskValue, setIssueDateMaskValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.issueDateMask ? 'correct' : undefined
  );
  const [birthDateMaskValue, setBirthDateMaskValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.birthDateMask ? 'correct' : undefined
  );
  const [parentsSeparatorValue, setParentsSeparatorValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.parentsSeparator
      ? 'correct'
      : undefined
  );
  const [cpfMaskValue, setCpfMaskValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.cpfMask ? 'correct' : undefined
  );
  const [referencePatternValue, setReferencePatternValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.referencePattern
      ? 'correct'
      : undefined
  );
  const [birthPlaceValue, setBirthPlaceValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.birthPlace ? 'correct' : undefined
  );
  const [graphicValue, setGraphicValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.graphic ? 'correct' : undefined
  );
  const [renachValue, setRenachValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.renach ? 'correct' : undefined
  );
  const [civilEvolutionValue, setCivilEvolutionValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.civilEvolution
      ? 'correct'
      : undefined
  );
  const [viaValue, setViaValue] = useState(
    documentscopy?.rulesValidation?.textValidations?.via ? 'correct' : undefined
  );

  const [issueDateMask, setIssueDateMask] = useState(rules?.issueDateMask);
  const [birthDateMask, setBirthDateMask] = useState(rules?.birthDateMask);
  const [parentsNameSeparator, setParentsNameSeparator] = useState(
    rules?.parentsSeparator
  );
  const [cpfMask, setCpfMask] = useState(rules?.cpfMask);
  const [referencePattern, setReferencePattern] = useState(rules?.referencePattern);
  const [birthPlace, setBirthPlace] = useState(rules?.birthPlace);
  const [graphic, setGraphic] = useState(rules?.graphic);
  const [renach, setRenach] = useState(rules?.renach);
  const [civilEvolution, setCivilEvolution] = useState(rules?.civilEvolution);
  const [via, setVia] = useState(rules?.via);

  useEffect(() => {
    let isMainRule;
    let otherRulesCheck;
    if (recordData?.record?.queue?.solutionType === 'cnh') {
      isMainRule = documentscopy?.rulesValidation?.rule?.model === modelId?.charAt(0);
      otherRulesCheck = (r) =>
        Object.prototype.hasOwnProperty.call(r?.referenceImages, `${modelId}`);
    } else {
      isMainRule = documentscopy?.rulesValidation?.rule._id === modelId;
      otherRulesCheck = (r) => r._id === modelId;
    }

    if (isMainRule) {
      setRules(documentscopy?.rulesValidation?.rule);
    } else {
      setRules(
        documentscopy?.rulesValidation?.otherRules?.find((r) => otherRulesCheck(r))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  useEffect(() => {
    if (allRulesCorrect.layoutBack) {
      formRef.setFieldsValue({
        rgMaskValue: 'correct',
        issueDateMaskValue: 'correct',
        birthDateMaskValue: 'correct',
        parentsSeparatorValue: 'correct',
        cpfMaskValue: 'correct',
        referencePatternValue: 'correct',
        birthPlaceValue: 'correct',
        graphicValue: 'correct',
        renachValue: 'correct',
        civilEvolutionValue: 'correct',
        viaValue: 'correct',
      });

      setRgMaskValue('correct');
      setIssueDateMaskValue('correct');
      setBirthDateMaskValue('correct');
      setParentsSeparatorValue('correct');
      setCpfMaskValue('correct');
      setReferencePatternValue('correct');
      setBirthPlaceValue('correct');
      setGraphicValue('correct');
      setRenachValue('correct');
      setCivilEvolutionValue('correct');
      setViaValue('correct');
    }
  }, [rules, allRulesCorrect, formRef]);

  useEffect(() => {
    if (!isReview) {
      setRgMask(rules?.rgMask);
      setIssueDateMask(rules?.issueDateMask);
      setBirthDateMask(rules?.birthDateMask);
      setParentsNameSeparator(rules?.parentsNameSeparator);
      setCpfMask(rules?.cpfMask);
      setReferencePattern(rules?.referencePattern);
      setBirthPlace(rules?.birthPlace);
      setGraphic(rules?.graphic);
      setRenach(rules?.renach);
      setCivilEvolution(rules?.civilEvolution);
      setVia(rules?.via);
    }
  }, [formRef, isReview, rules]);

  useEffect(() => {
    if (
      isReview &&
      documentscopy?.review &&
      !!Object.keys(documentscopy?.review?.additionalValidations?.layoutBack || {}).length
    ) {
      const { layoutBack } = documentscopy?.review?.additionalValidations;

      if (layoutBack.rgMask) {
        formRef?.setFieldsValue({
          rgMaskValue: layoutBack.rgMask.selected,
          rgMask: layoutBack.rgMask.value,
        });
      }

      if (layoutBack.issueDateMask) {
        formRef?.setFieldsValue({
          issueDateMaskValue: layoutBack.issueDateMask.selected,
          issueDateMask: layoutBack.issueDateMask.value,
        });
      }

      if (layoutBack.birthDateMask) {
        formRef?.setFieldsValue({
          birthDateMaskValue: layoutBack.birthDateMask.selected,
          birthDateMask: layoutBack.birthDateMask.value,
        });
      }

      if (layoutBack.parentsNameSeparator) {
        formRef?.setFieldsValue({
          parentsSeparatorValue: layoutBack.parentsNameSeparator.selected,
          parentsNameSeparator: layoutBack.parentsNameSeparator.value,
        });
      }

      if (layoutBack.cpfMask) {
        formRef?.setFieldsValue({
          cpfMaskValue: layoutBack.cpfMask.selected,
          cpfMask: layoutBack.cpfMask.value,
        });
      }

      if (layoutBack.referencePattern) {
        formRef?.setFieldsValue({
          referencePatternValue: layoutBack.referencePattern.selected,
          referencePattern: layoutBack.referencePattern.value,
        });
      }

      if (layoutBack.birthPlace) {
        formRef?.setFieldsValue({
          birthPlaceValue: layoutBack.birthPlace.selected,
          birthPlace: layoutBack.birthPlace.value,
        });
      }

      if (layoutBack.graphic) {
        formRef?.setFieldsValue({
          graphicValue: layoutBack.graphic.selected,
          graphic: layoutBack.graphic.value,
        });
      }

      if (layoutBack.renach) {
        formRef?.setFieldsValue({
          renachValue: layoutBack.renach.selected,
          renach: layoutBack.renach.value,
        });
      }

      if (layoutBack.civilEvolution) {
        formRef?.setFieldsValue({
          civilEvolutionValue: layoutBack.civilEvolution.selected,
          civilEvolution: layoutBack.civilEvolution.value,
        });
      }
      if (layoutBack.via) {
        formRef?.setFieldsValue({
          viaValue: layoutBack.via.selected,
          via: layoutBack.via.value,
        });
      }
    } else {
      formRef?.setFieldsValue({
        rgMask: rules?.rgMask,
        issueDateMask: rules?.issueDateMask,
        birthDateMask: rules?.birthDateMask,
        parentsNameSeparator: rules?.parentsNameSeparator,
        cpfMask: rules?.cpfMask,
        referencePattern: rules?.referencePattern,
        birthPlace: rules?.birthPlace,
        graphic: rules?.graphic,
        renach: rules?.renach,
        civilEvolution: rules?.civilEvolution,
        via: rules?.via,
      });
    }
  }, [formRef, isReview, documentscopy, rules]);

  useEffect(() => {
    setLayoutBackData({
      rgMask: {
        value: rgMask,
        selected: rgMaskValue?.target?.value || rgMaskValue,
      },
      issueDateMask: {
        value: issueDateMask,
        selected: issueDateMaskValue?.target?.value || issueDateMaskValue,
      },
      birthDateMask: {
        value: birthDateMask,
        selected: birthDateMaskValue?.target?.value || birthDateMaskValue,
      },
      parentsNameSeparator: {
        value: parentsNameSeparator,
        selected: parentsSeparatorValue?.target?.value || parentsSeparatorValue,
      },
      cpfMask: {
        value: cpfMask,
        selected: cpfMaskValue?.target?.value || cpfMaskValue,
      },
      referencePattern: {
        value: referencePattern,
        selected: referencePatternValue?.target?.value || referencePatternValue,
      },
      birthPlace: {
        value: birthPlace,
        selected: birthPlaceValue?.target?.value || birthPlaceValue,
      },
      graphic: {
        value: graphic,
        selected: graphicValue?.target?.value || graphicValue,
      },
      renach: {
        value: renach,
        selected: renachValue?.target?.value || renachValue,
      },
      civilEvolution: {
        value: civilEvolution,
        selected: civilEvolutionValue?.target?.value || civilEvolutionValue,
      },
      via: {
        value: via,
        selected: viaValue?.target?.value || viaValue,
      },
    });
  }, [
    rgMask,
    birthDateMask,
    birthDateMaskValue,
    birthPlace,
    birthPlaceValue,
    cpfMask,
    cpfMaskValue,
    issueDateMask,
    issueDateMaskValue,
    parentsNameSeparator,
    parentsSeparatorValue,
    referencePattern,
    referencePatternValue,
    rgMaskValue,
    setLayoutBackData,
    graphic,
    graphicValue,
    renach,
    renachValue,
    civilEvolution,
    civilEvolutionValue,
    via,
    viaValue,
  ]);

  function selectAll() {
    setAllRulesCorrect((prevState) => ({
      ...prevState,
      layoutBack: true,
    }));
  }

  return (
    <div id="additional-info-component" justify="center">
      <Form
        form={formRef}
        initialValues={{
          rgMaskValue,
          issueDateMaskValue,
          birthDateMaskValue,
          parentsSeparatorValue,
          cpfMaskValue,
          referencePatternValue,
          birthPlaceValue,
          rgMask,
          issueDateMask,
          birthDateMask,
          parentsNameSeparator,
          cpfMask,
          referencePattern,
          birthPlace,
          graphic,
          graphicValue,
          renach,
          renachValue,
          civilEvolution,
          civilEvolutionValue,
          via,
          viaValue,
        }}
      >
        {rules?.model && (
          <Row>
            <span className="model">{`Modelo Escolhido: ${rules?.model || ''}`}</span>
          </Row>
        )}
        {rules?.rgMask && (
          <div>
            <div>
              <span className="label">Máscara RG</span>
              <Form.Item name="rgMask" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setRgMask(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item
                name="rgMaskValue"
                valuePropName="value"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  optionType="button"
                  onChange={(e) => setRgMaskValue(e.target.value)}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.issueDateMask && (
          <div>
            <div>
              <span className="label">Máscara da Expedição</span>
              <Form.Item name="issueDateMask" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setIssueDateMask(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item
                name="issueDateMaskValue"
                className="mrg-top-5"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  optionType="button"
                  onChange={setIssueDateMaskValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.birthDateMask && (
          <div>
            <div>
              <span className="label">Máscara data de nascimento</span>
              <Form.Item name="birthDateMask" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setBirthDateMask(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="birthDateMaskValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setBirthDateMaskValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.parentsNameSeparator && (
          <div>
            <div>
              <span className="label">Separador filiação</span>
              <Form.Item name="parentsNameSeparator" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setParentsNameSeparator(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="parentsSeparatorValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setParentsSeparatorValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.cpfMask && (
          <div>
            <div>
              <span className="label">Máscara de CPF</span>
              <Form.Item name="cpfMask" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setCpfMask(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="cpfMaskValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setCpfMaskValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.referencePattern && (
          <div>
            <div>
              <span className="label">Origem do documento</span>
              <Form.Item name="referencePattern" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setReferencePattern(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="referencePatternValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setReferencePatternValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.birthPlace && (
          <div>
            <div>
              <span className="label">Naturalidade</span>
              <Form.Item name="birthPlace" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setBirthPlace(e.target.value)}
                  disabled={isReview}
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="birthPlaceValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setBirthPlaceValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.graphic && (
          <div>
            <div>
              <span className="label">Graphic</span>
              <Form.Item name="graphic" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setGraphic(e.target.value)}
                  disabled
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="graphicValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setGraphicValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}

        {rules?.renach && (
          <div>
            <div>
              <span className="label">Renach</span>
              <Form.Item name="renach" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setRenach(e.target.value)}
                  disabled
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="renachValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setRenachValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}
        {rules?.civilEvolution && (
          <div>
            <div>
              <span className="label">Evolução Civil</span>
              <Form.Item name="civilEvolution" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setCivilEvolution(e.target.value)}
                  disabled
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="civilEvolutionValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setCivilEvolutionValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}
        {rules?.via && (
          <div>
            <div>
              <span className="label">Via</span>
              <Form.Item name="via" valuePropName="value">
                <Input.TextArea
                  className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                  allowClear
                  autoSize
                  onChange={(e) => setVia(e.target.value)}
                  disabled
                />
              </Form.Item>
            </div>
            <div className="row-buttons">
              <Form.Item name="viaValue" rules={[{ required: true }]}>
                <Radio.Group
                  optionType="button"
                  onChange={setViaValue}
                  disabled={isReview}
                >
                  <RadioButtonOptions />
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
      {!isReview && (
        <div className="footer">
          <Button type="link" primary onClick={() => selectAll()}>
            Todas as regras estão corretas
          </Button>
        </div>
      )}
    </div>
  );
};

AdditionalInformation.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  setLayoutBackData: PropTypes.func,
  modelId: PropTypes.string,
  isReview: PropTypes.bool,
  setAllRulesCorrect: PropTypes.func,
  allRulesCorrect: PropTypes.objectOf(PropTypes.objectOf),
};

AdditionalInformation.defaultProps = {
  modelId: null,
  isReview: false,
  setLayoutBackData: () => {},
  allRulesCorrect: {},
  setAllRulesCorrect: () => {},
};

export default AdditionalInformation;
