import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react';
import { Col, Row, Form } from 'antd';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { Duplicate, Clock, ArrowDown, Home } from '@combateafraude/icons/general';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useBeforeunload } from 'react-beforeunload';
import uppercaseObject from 'utils/uppercaseObject';

import Loader from 'components/Loader';
import Button from 'components/Button';
import showToastMessage from 'components/ToastMessage';

import { useAuth } from 'hooks/auth';
import { useRecord } from 'hooks/record';
import { useCountdown } from 'hooks/countdown';
import { fetchApi } from 'services/api';
import { useFetch } from 'services/hooks';

import alertImg from 'assets/images/alert-img.svg';

import Modal from 'components/Modal';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import isValidJson from '../utils/isValidJson';
import DocumentsConfirmation from '../pages/components/DocumentsConfirmation';
import AvaliationError from '../pages/AvaliationError';
import Countdown from '../pages/components/Countdown';
import AvaliationPendingOcr from '../pages/AvaliationPendingOCR';

import Wrapper from '../wrapper';

import './styles.less';

const RecordDetailsEvaluationPendingOcr = ({ id }) => {
  const { recordId } = useParams();
  const { loggedUser } = useAuth();
  const { recordData, loadingRecord, loadData, error, clearRecord } = useRecord();
  const history = useHistory();

  const { openModal: initialPage, Modal: ModalInfoInitialPage } = Modal({
    img: alertImg,
    title: 'Termine de avaliar o documento para voltar para a página inicial!',
    okText: 'Ok',
    okType: 'primary',
  });

  const { openModal: reproveModified, Modal: ModalReproveModified } = Modal({
    img: alertImg,
    title:
      'Você selecionou motivos de reprovação, confirme no botão Reprovar abaixo do seletor',
    okText: 'Ok',
    okType: 'primary',
  });

  const { time, timeout, setTimeLast, fiveMinutes } = useCountdown();

  const [form] = Form.useForm();

  const { put, loading } = useFetch();
  const [modelId, setModelId] = useState();
  const [reproveChanged, setReproveChanged] = useState(false);

  const [avaliationFinished, setAvaliationFinished] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('/manual-review-wizard');
  const [viewMore, setViewMore] = useState(true);

  const bodyRecordRef = useRef();

  const skipEvaluation = useCallback(async () => {
    const body = {
      user: {
        _id: loggedUser._id,
        name: loggedUser.name,
        username: loggedUser.username,
      },
      timeout,
      time,
      skippedAt: new Date(),
    };

    if (!avaliationFinished) {
      await fetchApi(`/queuing/${recordData?.record?._id}`, 'PUT', body);
    }
  }, [avaliationFinished, loggedUser, recordData, time, timeout]);

  useBeforeunload(async () => {
    await skipEvaluation();

    return undefined;
  });

  const backHome = useCallback(async () => {
    initialPage();

    setRedirectUrl('/home');
  }, [initialPage]);

  const handleSubmit = useCallback(async () => {
    if (reproveChanged) {
      reproveModified();
    } else {
      try {
        await form.validateFields();
      } catch {
        showToastMessage({
          type: 'error',
          text: 'Há campos obrigatórios que devem ser preenchidos',
        });

        return;
      }

      try {
        const values = form.getFieldsValue();

        delete values.parameters;

        let formattedValues = values;
        let data = {};

        const { cnhIssueDate, issueDate } = values;

        if (cnhIssueDate && !issueDate) {
          formattedValues = { ...formattedValues, issueDate: cnhIssueDate };
        }

        const validValues = JSON.stringify(formattedValues, (_, value) =>
          value === 'UNDEFINED' || value === undefined ? '' : value
        );

        if (isValidJson(validValues)) {
          data = uppercaseObject(JSON.parse(validValues));
        } else {
          data = uppercaseObject(formattedValues);
        }

        const payload = {
          avaliationInfo: {
            evaluated: true,
            user: {
              _id: loggedUser._id,
              name: loggedUser.name,
              username: loggedUser.username,
            },
            time,
            reviewAt: new Date(),
          },
          pendingOcr: {
            approved: true,
            fraud: false,
            data,
          },
          type: recordData?.record?.type,
        };

        try {
          await put({
            url: `/records/${recordData?.record._id}`,
            payload,
          });

          setAvaliationFinished(true);

          history.push(redirectUrl);
          window.location.reload();
        } catch (e) {
          showToastMessage({
            type: 'error',
            text: 'Erro ao registrar informações.',
          });
          setAvaliationFinished(true);
          history.replace('/home');
        }
      } catch (e) {
        showToastMessage({
          type: 'error',
          text: 'Houve um problema ao atualizar os dados.',
        });
      }
    }
  }, [
    reproveChanged,
    reproveModified,
    loggedUser,
    time,
    recordData,
    form,
    put,
    history,
    redirectUrl,
  ]);

  const avaliationPage = useMemo(() => {
    if (recordData) {
      const { record } = recordData;

      const {
        front = null,
        back = null,
        originalFront = null,
        originalBack = null,
        originalFrontCompressed = null,
        originalBackCompressed = null,
      } = recordData?.record?.executionInfo?.images;
      const containsImage =
        originalFrontCompressed ||
        originalBackCompressed ||
        front ||
        back ||
        originalFront ||
        originalBack;

      return (
        <Wrapper>
          {ModalInfoInitialPage}
          {ModalReproveModified}
          <Row>
            <Col className="pending-ocr record-details-cards" ref={bodyRecordRef}>
              <div className="header-record-details flex">
                <div className="flex center fd-row gx-w-100 space-between details">
                  <div className="flex center">
                    <Button
                      id="back-home-button"
                      type="link"
                      onClick={backHome}
                      className={`${classNames({
                        'back-home-enabled': redirectUrl === '/home',
                      })} btn-icon`}
                    >
                      <Home />
                    </Button>
                    <span>Id: {record._id}</span>
                    <CopyToClipboardButton
                      text={`${process.env.REACT_APP_BASE_URL_WECHECK_WEB}/${record.type}/records/details/record/${record._id}`}
                      onCopyText="Link de detalhes da execução copiado!"
                      icon={<Duplicate width={12} height={12} />}
                      size="small"
                      className="copy-record-details-link-button mrg-left-5 flex center"
                    />
                  </div>

                  <div className="flex center">
                    <div className="flex center">
                      <Clock width={24} height={24} />
                    </div>
                    <Countdown />
                  </div>
                </div>
              </div>

              <div
                className={`${classNames({
                  'pdd-btm-btm': !containsImage,
                })} pdd-btm-90 body-record-details body-record-details-evaluation`}
              >
                {!containsImage && (
                  <AvaliationError
                    recordData={recordData}
                    reproveChanged={reproveChanged}
                    setReproveChanged={setReproveChanged}
                    setAvaliationFinished={setAvaliationFinished}
                    redirectUrl={redirectUrl}
                  />
                )}
                {containsImage && (
                  <AvaliationPendingOcr
                    recordData={recordData}
                    setAvaliationFinished={setAvaliationFinished}
                    redirectUrl={redirectUrl}
                    formRef={form}
                  />
                )}
              </div>

              <div className="footer-record-details">
                <div>
                  {viewMore && (
                    <div className="view-more-details-component">
                      <ArrowDown width={24} height={24} />
                    </div>
                  )}
                  {containsImage && (
                    <div className="btn-finished-true footer-buttons">
                      <div className="flex fd-row space-between center">
                        {containsImage &&
                          record?.queue?.solutionType !== 'uf_year_is_empty' && (
                            <Button
                              onClick={handleSubmit}
                              loading={loading}
                              className="btn-finished btn-custom-primary"
                            >
                              Finalizar
                            </Button>
                          )}
                        <a href="#reprove-component" className="btn-reprove">
                          Reprovar
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>

            <Col>
              <DocumentsConfirmation
                recordData={recordData}
                setModelId={setModelId}
                modelId={modelId}
              />
            </Col>
          </Row>
        </Wrapper>
      );
    }

    return <></>;
  }, [
    recordData,
    ModalInfoInitialPage,
    ModalReproveModified,
    reproveChanged,
    redirectUrl,
    modelId,
    form,
    viewMore,
    handleSubmit,
    loading,
    backHome,
  ]);

  useEffect(() => {
    const bodyRecord = bodyRecordRef?.current;

    if (bodyRecord) {
      if (bodyRecord.scrollHeight > bodyRecord.clientHeight) {
        bodyRecord.addEventListener('scroll', () => {
          const { scrollTop, scrollHeight, clientHeight } = bodyRecordRef.current;
          if (scrollTop + clientHeight === scrollHeight) {
            setViewMore(false);
          } else {
            setViewMore(true);
          }
        });
      } else {
        setViewMore(false);
      }
    }
  }, [recordData]);

  useEffect(() => {
    const idData = id || recordId;

    loadData({ recordId: idData, clearData: true });
  }, [loadData, id, recordId, clearRecord]);

  useEffect(() => {
    setTimeLast(fiveMinutes);
  }, [fiveMinutes, setTimeLast]);

  const hasRecordError = useMemo(() => {
    const formattedErrorStatusCode = error?.statusCode;

    return ['500', '400'].includes(formattedErrorStatusCode);
  }, [error]);

  useEffect(() => {
    if (hasRecordError) {
      showToastMessage({
        type: 'error',
        text: `${error.data.message}`,
      });
    }
  }, [error, hasRecordError]);

  return (
    <div id="record-details-component">
      {(loadingRecord && !recordData) || loading || avaliationFinished ? (
        <div id="page-loading">
          <Loader size="large" />
        </div>
      ) : (
        <>
          {hasRecordError ? (
            <>
              <Redirect to="/home" />
              {clearRecord({ shouldClearError: true })}
            </>
          ) : (
            <>
              {recordData && (
                <div className="gx-flex-row">
                  <Form form={form} autoComplete="off">
                    {avaliationPage}
                  </Form>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

RecordDetailsEvaluationPendingOcr.propTypes = {
  id: PropTypes.string,
};

RecordDetailsEvaluationPendingOcr.defaultProps = {
  id: null,
};

export default RecordDetailsEvaluationPendingOcr;
