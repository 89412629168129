import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ProcessingLine,
  ReguaDocs,
  DocFilter,
  LoupeDocumentSearch,
} from '@combateafraude/icons/general';

import FAButton from 'components/Button/FloatingActionButton';
import getDegreesFromString from 'utils/getDegreesFromString';

import './styles.less';

const ZoomedControls = ({
  setStyle,
  setScaleVTStyle,
  setScaleHLStyle,
  showScale,
  setShowScale,
  buttonsPosition,
  showScaleButton,
  showLoupeButton,
  isLoupeActive,
  setIsLoupeActive,
}) => {
  const [filterProperty, setFilterProperty] = useState('invert(1)');

  const rotate = useCallback(
    (direction) => {
      setStyle((state) => {
        const degrees = getDegreesFromString(state?.transform);
        let newDegrees = degrees === 270 ? 0 : degrees + 90;

        if (direction === 'counter-clockwise') {
          newDegrees = degrees === 0 ? 270 : degrees - 90;
        }

        const transform = `rotate(${newDegrees}deg)`;

        return {
          ...state,
          transform,
        };
      });
    },
    [setStyle]
  );

  const invert = useCallback(
    (filter) => {
      setStyle((state) => ({
        ...state,
        filter,
      }));
    },
    [setStyle]
  );

  const addScale = useCallback(
    (scale) => {
      const containers = document.querySelectorAll('.zoomed-image-component');

      containers?.forEach((container) => {
        function addStyle(e, rect) {
          setScaleVTStyle({
            visibility: 'visible',
            left: `${e.pageX - rect.left}px`,
          });
          setScaleHLStyle({
            visibility: 'visible',
            top: `${e.pageY - rect.top}px`,
          });
        }

        if (scale) {
          container?.addEventListener('mouseover', () => {
            const rect = container.getBoundingClientRect();

            container?.addEventListener('mousemove', (e) => addStyle(e, rect), false);
          });
          container?.addEventListener('mouseout', () => {
            setScaleVTStyle({ visibility: 'hidden' });
            setScaleHLStyle({ visibility: ' hidden' });

            container?.removeEventListener('mousemove', (e) => addStyle(e));
          });
        } else {
          container?.removeEventListener('mousemove', (e) => addStyle(e), false);
        }
      });
    },
    [setScaleVTStyle, setScaleHLStyle]
  );

  return (
    <div className="zoomed-controls">
      <FAButton
        className={`${classNames({
          active: !filterProperty,
        })} ${buttonsPosition}`}
        onClick={() => {
          invert(filterProperty);
          setFilterProperty(filterProperty === '' ? 'invert(1)' : '');
        }}
      >
        <DocFilter />
      </FAButton>
      <FAButton className={`${buttonsPosition} num-2`} onClick={rotate}>
        <ProcessingLine />
      </FAButton>
      {showScaleButton && (
        <FAButton
          className={`${classNames({
            active: !showScale,
          })} ${buttonsPosition} num-3`}
          onClick={() => {
            addScale(showScale);
            setShowScale(!showScale);
          }}
        >
          <ReguaDocs />
        </FAButton>
      )}

      {showLoupeButton && (
        <FAButton
          className={`${classNames({
            active: isLoupeActive,
          })} ${buttonsPosition} num-4`}
          onClick={() => {
            setIsLoupeActive(!isLoupeActive);
          }}
        >
          <LoupeDocumentSearch />
        </FAButton>
      )}
    </div>
  );
};

ZoomedControls.propTypes = {
  setStyle: PropTypes.func.isRequired,
  setScaleVTStyle: PropTypes.func.isRequired,
  setScaleHLStyle: PropTypes.func.isRequired,
  showScale: PropTypes.bool,
  setShowScale: PropTypes.func.isRequired,
  buttonsPosition: PropTypes.string.isRequired,
  showScaleButton: PropTypes.bool,
  showLoupeButton: PropTypes.bool,
  isLoupeActive: PropTypes.bool,
  setIsLoupeActive: PropTypes.func,
};

ZoomedControls.defaultProps = {
  showScale: false,
  showScaleButton: false,
  showLoupeButton: false,
  isLoupeActive: false,
  setIsLoupeActive: undefined,
};

export default ZoomedControls;
