import React from 'react';
import { Form, Input } from 'antd';
import InputMask from 'components/Form/InputMask';
import PropTypes from 'prop-types';

const TypeCNH = ({ isReview }) => (
  <>
    <div className="custom-inputs">
      <div>
        <Form.Item name="name" label="Nome completo (opcional)">
          <Input className="custom-input" disabled={isReview} style={{ width: 450 }} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="cpf" label="CPF">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="motherName" label="Nome da mãe (opcional)">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="fatherName" label="Nome do pai (opcional)">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="expirationDate" label="Validade">
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item
          name="registrationNumber"
          label="Nº do registro"
          rules={[{ required: true }]}
        >
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="firstDriverLicenseDate" label="Primeira habilitação">
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="securityNumber" label="Nº de segurança">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item
          name="cnhIssueDate"
          label="Data de emissão"
          rules={[{ required: true }]}
        >
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item
          name="cnhPlaceOfIssue"
          label="Local de emissão"
          rules={[{ required: true }]}
        >
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory" span={12}>
        <Form.Item
          name="cnhIssueState"
          label="UF de emissão"
          rules={[{ required: true }]}
        >
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="birthDate" label="Data de nascimento">
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="mirrorNumber" label="Nº do espelho">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="renachNumber" label="Nº do renach">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="notes" label="Observações">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item name="rg" label="RG">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item
          name="rgIssuingAuthority"
          label="Órgão emissor do RG"
          rules={[{ required: true }]}
        >
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="category" label="Categoria da CNH" rules={[{ required: true }]}>
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>
  </>
);

TypeCNH.propTypes = {
  isReview: PropTypes.bool,
};

TypeCNH.defaultProps = {
  isReview: false,
};

export default TypeCNH;
