import React from 'react';

import PageTitle from 'components/PageTitle';

const Page3 = () => (
  <div id="info-page" className="info-page mrg-top-70 mrg-btm-95 pdd-horizon-170">
    <div className="content">
      <PageTitle title="Mas como é feito o Cálculo?" />
      <div className="mrg-top-20">
        <p>
          A ordem de maior relevância é calculada com base no valor de prioridade + o
          tempo que está na fila.
        </p>
        <p>
          Todos os registros que recebemos hoje são inseridos na fila com prioridade
          inicial 1, para cada segundo que esse registro fica na fila é adicionado +1 a
          prioridade.
        </p>
        <p>
          <strong>Exemplo:</strong> <br />O registro está 3min na fila, sua prioridade
          portanto é 1 + (3 * 60) = 181.
        </p>
      </div>
    </div>
  </div>
);

export default Page3;
