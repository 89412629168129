import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import * as Icons from '@combateafraude/icons/general';

const SearchInput = ({ placeholder, icon, name, disabled, className, ...rest }) => (
  <Form.Item {...rest} name={name} className={`no-mrg-btm ${className}`}>
    <Input
      disabled={disabled}
      className="custom-search-input"
      autoComplete="off"
      placeholder={placeholder}
      prefix={Object.keys(Icons || {}).includes(icon) && createElement(Icons[icon])}
    />
  </Form.Item>
);

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: 'Digite aqui para filtrar...',
  icon: 'Search',
  name: '_search',
  disabled: false,
  className: '',
};

export default SearchInput;
