import api from 'services/api';
import showToastMessage from 'components/ToastMessage';
import { useAuth } from '../hooks/auth';

const HttpInterceptor = () => {
  const { setLoggedUser, refreshUserToken, logOut } = useAuth();

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error?.response?.config?.url ===
        `${process.env.REACT_APP_BASE_URL_WECHECK_API}/auth/refresh`
      ) {
        return null;
      }

      if (error.response) {
        if (error.response.status === 403) {
          if (
            error.response.data?.message !== 'Usuário precisa estar vinculado a um grupo!'
          ) {
            const userData = error?.response?.data.docs;
            setLoggedUser(userData);
          }
        }

        if (error.response.status === 401) {
          const userData = error?.response?.data.docs;
          if (userData) {
            setLoggedUser(userData);
          } else {
            try {
              const res = await refreshUserToken();

              const { idToken } = res || {};
              error.config.headers.Authorization = idToken;

              return api.request(error.config);
            } catch (err) {
              showToastMessage({
                type: 'error',
                text: 'Sessão expirada. Faça login novamente',
              });
              logOut();

              return null;
            }
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return null;
};

export default HttpInterceptor;
