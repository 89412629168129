import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const QueueList = ({ tableContent }) => (
  <div id="queue-list-component" className="gx-w-100">
    {tableContent}
  </div>
);

QueueList.propTypes = {
  tableContent: PropTypes.element.isRequired,
};

export default QueueList;
