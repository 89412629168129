import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

const ExtraInformation = ({ recordData, formRef, modelId }) => {
  const [rules, setRules] = useState('');
  const { documentscopy } = recordData?.record;

  const [rightThumb] = useState(rules?.rightThumb);
  const [plastifyForbidden] = useState(rules?.plastifyForbidden);
  const [graphicFront] = useState(rules?.graphicFront);
  const [graphicBack] = useState(rules?.graphicBack);
  const [graphicExtra] = useState(rules?.graphicExtra);

  useEffect(() => {
    const isMainRule = documentscopy?.rulesValidation?.rule._id === modelId;
    const otherRulesCheck = (r) => r._id === modelId;

    if (isMainRule) {
      setRules(documentscopy?.rulesValidation?.rule);
    } else {
      setRules(
        documentscopy?.rulesValidation?.otherRules?.find((r) => otherRulesCheck(r))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  useEffect(() => {
    formRef?.setFieldsValue({
      plastifyForbidden: rules?.plastifyForbidden,
      rightThumb: rules?.rightThumb,
      graphicFront: rules?.graphicFront,
      graphicBack: rules?.graphicBack,
      graphicExtra: rules?.graphicExtra,
    });
  }, [formRef, documentscopy, rules]);

  return (
    <div id="additional-info-component" justify="center">
      <Form
        form={formRef}
        initialValues={{
          plastifyForbidden,
          rightThumb,
          graphicFront,
          graphicBack,
          graphicExtra,
        }}
      >
        {rules?.rightThumb && (
          <div>
            <span className="label">Polegar direito</span>
            <Form.Item name="rightThumb" valuePropName="value">
              <Input.TextArea
                className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                disabled
                autoSize
              />
            </Form.Item>
          </div>
        )}
        {rules?.plastifyForbidden && (
          <div>
            <span className="label">Proibido plastificar</span>
            <Form.Item name="plastifyForbidden" valuePropName="value">
              <Input.TextArea
                className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                disabled
                autoSize
              />
            </Form.Item>
          </div>
        )}
        {rules?.graphicFront && (
          <div>
            <span className="label">Graphic frontal</span>
            <Form.Item name="graphicFront" valuePropName="value">
              <Input.TextArea
                className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                disabled
                autoSize
              />
            </Form.Item>
          </div>
        )}
        {rules?.graphicBack && (
          <div>
            <span className="label">Graphic traseiro</span>
            <Form.Item name="graphicBack" valuePropName="value">
              <Input.TextArea
                className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                disabled
                autoSize
              />
            </Form.Item>
          </div>
        )}
        {rules?.graphicExtra && (
          <div>
            <span className="label">Graphic extra</span>
            <Form.Item name="graphicExtra" valuePropName="value">
              <Input.TextArea
                className="text-dark ant-input-text resize-none pdd-vertical-5 pdd-horizon-10"
                disabled
                autoSize
              />
            </Form.Item>
          </div>
        )}
        {!(
          rules?.graphicExtra ||
          rules?.graphicBack ||
          rules?.graphicFront ||
          rules?.plastifyForbidden ||
          rules?.rightThumb
        ) && (
          <p className="card-title max-lines-2 gx-w-100 text-bold">
            Nenhuma informação extra referente ao documento.
          </p>
        )}
      </Form>
    </div>
  );
};

ExtraInformation.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  modelId: PropTypes.string,
};

ExtraInformation.defaultProps = {
  modelId: null,
};

export default ExtraInformation;
