import React from 'react';
import PropTypes from 'prop-types';

import CountryFlagAvatar from '../CountryFlagAvatar';

import './styles.less';

const DocumentIdentification = ({ title, countryCode, countryFlag }) => (
  <div id="document-identification-component" className="flex center space-between">
    <div className="document-identification-title flex center">
      <span>{title}</span>
    </div>
    <div className="country-info flex center">
      <CountryFlagAvatar src={countryFlag} size={24} />
      {countryFlag && <span className="country-code">{countryCode}</span>}
    </div>
  </div>
);

DocumentIdentification.propTypes = {
  title: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  countryFlag: PropTypes.string,
};

DocumentIdentification.defaultProps = {
  countryCode: undefined,
  countryFlag: undefined,
};

export default DocumentIdentification;
