import React, { useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import _ from 'lodash';
import { PageTitle, Button } from '@combateafraude/react';
import { CircleTrue, Plus } from '@combateafraude/icons/general';
import { useQueryParams, StringParam } from 'use-query-params';

import useTable from 'components/List/hooks/useTable';
import SearchInput from 'components/Form/SearchInput';
import SortIcon from 'components/SortIcon';

import { toFormatDate } from 'utils/formatters';

import PermissionWrapper from 'routes/PermissionWrapper';

import ActionsList from '../OperationsGroupsActions/OperationsGroupsActionsList';

import Wrapper from '../wrapper';

import './styles.less';

const OperationsGroups = () => {
  const history = useHistory();
  const [query] = useQueryParams({
    search: StringParam,
  });

  const refreshListRef = useRef(() => {});

  const columns = [
    {
      key: 'name',
      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(({ column }) => column.key === 'name');
        return (
          <div className="flex row">
            <span className="column-title">Nome</span>
            <SortIcon order={sortedColumn?.order} />
          </div>
        );
      },
      dataIndex: 'name',
      sorter: (a, b) => a.name?.localeCompare(b.name),
      render: (__, record) => (
        <div className="flex align-center text-dark">
          <div className="flex align-center text-dark">{record?.name || '-'}</div>
        </div>
      ),
    },
    {
      key: 'createdAt',
      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === 'createdAt'
        );
        return (
          <div className="flex row">
            <span className="column-title">Data de criação</span>
            <SortIcon order={sortedColumn?.order} />
          </div>
        );
      },
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const aDate = new Date(a.createdAt).getTime();
        const bDate = new Date(b.createdAt).getTime();

        return aDate - bDate;
      },
      render: (__, record) => (
        <div className="flex align-center text-dark">
          {toFormatDate(record?.createdAt)}
        </div>
      ),
    },
    {
      key: 'masterGroup',
      title: <span className="text-bold">Grupo mestre</span>,
      dataIndex: 'masterGroup',
      render: (__, record) => <>{record?.masterGroup && <CircleTrue />}</>,
    },
    {
      key: 'actions',
      render: (__, record) => (
        <ActionsList operationsGroup={record} refreshListRef={refreshListRef} />
      ),
      width: 60,
    },
  ];

  const { tableContent, refreshList, setFilterData } = useTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/operations-groups`,
      config: { params: {} },
    },
    queryParams: { search: StringParam },
    columns,
    rowKey: 'id',
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );

  return (
    <Wrapper id="operations-groups-page">
      <div id="operations-groups-page-content" className="flex">
        <PageTitle
          title="Grupos de operações no WeCheck"
          subtitle="Crie e gerencie grupos de operações personalizados para começarem a atuar nas avaliações do WeCheck."
        />
        <div className="flex row end-center flex-1">
          <div className="flex align-center">
            <Form
              layout="horizontal"
              className="flex align-center"
              onValuesChange={handleFormChange}
              initialValues={{ _search: query?.search }}
            >
              <SearchInput
                name="_search"
                placeholder="Busque por nome"
                iconSize="sm"
                className="flex center"
              />
            </Form>
          </div>

          <div className="create-new-group flex center mrg-left-50">
            <PermissionWrapper requiredPermissions={['wecheck_configs:create']}>
              <Button
                className="btn-custom-primary flex center"
                onClick={() => history.push('/operations-groups/create')}
              >
                Criar novo Grupo
                <Plus />
              </Button>
            </PermissionWrapper>
          </div>
        </div>
      </div>
      <div id="operations-groups-list-table" className="gx-w-100 mrg-top-10">
        {tableContent}
      </div>
    </Wrapper>
  );
};

export default OperationsGroups;
