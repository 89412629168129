import React from 'react';
import { Layout } from 'antd';

import { useScroll } from 'hooks/scroll';

const { Content } = Layout;

const Wrapper = ({ children, ...rest }) => {
  const { handleScroll } = useScroll();

  return (
    <Layout className="gx-app-layout">
      <Layout>
        <Content id="main-content" className="gx-container-wrap" onScroll={handleScroll}>
          <div {...rest}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Wrapper;
