import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { useAuth } from 'hooks/auth';

import { hasRequiredPermissions } from 'routes/permissionFunctions';

const HorizontalNav = () => {
  const { loggedUser } = useAuth();

  const [hasPermissionFilterEmail, setHasPermissionFilter] = useState(false);

  useEffect(() => {
    setHasPermissionFilter(
      hasRequiredPermissions(loggedUser, ['wecheck_revision:read'], [])
    );
  }, [loggedUser]);

  return (
    <Menu className="menu-container" mode="horizontal" defaultSelectedKeys={['home']}>
      <Menu.Item key="home">
        <NavLink
          to="/home"
          className={(isActive) => `nav-link${!isActive ? ' unselected' : ''}`}
        >
          Home
        </NavLink>
      </Menu.Item>
      {hasPermissionFilterEmail && (
        <Menu.Item key="reviews">
          <NavLink
            to="/reviews"
            className={(isActive) => `nav-link${!isActive ? ' unselected' : ''}`}
          >
            Revisão
          </NavLink>
        </Menu.Item>
      )}

      <Menu.Item key="queue">
        <NavLink
          to="/queue"
          className={(isActive) => `nav-link${!isActive ? ' unselected' : ''}`}
        >
          Fila
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default HorizontalNav;
