/* eslint-disable no-nested-ternary */
import React from 'react';
import { StringParam } from 'use-query-params';
import { Link } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';
import { Tooltip, Popover } from 'antd';
import { CursorSelect } from '@combateafraude/icons/builder';
import { Eye } from '@combateafraude/icons/general';

import PermissionWrapper from 'routes/PermissionWrapper';

import { toFormatDate } from 'utils/formatters';

import SortIcon from 'components/SortIcon';
import useTable from 'components/List/hooks/useTable';

const useQueueTable = () => {
  const getCellColorClass = (createdAt) => {
    const minutesFromCreation = differenceInMinutes(new Date(), new Date(createdAt));
    if (minutesFromCreation > 30) {
      return 'red-background';
    }

    if (minutesFromCreation > 15) {
      return 'yellow-background';
    }

    if (minutesFromCreation > 5) {
      return 'grey-background';
    }

    return '';
  };

  const renderOperationsGroupsNames = (groups) => (
    <div className="flex fd-column center">
      {groups.map((groupName) => (
        <span key={groupName}>{groupName}</span>
      ))}
    </div>
  );

  const renderOperationsGroupsPopover = (record) => (
    <Popover
      content={renderOperationsGroupsNames(record?.operationsGroupsNames)}
      trigger="hover"
      className="view-groups flex center pointer"
      overlayClassName="operations-group-popover"
    >
      <span>Ver grupos</span>
      <CursorSelect width={24} height={24} />
    </Popover>
  );

  const columns = [
    {
      key: 'createdAt',
      title: <span className="column-title">Data de criação</span>,
      dataIndex: 'createdAt',
      render: (__, record) => (
        <div
          className={`flex align-center text-dark ${getCellColorClass(
            record?.createdAt
          )}`}
        >
          {toFormatDate(record?.createdAt)}
        </div>
      ),
    },
    {
      key: 'hookedUsername',
      title: <span className="column-title">Avaliador</span>,
      dataIndex: 'hookedUsername',
      render: (__, record) => (
        <div className="flex align-center text-dark">{record?.hookedUsername || '-'}</div>
      ),
    },
    {
      key: 'hookedAt',
      title: <span className="column-title">Início da avaliação</span>,
      dataIndex: 'hookedAt',
      render: (__, record) => (
        <div className="flex align-center text-dark">
          {toFormatDate(record?.hookedAt)}
        </div>
      ),
    },
    {
      key: 'solutionLevel',
      title: <span className="column-title">Fila de processamento</span>,
      dataIndex: 'solutionLevel',
      render: (__, record) => (
        <div className="flex align-center text-dark">
          {record?.solutionLevel === 'standard'
            ? 'Standard'
            : record?.solutionLevel === 'advanced'
            ? 'Advanced'
            : record?.solutionLevel === 'international'
            ? 'International'
            : record?.solutionLevel === 'qrcode'
            ? 'QRCode'
            : record?.solutionLevel === 'poc'
            ? 'POC'
            : record?.solutionLevel === 'exclusive'
            ? 'Exclusiva'
            : record?.solutionLevel === 'quality'
            ? 'Quality'
            : '-'}
        </div>
      ),
    },
    {
      key: 'type',
      title: <span className="column-title">Tipo de avaliação</span>,
      dataIndex: 'type',
      render: (__, record) => (
        <div className="flex align-center text-dark">
          {record?.type === 'documentscopy'
            ? 'Documentoscopia'
            : record?.type === 'pending-ocr'
            ? 'Pendente OCR'
            : record?.type === 'double-check-liveness'
            ? 'Double-check de Liveness'
            : '-'}
        </div>
      ),
    },
    {
      key: 'totalPriority',
      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === 'totalPriority'
        );
        return (
          <div className="flex row">
            <span className="column-title">Prioridade</span>
            <SortIcon order={sortedColumn?.order} />
          </div>
        );
      },
      dataIndex: 'totalPriority',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.totalPriority - b.totalPriority,
      render: (__, record) => (
        <div className="flex align-center text-dark">
          {typeof record?.totalPriority === 'number'
            ? Math.trunc(record?.totalPriority)
            : '-'}
        </div>
      ),
    },
    {
      key: 'operationsGroupsNames',
      title: <span className="column-title">Grupos de Operações</span>,
      dataIndex: 'operationsGroupsNames',
      render: (__, record) => (
        <>{record?.operationsGroupsNames ? renderOperationsGroupsPopover(record) : '-'}</>
      ),
    },
    {
      key: 'recordDetails',
      render: (__, record) => (
        <PermissionWrapper requiredPermissions={['wecheck_revision:read']}>
          <Tooltip
            title="Ver detalhes do registro"
            overlayClassName="record-details-tooltip"
          >
            <div className="inline">
              <Link
                to={`/${record.type}/records/details/record/${record.recordId}`}
                target="_blank"
                className="record-details-link flex center"
              >
                <Eye width={24} height={24} />
              </Link>
            </div>
          </Tooltip>
        </PermissionWrapper>
      ),
    },
  ];

  const {
    tableContent,
    refreshList,
    loading: loadingTable,
    setLoading: setLoadingTable,
    data,
    query,
    setFilterData,
  } = useTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_WECHECK_API}/queuing`,
      config: { params: {} },
    },
    queryParams: {
      _recordTypes: StringParam,
      _solutionLevels: StringParam,
      _operationsGroups: StringParam,
      _testEvaluation: StringParam,
    },
    columns,
    rowKey: 'id',
  });

  return {
    tableContent,
    refreshList,
    loading: loadingTable,
    setLoading: setLoadingTable,
    data,
    query,
    setFilterData,
  };
};

export default useQueueTable;
