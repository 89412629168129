import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal as StandardModal } from 'antd';

import ZoomedImage from 'components/Image/ZoomedImage';

import './styles.less';

const ViewSelfieModal = ({ selfieImg }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(async () => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const Modal = useMemo(
    () => (
      <StandardModal
        okText="Ok"
        okType="primary"
        visible={modalVisible}
        wrapClassName="view-selfie-modal-component"
        footer={null}
        centered
        closable
        maskClosable={false}
        onCancel={closeModal}
        keyboard
      >
        <div className="modal-body">
          <ZoomedImage
            src={selfieImg}
            alt="Selfie"
            height={28}
            label="Selfie"
            className="zoomed-image-selfie"
            showScaleButton
            useImageDefaultHeight
          />
        </div>
      </StandardModal>
    ),
    [modalVisible, closeModal, selfieImg]
  );

  return {
    openModal,
    closeModal,
    Modal,
  };
};

ViewSelfieModal.propTypes = {
  selfieImg: PropTypes.string.isRequired,
};

export default ViewSelfieModal;
