import React from 'react';

import useQueueTable from './QueueList/hooks/useQueueTable';
import QueueSubheader from './QueueSubheader';
import QueueCards from './QueueCards';
import QueueList from './QueueList';

import Wrapper from '../wrapper';

import './styles.less';

const Queue = () => {
  const {
    tableContent,
    refreshList,
    loading: loadingTable,
    setLoading: setLoadingTable,
    data,
    query,
    setFilterData,
  } = useQueueTable();

  return (
    <Wrapper id="queue-component">
      <QueueSubheader
        refreshList={refreshList}
        loadingTable={loadingTable}
        setLoadingTable={setLoadingTable}
      />
      <QueueCards
        data={data}
        loadingTable={loadingTable}
        query={query}
        setFilterData={setFilterData}
      />
      <hr />
      <QueueList tableContent={tableContent} />
    </Wrapper>
  );
};

export default Queue;
