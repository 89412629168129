import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';

import Button from 'components/Button';
import showToastMessage from 'components/ToastMessage';

const CopyToClipboardButton = ({ text, onCopyText, icon, size, className }) => {
  const onCopy = useCallback(() => {
    showToastMessage({ type: 'info', text: onCopyText });
  }, [onCopyText]);

  return (
    <ReactCopyToClipboard
      id="copy-to-clipboard-button-component"
      text={text}
      onCopy={onCopy}
    >
      <Button className={className} size={size}>
        {icon}
      </Button>
    </ReactCopyToClipboard>
  );
};

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
  onCopyText: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
};

CopyToClipboardButton.defaultProps = {
  className: undefined,
  size: undefined,
};

export default CopyToClipboardButton;
