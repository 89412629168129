import React, { createContext, useCallback, useContext } from 'react';

import { useFetch } from 'services/hooks';

const CustomerContext = createContext({});

const CustomerProvider = ({ children }) => {
  const {
    data: customersData,
    clearData: clearCustomers,
    loading: loadingCustomers,
    get,
  } = useFetch();

  const getCustomers = useCallback(() => {
    get({
      url: `${process.env.REACT_APP_BASE_URL_BACKOFFICE_API}/clients`,
      config: {
        params: {
          _offset: 0,
          _sort: 'fantasyName',
          _order: 1,
          _returnDocuments: false,
        },
      },
    });
  }, [get]);

  return (
    <CustomerContext.Provider
      value={{
        customersData,
        customers: customersData?.docs,
        loadingCustomers,
        getCustomers,
        clearCustomers,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

function useCustomer() {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error('useCustomer must be used within an CustomerContext');
  }

  return context;
}

export { CustomerProvider, useCustomer };
