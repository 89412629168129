import React, { useEffect, useState } from 'react';
import { Row, Col, Radio, Form, Carousel } from 'antd';
import PropTypes from 'prop-types';

import ZoomedImage from 'components/Image/ZoomedImage';
import RadioButtonOptions from '../RadioButtonOptions';

import './styles.less';

const Blazon = ({
  recordData,
  formRef,
  modelId,
  setBlazonData,
  isReview,
  allRulesCorrect,
}) => {
  const [rules, setRules] = useState('');
  const { documentscopy } = recordData?.record;
  const [blazonValue, setBlazonValue] = useState(
    documentscopy.automation.blazon.prediction ? 'correct' : undefined
  );

  useEffect(() => {
    if (allRulesCorrect.blazon) {
      formRef.setFieldsValue({
        blazonValue: 'correct',
      });

      setBlazonData({
        selected: 'correct',
      });
    }
  }, [allRulesCorrect, formRef, setBlazonData]);

  useEffect(() => {
    if (
      isReview &&
      documentscopy?.review &&
      !!Object.keys(documentscopy?.review?.additionalValidations?.blazon || {}).length
    ) {
      const { blazon } = documentscopy?.review?.additionalValidations;
      formRef?.setFieldsValue({
        blazonValue: blazon.selected,
      });
    }
  }, [formRef, isReview, documentscopy]);

  useEffect(() => {
    if (documentscopy?.rulesValidation?.rule?._id === modelId) {
      setRules(documentscopy?.rulesValidation.rule);
    } else {
      setRules(
        documentscopy?.rulesValidation?.otherRules?.find((r) => r._id === modelId)
      );
    }
  }, [documentscopy, modelId, rules]);

  useEffect(() => {
    setBlazonData({
      selected: blazonValue?.target?.value || blazonValue,
    });
  }, [blazonValue, setBlazonData]);

  return (
    <div id="blazon-component">
      {rules?.model && (
        <Row>
          <span className="model">{`Modelo Escolhido: ${rules?.model || ''}`}</span>
        </Row>
      )}
      <Row className="flex fd-row space-between">
        <Col span={12}>
          <ZoomedImage
            src={documentscopy?.processing?.front?.detectedData?.blazon[0]}
            height={10}
            label="Analisar"
            checkHover
            minHeight={25}
            zoomPixels={25}
            id="review-blazon-image"
            reduceInitialHeight={45}
          />
        </Col>
        <Col span={12}>
          <Carousel>
            {rules?.blazon?.images?.map((image, index) => (
              <div className="custom-carousel" key={image}>
                <ZoomedImage
                  src={image}
                  height={10}
                  label="Referência"
                  checkHover
                  minHeight={25}
                  zoomPixels={25}
                  id={`${index}-reference-blazon-image`}
                  reduceInitialHeight={45}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Form
        form={formRef}
        initialValues={{
          blazonValue,
        }}
      >
        <Row align="middle" justify="end">
          <Form.Item name="blazonValue">
            <Radio.Group
              optionType="button"
              onChange={setBlazonValue}
              disabled={isReview}
            >
              <RadioButtonOptions />
            </Radio.Group>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

Blazon.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  modelId: PropTypes.string,
  setBlazonData: PropTypes.func,
  isReview: PropTypes.bool,
  allRulesCorrect: PropTypes.objectOf(PropTypes.objectOf),
};

Blazon.defaultProps = {
  modelId: null,
  setBlazonData: () => {},
  allRulesCorrect: {},
  isReview: false,
};
export default Blazon;
