import React, { useMemo } from 'react';
import { Typography } from 'antd';

import { useAuth } from 'hooks/auth';

import notify from 'assets/images/notify.svg';

import Wrapper from '../wrapper';

import './styles.less';

const { Title, Paragraph } = Typography;

const Unauthorized = () => {
  const { loggedUser } = useAuth();

  const hasProduct = useMemo(
    () => !!loggedUser?.policyGroup?.products?.wecheck,
    [loggedUser]
  );

  const userIsActive = useMemo(() => loggedUser?.active, [loggedUser]);

  return (
    <Wrapper id="unauthorized-page">
      <div id="unauthorized-content">
        <img src={notify} alt="Não autorizado" className="mrg-btm-30" />
        <Title className="title">
          {userIsActive
            ? `Você não possui permissão para acessar ${
                hasProduct ? ' essa página' : ' esse sistema'
              }`
            : 'Seu usuário está inativado'}
        </Title>

        <Paragraph className="subtitle">
          {`Solicite ${
            userIsActive ? 'acesso para este recurso' : 'ativação de seu usuário'
          } a um administrador do sistema`}
        </Paragraph>
      </div>
    </Wrapper>
  );
};

export default Unauthorized;
