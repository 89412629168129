import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import Button from 'components/Button';
import ReportSuspectModal from './ReportSuspectModal';

import './styles.less';

const ReportSuspectButton = ({ loggedUser, executionInfo }) => {
  const { openModal: openModalReportSuspect, Modal: ModalReportSuspect } =
    ReportSuspectModal({ executionInfo });

  const handleOpenReportSuspectModal = useCallback(async () => {
    openModalReportSuspect();
  }, [openModalReportSuspect]);

  const hasPermissionToReportSuspect = hasRequiredPermissions(
    loggedUser,
    ['wecheck_reportSuspect:create'],
    []
  );
  const alreadyReported = executionInfo?.postEvaluationReview?.reportSuspect?.isTrue;
  const isDisabled = !hasPermissionToReportSuspect || alreadyReported;

  return (
    <>
      {ModalReportSuspect}

      <Button
        title={
          isDisabled &&
          (hasPermissionToReportSuspect
            ? 'Suspeito já reportado nessa execução'
            : 'Sem permissão para reportar suspeito')
        }
        className="report-suspect-button"
        disabled={isDisabled}
        type="danger"
        onClick={handleOpenReportSuspectModal}
      >
        Reportar como suspeito
      </Button>
    </>
  );
};

ReportSuspectButton.propTypes = {
  loggedUser: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  executionInfo: PropTypes.objectOf(PropTypes.objectOf).isRequired,
};

export default ReportSuspectButton;
