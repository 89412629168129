import React from 'react';
import { Form, Input } from 'antd';
import InputMask from 'components/Form/InputMask';
import PropTypes from 'prop-types';

const TypeRNE = ({ isReview }) => (
  <>
    <div>
      <Form.Item name="name" label="Nome completo (opcional)">
        <Input className="custom-input" disabled={isReview} />
      </Form.Item>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="motherName" label="Nome da mãe (opcional)">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="fatherName" label="Nome do pai (opcional)">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="cpf" label="CPF">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="birthDate" label="Data de nascimento">
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div />

    <div className="custom-inputs">
      <div className="field-mandatory">
        <Form.Item name="rne" label="RNE" rules={[{ required: true }]}>
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item name="issueDate" label="Data de emissão" rules={[{ required: true }]}>
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item
          name="issuingAuthority"
          label="Órgão expedidor"
          rules={[{ required: true }]}
        >
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div className="field-mandatory">
        <Form.Item name="validity" label="Data de validade" rules={[{ required: true }]}>
          <InputMask className="custom-input" mask="99/99/9999" disabled={isReview} />
        </Form.Item>
      </div>
    </div>

    <div className="custom-inputs">
      <div>
        <Form.Item name="nativeCountry" label="Naturalidade">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="nationality" label="Nacionalidade">
          <Input className="custom-input" disabled={isReview} />
        </Form.Item>
      </div>
    </div>
  </>
);

TypeRNE.propTypes = {
  isReview: PropTypes.bool,
};

TypeRNE.defaultProps = {
  isReview: false,
};

export default TypeRNE;
