/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import './styles.less';

const FieldDetailsList = ({ fieldName, fieldDetails }) => (
  <div className="field-details-list-component">
    <span className="field-details-list-label">{fieldName}</span>
    <div className="field-details-list-box flex">
      {fieldDetails.map((detail, index) => (
        <Tag className="field-detail-tag" key={index}>
          <span className="middle-dot">&#11044;</span>
          {detail}
        </Tag>
      ))}
    </div>
  </div>
);
FieldDetailsList.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldDetails: PropTypes.arrayOf(PropTypes.string),
};

FieldDetailsList.defaultProps = {
  fieldDetails: [],
};

export default FieldDetailsList;
