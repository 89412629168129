import React from 'react';

import { AuthProvider } from './auth';
import { ScrollProvider } from './scroll';
import { RecordProvider } from './record';
import { CountdownProvider } from './countdown';
import { CustomerProvider } from './customer';
import { DisapprovalReasonsProvider } from './disapprovalReasons';
import { OperationsGroupsProvider } from './operationsGroups';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <ScrollProvider>
      <CountdownProvider>
        <CustomerProvider>
          <DisapprovalReasonsProvider>
            <OperationsGroupsProvider>
              <RecordProvider>{children}</RecordProvider>
            </OperationsGroupsProvider>
          </DisapprovalReasonsProvider>
        </CustomerProvider>
      </CountdownProvider>
    </ScrollProvider>
  </AuthProvider>
);

export default AppProvider;
