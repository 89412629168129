/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';

import { Button } from '@combateafraude/react';

import './styles.less';

const OpenAllDropdown = ({ data, loading }) => {
  const handleOpenAllInWecheck = useCallback(() => {
    const docs = data?.docs || [];
    docs.forEach((d) => {
      window.open(`/${d.type}/records/details/record/${d._id}`, '_blank');
    });
  }, [data]);

  const handleOpenAllInBackoffice = useCallback(() => {
    const docs = data?.docs || [];
    docs.forEach((d) => {
      let backofficeUrlTail = '';
      if (d.executionInfo?.tenantId && d.executionInfo?.executionId) {
        backofficeUrlTail = `executions/details/user/${d.executionInfo.tenantId}/report/000000000000000000000000/execution/${d.executionInfo.executionId}`;
      }
      const backofficeUrl = `${process.env.REACT_APP_BASE_URL_BACKOFFICE_WEB}/${backofficeUrlTail}`;
      window.open(backofficeUrl, '_blank');
    });
  }, [data]);

  const menu = useMemo(
    () => (
      <Menu
        items={[
          {
            key: '0',
            label: (
              <Button type="text" onClick={handleOpenAllInWecheck}>
                Abrir no WeCheck
              </Button>
            ),
          },
          {
            key: '1',
            label: (
              <Button type="text" onClick={handleOpenAllInBackoffice}>
                Abrir no Backoffice
              </Button>
            ),
          },
        ]}
      />
    ),
    [handleOpenAllInWecheck, handleOpenAllInBackoffice]
  );

  const isDisabled = data?.docs?.length === 0 || loading;

  return (
    <Dropdown
      overlayClassName="open-all-dropdown"
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      disabled={isDisabled}
    >
      <Button
        disabled={isDisabled}
        block
        className="flex center btn-custom-secondary"
        onClick={(e) => e.preventDefault()}
      >
        <span>Abrir todos</span>
      </Button>
    </Dropdown>
  );
};

OpenAllDropdown.propTypes = {
  data: PropTypes.shape({
    docs: PropTypes.arrayOf(PropTypes.objectOf),
  }),
  loading: PropTypes.bool,
};

OpenAllDropdown.defaultProps = {
  data: {
    docs: [],
  },
  loading: false,
};

export default OpenAllDropdown;
