import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import countries from 'i18n-iso-countries';
import ptLocale from 'i18n-iso-countries/langs/pt.json';
import { Checkmark, CircleFalse } from '@combateafraude/icons/general';

const { Option } = Select;

countries.registerLocale(ptLocale);
const countriesObj = countries.getNames('pt', { select: 'official' });
const countriesArr = Object.entries(countriesObj).map(([key, value]) => ({
  label: value,
  value: key,
}));

const SelectCountry = ({ disabled, ...rest }) => (
  <Select
    {...rest}
    showSearch
    listHeight={320}
    dropdownAlign={{ offset: [0, 2] }}
    removeIcon={<CircleFalse />}
    menuItemSelectedIcon={<Checkmark />}
    disabled={disabled}
    filterOption={(input, option) =>
      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {countriesArr
      ?.map((country) => (
        <Option key={country.value} value={country.value} label={country.label}>
          {country.label}
        </Option>
      ))
      .sort((a, b) => a.props.label.localeCompare(b.props.label))}
  </Select>
);

SelectCountry.propTypes = {
  disabled: PropTypes.bool,
};

SelectCountry.defaultProps = {
  disabled: false,
};

export default SelectCountry;
