import React from 'react';
import PropTypes from 'prop-types';
import { Divider, List, Typography } from 'antd';
import { CheckmarkSmall, CloseSmall } from '@combateafraude/icons/general';
import './styles.less';

const { Text } = Typography;

const DisplayRules = ({ approveRules, reproveRules, fantasyName }) => (
  <div className="display-rules">
    <Text>
      Cliente: <strong>{fantasyName}</strong>
    </Text>

    <Divider className="header-divider" />

    {approveRules.length > 0 && (
      <List
        header={<strong>Aprove se:</strong>}
        dataSource={approveRules}
        split={false}
        renderItem={(item) => (
          <List.Item>
            <CheckmarkSmall />
            {item}
          </List.Item>
        )}
        id="approve-rules"
      />
    )}

    {reproveRules.length > 0 && (
      <List
        header={<strong>Reprove se:</strong>}
        dataSource={reproveRules}
        split={false}
        renderItem={(item) => (
          <List.Item>
            <CloseSmall />
            {item}
          </List.Item>
        )}
        id="reprove-rules"
      />
    )}
  </div>
);

DisplayRules.propTypes = {
  approveRules: PropTypes.shape.isRequired,
  reproveRules: PropTypes.shape.isRequired,
  fantasyName: PropTypes.string.isRequired,
};

export default DisplayRules;
