import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle2, ArrowRight, AlertTriangle } from '@combateafraude/icons/general';
import { differenceInMinutes, isBefore } from 'date-fns';
import { Row, Col } from 'antd';
import classNames from 'classnames';

import { toFormatDate } from 'utils/formatters';

import Card from 'components/Card';
import Button from 'components/Button';
import QueueFiltersModal from './components/QueueFiltersModal';

import './styles.less';

const QueueCards = ({ data, loadingTable, query, setFilterData }) => {
  const { openModal: openModalQueueFilters, Modal: ModalQueueFilters } =
    QueueFiltersModal({ query, setFilterData });

  const handleOpenModal = useCallback(async () => {
    openModalQueueFilters();
  }, [openModalQueueFilters]);

  const queueCounts = useMemo(() => {
    const now = new Date();

    const counts = data.reduce(
      (acc, curr) => {
        const minutesFromCreation = differenceInMinutes(now, new Date(curr.createdAt));

        if (minutesFromCreation > 5 && minutesFromCreation <= 15) {
          acc.moreThan5Min += 1;
        }

        if (minutesFromCreation > 15 && minutesFromCreation <= 30) {
          acc.moreThan15Min += 1;
        }

        if (minutesFromCreation > 30) {
          acc.moreThan30Min += 1;
        }

        if (isBefore(new Date(curr.createdAt), new Date(acc.oldestCreation))) {
          acc.oldestCreation = curr.createdAt;
        }

        if (curr.type === 'documentscopy') {
          acc.documentscopy += 1;
        }

        if (curr.type === 'pending-ocr') {
          acc.pendingOcr += 1;
        }

        if (curr.type === 'double-check-liveness') {
          acc.doubleCheckLiveness += 1;
        }

        return acc;
      },
      {
        moreThan5Min: 0,
        moreThan15Min: 0,
        moreThan30Min: 0,
        oldestCreation: now.toISOString(),
        documentscopy: 0,
        pendingOcr: 0,
        doubleCheckLiveness: 0,
      }
    );

    return counts;
  }, [data]);

  return (
    <div id="queue-cards-component">
      {ModalQueueFilters}
      <Row>
        <Col span={8}>
          <Card
            title="Defina os filtros"
            extra={
              <Button type="link" onClick={handleOpenModal} disabled={loadingTable}>
                <ArrowRight width={24} height={24} />
              </Button>
            }
            loading={loadingTable}
            className="filters-card"
          >
            <div
              className={`card-row flex space-between ${classNames({
                'filter-is-enabled': query?._recordTypes?.length > 0,
              })}`}
            >
              <span>Tipo de registro</span>
              {query?._recordTypes?.length > 0 && <CheckCircle2 width={20} height={20} />}
            </div>

            <div
              className={`card-row flex space-between ${classNames({
                'filter-is-enabled': query?._solutionLevels?.length > 0,
              })}`}
            >
              <span>Tipo de solução na Documentoscopia</span>
              {query?._solutionLevels?.length > 0 && (
                <CheckCircle2 width={20} height={20} />
              )}
            </div>

            <div
              className={`card-row flex space-between ${classNames({
                'filter-is-enabled': query?._operationsGroups?.length > 0,
              })}`}
            >
              <span>Grupo de operações</span>
              {query?._operationsGroups?.length > 0 && (
                <CheckCircle2 width={20} height={20} />
              )}
            </div>
            <div
              className={`card-row flex space-between ${classNames({
                'filter-is-enabled': query?._testEvaluation === 'true',
              })}`}
            >
              <span>Análises de testes</span>
              {query?._testEvaluation === 'true' && (
                <CheckCircle2 width={20} height={20} />
              )}
            </div>
          </Card>
        </Col>

        <Col span={10}>
          <Card title="Tempo de fila" loading={loadingTable}>
            <div className="flex center space-between card-row">
              <div className="flex center">
                <span className="rect-icon">
                  <svg>
                    <rect className="fill-grey" />
                  </svg>
                </span>
                <span>Execuções com + de 5 min na fila</span>
              </div>
              <span>{queueCounts.moreThan5Min}</span>
            </div>
            <div className="flex center space-between card-row">
              <div className="flex center">
                <span className="rect-icon">
                  <svg>
                    <rect className="fill-warning" />
                  </svg>
                </span>
                <span>Execuções com + de 15 min na fila</span>
              </div>
              <span>{queueCounts.moreThan15Min}</span>
            </div>
            <div className="flex center space-between card-row">
              <div className="flex center">
                <span className="rect-icon">
                  <svg>
                    <rect className="fill-danger" />
                  </svg>
                </span>
                <span>Execuções com + de 30 min na fila</span>
              </div>
              <span>{queueCounts.moreThan30Min}</span>
            </div>
            <div className="flex center space-between card-row">
              <div className="flex center dark-red">
                <AlertTriangle />
                <span>Registro mais antigo na fila</span>
              </div>
              <span>
                {data.length > 0 ? toFormatDate(queueCounts.oldestCreation) : '-'}
              </span>
            </div>
          </Card>
        </Col>

        <Col span={6}>
          <Card title="Filas" loading={loadingTable}>
            <div className="flex space-between card-row">
              <span>Documentoscopia</span>
              <span>{queueCounts.documentscopy}</span>
            </div>
            <div className="flex space-between card-row">
              <span>Pendente de OCR</span>
              <span>{queueCounts.pendingOcr}</span>
            </div>
            <div className="flex space-between card-row">
              <span>DoubleCheck Liveness</span>
              <span>{queueCounts.doubleCheckLiveness}</span>
            </div>
            <div className="flex space-between card-row">
              <span>Total de itens</span>
              <span>{data.length}</span>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
QueueCards.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  loadingTable: PropTypes.bool.isRequired,
  query: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  setFilterData: PropTypes.func.isRequired,
};

export default QueueCards;
